import React from "react";
import {
  Button,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useApolloClient, useMutation } from "@apollo/client";
import { UPDATE_CONS_SOCIAL_LINKS } from "~/graphql/cons_social_links/Mutation";
import { toast } from "react-toastify";

const socialPlatforms = [
  { label: "Facebook", value: "Facebook" },
  { label: "LinkedIn", value: "Linkedin" },
  { label: "Instagram", value: "Instagram" },
  { label: "YouTube", value: "Youtube" },
];

const EditConsSocialLinks = ({ setSidePopup, selectedLink }: any) => {
  const client = useApolloClient();
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      name: selectedLink?.name || "",
      link: selectedLink?.link || "",
    },
  });

  const [updateSocialLink] = useMutation(UPDATE_CONS_SOCIAL_LINKS);

  const onSubmit = async (data: any) => {
    try {
      const response = await updateSocialLink({
        variables: {
          id: selectedLink.id,
          socilaLinkData: { name: data.name, link: data.link },
        },
      });

      if (response?.data?.update_cons_social_links?.affected_rows) {
        client.refetchQueries({ include: "all" });
        toast.success("Social link updated successfully!");
      }

      setSidePopup(false);
    } catch (error) {
      toast.error("Failed to update social link!");
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Edit Social Link
      </h1>

      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
        {/* Platform Dropdown */}
        <FormControl fullWidth>
          <InputLabel>Platform</InputLabel>
          <Controller
            name="name"
            control={control}
            defaultValue={selectedLink?.name || ""}
            render={({ field }) => (
              <Select {...field} label="Platform">
                {socialPlatforms.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>

        {/* Profile URL */}
        <TextField
          label="Profile URL"
          variant="outlined"
          fullWidth
          {...register("link")}
          defaultValue={selectedLink?.link || ""}
        />

        <Button type="submit" variant="contained" color="primary">
          Update
        </Button>
      </form>
    </div>
  );
};

export default EditConsSocialLinks;
