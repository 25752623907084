import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useForm, Controller } from "react-hook-form";
import { Button, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { UPDATE_BRD_TESTIMONIAL } from "~/graphql/cons_testimonials/Mutation";
import { GET_BRD_TESTIMONIAL } from "~/graphql/cons_testimonials/Query";
// import { GET_BRD_TESTIMONIAL } from "~/graphql/cons_testimonials/Query"; // Import the query to refetch

const EditConsTestimonials = ({ selectedTestimonial, setSidePopup , brdId }: any) => {
  const { control, handleSubmit, reset, setValue } = useForm();
  const [loading, setLoading] = useState(false);

  // Pre-fill form fields when a testimonial is selected
  useEffect(() => {
    if (selectedTestimonial) {
      setValue("name", selectedTestimonial.name);
      setValue("designation", selectedTestimonial.designation);
      setValue("rating", selectedTestimonial.rating);
      setValue("feedback", selectedTestimonial.feedback);
    }
  }, [selectedTestimonial, setValue]);

  // Mutation for updating testimonial with refetchQueries
  const [updateTestimonial] = useMutation(UPDATE_BRD_TESTIMONIAL, {
    onCompleted: () => {
      toast.success("Testimonial updated successfully!");
      setSidePopup(false);
      reset();
    },
    onError: (error) => {
      toast.error(`Error: ${error.message}`);
      console.error("Update error:", error);
    },
    refetchQueries: [{ query: GET_BRD_TESTIMONIAL , variables : {brdId}}], 
  });

  // Handle form submission
  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      await updateTestimonial({
        variables: {
          id: selectedTestimonial.id,
          testimonialData: {
            name: data.name,
            designation: data.designation,
            feedback: data.feedback,
            rating: parseFloat(data.rating),
          },
        },
      });
    } catch (error) {
      console.error("Error updating testimonial:", error);
      toast.error("Failed to update testimonial. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Edit Testimonial
      </h1>
      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        {/* Name Input */}
        <Controller
          name="name"
          control={control}
          rules={{ required: "Name is required" }}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} label="Name" variant="outlined" fullWidth error={!!error} helperText={error ? error.message : ""} />
          )}
        />

        {/* Designation Input */}
        <Controller
          name="designation"
          control={control}
          rules={{ required: "Designation is required" }}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} label="Designation" variant="outlined" fullWidth error={!!error} helperText={error ? error.message : ""} />
          )}
        />

        {/* Rating Input */}
        <Controller
          name="rating"
          control={control}
          rules={{ required: "Rating is required" }}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} label="Rating" variant="outlined" fullWidth type="number" inputProps={{ min: 0, max: 5, step: 0.1 }} error={!!error} helperText={error ? error.message : ""} />
          )}
        />

        {/* Feedback Input */}
        <Controller
          name="feedback"
          control={control}
          rules={{ required: "Feedback is required" }}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} label="Feedback" variant="outlined" fullWidth multiline rows={4} error={!!error} helperText={error ? error.message : ""} />
          )}
        />

        {/* Submit Button */}
        <Button type="submit" variant="contained" color="primary" disabled={loading}>
          {loading ? "Updating..." : "Update Testimonial"}
        </Button>
      </form>
    </div>
  );
};

export default EditConsTestimonials;
