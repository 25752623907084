import { Button } from '@mui/material'
import ShowConsTeamMembers from '../ConsTeamMembers/ShowConsTeamMembers'
import SideDrawer from '~/components/common/sideDrawer'
import AddConsTestimonials from './component/AddConsTestimonials';
import ShowConsTestimonials from './component/ShowConsTestimonials';
import { useState } from 'react';

function ConsTestimonials({brdId} : any) {
      const [openPopup, setOpenPopup] = useState(false);
  return (
    <div className="bg-secondary dark:bg-dark-secondary rounded-lg  p-5 mb-5">
    <div className="flex justify-between items-center mb-4">
      <div className="flex items-center justify-center h-full ">
        <h1 className="text-xl flex items-center justify-center">
          Consultancy Testimonials
        </h1>
      </div>

      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpenPopup(true)}
      >
        Add Consultancy Testimonials
      </Button>
    </div>
    <ShowConsTestimonials brdId={brdId} />
    <SideDrawer
      component={
        <AddConsTestimonials brdId={brdId}  setSidePopup={setOpenPopup} />
      }
      sidePopup={openPopup}
      setSidePopup={setOpenPopup}
    />
  </div>  )
}

export default ConsTestimonials
