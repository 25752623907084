import { gql } from "@apollo/client";

export const INSERT_INQ_FILES = gql`
  mutation InsertInqFiles($data: inq_files_insert_input!) {
    insert_inq_files(objects: [$data]) {
      affected_rows
    }
  }
`;

export const DELETE_INQ_FILE = gql`
  mutation DeleteInqFile($id: uuid!, $inqID: uuid!) {
    delete_inq_files(where: { id: { _eq: $id }, inq_id: { _eq: $inqID } }) {
      affected_rows
    }
  }
`;
