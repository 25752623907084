import { DELETE_PROGRAM_REQUIREMENTS } from "~/graphql/cons_programs_requirements/Mutation";
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import SideDrawer from "~/components/common/sideDrawer";
import { GET_PROGRAM_REQUIREMENTS } from "~/graphql/cons_programs_requirements/Query";
import EditProgramRequirements from "../../EditProgramRequirements";

const ShowProgramRequirements = ({ programId }: any) => {
  const [fetchRequirements, { data, loading, error }] = useLazyQuery(GET_PROGRAM_REQUIREMENTS);
  const [requirements, setRequirements] = useState<any[]>([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedRequirement, setSelectedRequirement] = useState<any | null>(null);

  const [deleteRequirement] = useMutation(DELETE_PROGRAM_REQUIREMENTS, {
    onCompleted: () => {
      toast.success("Requirement deleted successfully!");
    },
    onError: (error) => {
      console.error("Error deleting requirement:", error);
      toast.error("Failed to delete requirement.");
    },
  });

  useEffect(() => {
    if (programId) {
      fetchRequirements({ variables: { programId } });
    }
  }, [programId, fetchRequirements]);

  useEffect(() => {
    if (data?.cons_programs_requirements) {
      setRequirements(data.cons_programs_requirements);
    }
  }, [data]);

  const handleEdit = (requirement: any) => {
    setSelectedRequirement(requirement);
    setOpenPopup(true);
  };

  const handleDelete = async (id: string) => {
    try {
      const { data } = await deleteRequirement({
        variables: { id },
      });

      if (data?.delete_cons_programs_requirements?.affected_rows > 0) {
        setRequirements(requirements.filter((req) => req.id !== id));
      }
    } catch (error) {
      console.error("Error deleting requirement:", error);
    }
  };

  if (loading) return <p>Loading requirements...</p>;
  if (error) return <p>Error fetching requirements</p>;

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Course Name</TableCell>
              <TableCell>Gap in Months</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requirements.map((req) => (
              <TableRow key={req.id}>
                <TableCell>{req.course_name}</TableCell>
                <TableCell>{req.gap_in_months}</TableCell>
                <TableCell>
                  <div className="flex gap-3">
                    <Button onClick={() => handleEdit(req)}>
                      <FaEdit size={18} />
                    </Button>
                    <Button onClick={() => handleDelete(req.id)}>
                      <FaTrash size={18} />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {openPopup && selectedRequirement && (
        <SideDrawer
          component={<EditProgramRequirements selectedRequirement={selectedRequirement} setSidePopup={setOpenPopup} />}
          sidePopup={openPopup}
          setSidePopup={setOpenPopup}
        />
      )}
    </>
  );
};

export default ShowProgramRequirements;
