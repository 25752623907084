import { gql } from "@apollo/client";

export const INSERT_CONS_ABOUT_INFO = gql`
  mutation InsertConsAboutInfo($data: cons_about_info_insert_input!) {
    insert_cons_about_info(objects: [$data]) {
      affected_rows
    }
  }
`;


export const UPDATED_CONS_ABOUT_INFO = gql`
  mutation UpdateAboutInfo($id: uuid!, $data: cons_about_info_set_input!) {
    update_cons_about_info(where: { id: { _eq: $id } }, _set: $data) {
      affected_rows
    }
  }
`;






export const DELETE_CONS_ABOUT_INFO = gql`
  mutation deleteAboutConsHeading($id: uuid!) {
    delete_cons_about_info(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
