import React from "react";
import ShowDepartments from "./components/ShowDepartments";
import SidePopup from "~/components/common/SidePopup";
import AddDepartments from "./components/AddDepartments";
import { useParams } from "react-router-dom";

function Departments() {
  const { campus_id, brd_id } = useParams();
  return (
    <>
      <ShowDepartments campusId={campus_id} brdId={brd_id} />
      <SidePopup component={<AddDepartments campusId={campus_id} />} />
    </>
  );
}

export default Departments;
