import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { INSERT_DEPARTMENTS } from "~/graphql/cons_departments/Mutation";
import { GET_DEPARTMENTS_BY_CAMPUS } from "~/graphql/cons_departments/Query";

const AddDepartments = ({ campusId, setSidePopup }: any) => {
  const [department, setDepartment] = useState({
    name: "",
    slug: "",
  });

  const [insertDepartment, { loading }] = useMutation(INSERT_DEPARTMENTS, {
    refetchQueries: [
      { query: GET_DEPARTMENTS_BY_CAMPUS, variables: { campusId } },
    ],
    awaitRefetchQueries: true,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDepartment({ ...department, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!department.name || !department.slug) {
      toast.error("All fields are required!");
      return;
    }

    try {
      const { data } = await insertDepartment({
        variables: {
          data: [{ ...department, campus_id: campusId }],
        },
      });

      if (data?.insert_cons_department?.affected_rows > 0) {
        toast.success("Department added successfully!");
        setDepartment({ name: "", slug: "" });
        setSidePopup(false);
      } else {
        toast.error("Failed to add department.");
      }
    } catch (err) {
      console.error("Error adding department:", err);
      toast.error("An error occurred while adding department.");
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Add New Department
      </h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <TextField
          label="Department Name"
          name="name"
          value={department.name}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextField
          label="Slug"
          name="slug"
          value={department.slug}
          onChange={handleChange}
          fullWidth
          required
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
        >
          {loading ? "Adding..." : "Add Department"}
        </Button>
      </form>
    </div>
  );
};

export default AddDepartments;
