import { gql } from "@apollo/client";

export const UPDATE_CONS_MEDIA_THUMBNAIL = gql`
  mutation UpdateConsMedia($id: uuid!, $imageId: uuid!) {
    update_cons_media(
      where: { id: { _eq: $id }, featured: { _eq: true } }
      _set: { image_id: $imageId }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_CONS_MEDIAL_LOGO = gql`
  mutation UpdateConsMedia($id: uuid!, $imageId: uuid!) {
    update_cons_media(
      where: { id: { _eq: $id }, logo: { _eq: true } }
      _set: { image_id: $imageId }
    ) {
      affected_rows
    }
  }
`;
