import { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { GET_CONTACT_INFO } from "~/graphql/cons_contact_info/Query";
import { DELETE_CONTACT_INFO } from "~/graphql/cons_contact_info/Mutation";
import SideDrawer from "~/components/common/sideDrawer";
import EditConsContactinfo from "../EditConsContactInfo";

const ShowConsContactInfo = ({ brdId }:any) => {
  const [fetchContactInfo, { data, loading, error }] = useLazyQuery(GET_CONTACT_INFO);
  const [deleteContactInfo] = useMutation(DELETE_CONTACT_INFO);
  const [contactInfo, setContactInfo] = useState<any[]>([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedContact, setSelectedContact] = useState<any | null>(null);
  const client = useApolloClient();

  // Fetch contact info when brdId changes
  useEffect(() => {
    if (brdId) {
      fetchContactInfo({ variables: { brdId } });
    }
  }, [brdId, fetchContactInfo]);

  // Update state when data is fetched
  useEffect(() => {
    if (data?.brd_contact_detail) {
      setContactInfo(data.brd_contact_detail);
    }
  }, [data]);

  // Handle edit button click
  const handleEdit = (contact: any) => {
    setSelectedContact(contact);
    setOpenPopup(true);
  };

  // Handle delete functionality
  const handleDelete = async (id: any) => {
    try {
      const { data } = await deleteContactInfo({
        variables: { id },
      });
  
      console.log("Mutation Response:", data); // Debugging
  
      if (data?.delete_brd_contact_detail?.affected_rows > 0) {
        toast.success("Contact deleted successfully!");
  
        // Refetch queries to update UI
        client.refetchQueries({
          include: "active",
        });
  
        // Remove from state
        setContactInfo((prev) => prev.filter((contact) => contact.id !== id));
      } else {
        toast.warning("No contact was deleted.");
      }
    } catch (error) {
      toast.error("Failed to delete contact!");
      console.error("Error deleting contact:", error);
    }
  };
  
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading contact information</p>;

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Country</TableCell>
              <TableCell>City</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contactInfo.map((contact) => (
              <TableRow key={contact.id}>
                <TableCell>{contact.country || "NA"}</TableCell>
                <TableCell>{contact.city || "NA"}</TableCell>
                <TableCell>{contact.email || "NA"}</TableCell>
                <TableCell>{contact.phone || "NA"}</TableCell>
                <TableCell>
                  <div className="flex gap-3">
                    <Button onClick={() => handleEdit(contact)}>
                      <FaEdit size={18} />
                    </Button>
                    <Button onClick={() => handleDelete(contact.id)}>
                      <FaTrash size={18} />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {openPopup && selectedContact && (
        <SideDrawer
          component={
            <EditConsContactinfo
              selectedContact={selectedContact}
              setSidePopup={setOpenPopup}
              brdId = {brdId}
            />
          }
          sidePopup={openPopup}
          setSidePopup={setOpenPopup}
        />
      )}
    </>
  );
};

export default ShowConsContactInfo;
