import React from "react";
import { TextField, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation, useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import { UPDATE_INTAKES } from "~/graphql/cons_intakes/Mutation";

const EditIntakes = ({ selectedIntake, setSidePopup }: any) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      name: selectedIntake?.name || "",
      scholarship: selectedIntake?.scholarship || "",
      total_fee: selectedIntake?.total_fee || "",
    },
  });

  const [updateIntake, { loading }] = useMutation(UPDATE_INTAKES);
  const client = useApolloClient();

  const onSubmit = async (formData: any) => {
    if (!selectedIntake?.id) {
      toast.error("Invalid intake data: ID is missing");
      return;
    }

    try {
      const { data } = await updateIntake({
        variables: {
          id: selectedIntake.id,
          data: {
            name: formData.name,
            scholarship: formData.scholarship,
            total_fee: formData.total_fee,
          },
        },
      });

      if (data?.update_cons_intake?.affected_rows > 0) {
        toast.success("Intake updated successfully!");
        client.refetchQueries({ include: "all" });
        setSidePopup(false);
      } else {
        toast.error("Update failed.");
      }
    } catch (error) {
      console.error("Error updating intake:", error);
      toast.error("Failed to update intake.");
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Edit Intake
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Intake Name"
          variant="outlined"
          required
          fullWidth
          {...register("name")}
          style={{ marginBottom: "16px" }}
        />
        <TextField
          label="Scholarship"
          variant="outlined"
          required
          fullWidth
          {...register("scholarship")}
          style={{ marginBottom: "16px" }}
        />
        <TextField
          label="Total Fee"
          variant="outlined"
          required
          fullWidth
          {...register("total_fee")}
          style={{ marginBottom: "16px" }}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
          {loading ? "Updating..." : "Update Intake"}
        </Button>
      </form>
    </div>
  );
};

export default EditIntakes;
