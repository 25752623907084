import { gql } from "@apollo/client";

export const GET_CONS_APPROVAL_STEPS = gql`
  query GetConsApprovalSteps($brdId: uuid!) {
    cons_approval_steps(where: { brd_id: { _eq: $brdId } }) {
      id
      title
      description
      image_id
      brd_id
    }
  }
`;
