import { gql } from "@apollo/client";

export const INSERT_SOCIAL_LINKS = gql`
  mutation InsertSocialLinks($data: [cons_social_links_insert_input!]!) {
    insert_cons_social_links(objects: $data) {
      affected_rows
    }
  }
`;



export const DELETE_SOCIAL_LINKS= gql`
  mutation SocialLinks($id: uuid!) {
    delete_cons_social_links(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;




export const UPDATE_CONS_SOCIAL_LINKS = gql`
  mutation UpdateConsSocialLinks(
    $id: uuid!
    $socilaLinkData: cons_social_links_set_input!
  ) {
    update_cons_social_links(where: { id: { _eq: $id } }, _set: $socilaLinkData) {
      affected_rows
    }
  }
`;