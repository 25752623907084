import { gql } from "@apollo/client";

export const INSERT_CONS_APPROVAL_STEPS = gql`
  mutation InsertConsApprovalSteps(
    $objects: [cons_approval_steps_insert_input!]!
  ) {
    insert_cons_approval_steps(objects: $objects) {
      affected_rows
    }
  }
`;


export const DELETE_CONS_APPROVAL_STEP = gql`
  mutation DeleteConsApprovalStep($id: uuid!) {
    delete_cons_approval_steps(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

// export const UPDATE_CONS_APPROVAL_STEPS = gql`
//   mutation UpdateConsApprovalSteps(
//     $id: uuid!
//     $title: String!
//     $imageId: uuid!
//     $description: String!
//   ) {
//     update_cons_approval_steps(
//       where: { id: { _eq: $id } }
//       _set: { title: $title, image_id: $imageId, description: $description }
//     ) {
//       affected_rows
//     }
//   }
// `;



export const UPDATED_CONS_APPROVAL_STEPS = gql`
  mutation UpdateConsApprovalStep($id: uuid!, $data: cons_approval_steps_set_input!) {
    update_cons_approval_steps(where: { id: { _eq: $id } }, _set: $data) {
      affected_rows
    }
  }
`;
