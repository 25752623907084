import { gql } from "@apollo/client";

export const GET_DEPARTMENTS_BY_CAMPUS = gql`
  query GetDepartmentsByCampus($campusId: uuid!) {
    cons_department(where: { campus_id: { _eq: $campusId } }) {
      id
      name
      slug
    }
  }
`;
