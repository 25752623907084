import { useQuery, useSubscription } from "@apollo/client";
import { Chip, Grow } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";

import brandsStore from "~/store/brandsStore/brandsStore";

import {
  GET_CONS_LEADS,
  GET_CONS_LEADS_LIVE_COUNT,
} from "~/graphql/cons_leads/Query";
import ConsultancyLeadTable from "./components/ConsultancyLeadTable";

const ConsultancyAllLeads = ({
  selectedDates,
  selectedLeadNo,
  selectedCustomer,
  selectedName,
}: any) => {
  const { brands: selectedBrands } = brandsStore();
  const startDateTime = moment(selectedDates?.[0]?.$d)
    .startOf("day")
    .toISOString();
  const endDateTime = moment(selectedDates?.[1]?.$d).endOf("day").toISOString();

  const [newLeadsCount, setNewLeadsCount] = useState(0);

  const condition = {
    _and: [
      // ...(selectedLeadNo ? [{ lead_no: { _ilike: `${selectedLeadNo}` } }] : []),
      ...(selectedLeadNo
        ? [{ lead_no: { _ilike: `%${selectedLeadNo}%` } }]
        : []),

      // ...(selectedCustomer
      //   ? [{ user: { id: { _eq: selectedCustomer?.[0]?.id } } }]
      //   : []),

      ...(selectedName
        ? [{ name: { _ilike: `%${selectedName?.name}%` } }]
        : []),
      ...(startDateTime && endDateTime
        ? [{ created_at: { _gte: startDateTime, _lte: endDateTime } }]
        : []),
      ...(selectedBrands?.length > 0
        ? [{ brd_id: { _in: selectedBrands.map((brand: any) => brand.id) } }]
        : []),
    ],
  };

  const {
    data: leadsData,
    error: leadsError,
    refetch: refetchLeads,
  } = useQuery(GET_CONS_LEADS, {
    variables: {
      condition: condition,
    },
    skip: !selectedDates?.[1]?.$d,
  });

  const { data: liveCountData, error: liveCountError } = useSubscription(
    GET_CONS_LEADS_LIVE_COUNT,
    {
      variables: { condition },
      skip: !selectedDates?.[1]?.$d,
    }
  );

  useEffect(() => {
    const initialCount = leadsData?.cons_leads?.length;
    const liveCount =
      liveCountData?.cons_leads_aggregate?.aggregate?.count || 0;

    setNewLeadsCount(Math.max(0, liveCount - initialCount));
  }, [liveCountData]);

  const leads = leadsData?.cons_leads || [];

  return (
    <>
      <div
        style={
          newLeadsCount > 0
            ? { height: "25px", marginTop: "15px" }
            : { height: "0px" }
        }
        className="transition-all ease-in-out w-full flex justify-center"
      >
        <Grow in={newLeadsCount > 0}>
          <Chip
            onClick={() => {
              refetchLeads();
              setNewLeadsCount(0);
            }}
            sx={{
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#6A0DAD",
              },
            }}
            color="primary"
            label={`${newLeadsCount} New Leads`}
          />
        </Grow>
      </div>

      <ConsultancyLeadTable leads={leads} />
    </>
  );
};

export default ConsultancyAllLeads;
