import { gql } from "@apollo/client";

export const INSERT_CAMPUSES = gql`
  mutation InsertCampuses($data: [cons_campus_insert_input!]!) {
    insert_cons_campus(objects: $data) {
      affected_rows
    }
  }
`;


export const UPDATE_CAMPUS = gql`
  mutation UpdateCampus($id: uuid!, $data: cons_campus_set_input!) {
    update_cons_campus(
      where: { id: { _eq: $id } }
      _set: $data
    ) {
      affected_rows
    }
  }
`;


export const DELETE_CAMPUS = gql`
  mutation DeleteCampus($id: uuid!) {
    delete_cons_campus(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
