import SidePopup from "~/components/common/SidePopup";
import AddConsApprovalSteps from "./components/AddConsApprovalSteps";
import SideDrawer from "~/components/common/sideDrawer";
import { useState } from "react";
import { Button } from "@mui/material";
import ShowConsApprovalSteps from "./components/ShowConsApprovalSteps";

const ConsApprovalSteps = ({ brdId }: { brdId: any }) => {
  const [openPopup, setOpenPopup] = useState(false);

  return (
    <div className="bg-secondary dark:bg-dark-secondary rounded-lg p-5 mb-5">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center justify-center h-full ">
          <h1 className="text-xl flex items-center justify-center">
            Consultancy Approval Steps
          </h1>
        </div>

        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenPopup(true)}
        >
          Add Approval Step
        </Button>
      </div>
      <ShowConsApprovalSteps brdId={brdId} />

      <SideDrawer
        component={
          <AddConsApprovalSteps brdId={brdId} setSidePopup={setOpenPopup} />
        }
        sidePopup={openPopup}
        setSidePopup={setOpenPopup}
      />
    </div>
  );
};

export default ConsApprovalSteps;
