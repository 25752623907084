import { gql } from "@apollo/client";


export const INSERT_PROGRAMS = gql`
  mutation InsertProgram($data: [cons_program_insert_input!]!) {
    insert_cons_program(objects: $data) {
      affected_rows
    }
  }
`;


export const UPDATE_PROGRAMS = gql`
  mutation UpdatePrograms($id: uuid!, $data: cons_program_set_input!) {
    update_cons_program(
      where: { id: { _eq: $id } }
      _set: $data
    ) {
      affected_rows
    }
  }
`;

export const DELETE_PROGRAM = gql`
  mutation DeleteProgram($id: uuid!) {
    delete_cons_program(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
