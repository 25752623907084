import { gql } from "@apollo/client";

export const GET_CONS_HEADINGS = gql`
  query GetConsHeadings($brdId: uuid!) {
    cons_headings(where: { brd_id: { _eq: $brdId } }) {
      heading
      description
      page_name
      section_name
      id
      brd_id
    }
  }
`;
