import { gql } from "@apollo/client";

export const INSERT_CONS_TEAM = gql`
  mutation InsertConsTeam($data: [cons_team_insert_input!]!) {
    insert_cons_team(objects: $data) {
      affected_rows
    }
  }
`;



export const UPDATE_CONS_TEAM_MEMBERS = gql`
  mutation UpdateTeamMember($id: uuid!, $data: cons_team_set_input!) {
    update_cons_team(
      where: { id: { _eq: $id } }
      _set: $data
    ) {
      affected_rows
    }
  }
`;







export const DELETE_CONS_TEAM_MEMBER = gql`
  mutation DeleteConsTeamMember($id: uuid!) {
    delete_cons_team(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;


