import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { Button, TextField } from "@mui/material";
import { nhost } from "~/lib/nhost";
import { toast } from "react-toastify";
// import { UPDATE_CONS_APPROVAL_STEPS } from "~/graphql/cons_approval_steps/Mutation";
import { GET_CONS_APPROVAL_STEPS } from "~/graphql/cons_approval_steps/Query";
import { UPDATED_CONS_APPROVAL_STEPS } from "~/graphql/cons_approval_steps/Mutation";

const EditConsApprovalSteps = ({ editData, setSidePopup, brdId }: any) => {
  const [stepName, setStepName] = useState(editData?.title || "");
  const [description, setDescription] = useState(editData?.description || "");
  const [imageId, setImageId] = useState(editData?.image_id || null);
  const [imageUrl, setImageUrl] = useState(
    editData?.image_id
      ? nhost.storage.getPublicUrl({ fileId: editData.image_id })
      : ""
  );
  // useEffect(() => {
  //   if (editData?.image_id) {
  //     const url = nhost.storage.getPublicUrl({ fileId: editData.image_id });
  //     setImageUrl(url);
  //   }
  // }, [editData]); // Ensure values update when step changes

  const [loading, setLoading] = useState(false);

  const [updateApprovalStep] = useMutation(UPDATED_CONS_APPROVAL_STEPS, {
    refetchQueries: [{ query: GET_CONS_APPROVAL_STEPS, variables: { brdId } }],
    awaitRefetchQueries: true,
  });

  const handleImageUpload = async (file: File) => {
    if (!file) return;
    setLoading(true);
    try {
      const { fileMetadata } = await nhost.storage.upload({ file });
      if (!fileMetadata) throw new Error("File metadata is null");
      const uploadedUrl = nhost.storage.getPublicUrl({
        fileId: fileMetadata.id,
      });
      setImageId(fileMetadata.id);
      setImageUrl(uploadedUrl);
      toast.success("Image uploaded successfully!");
    } catch (error) {
      toast.error("Image upload failed.");
    } finally {
      setLoading(false);
    }
  };

  const handleImageRemove = async () => {
    if (imageUrl && imageId) {
      try {
        await nhost.storage.delete({ fileId: imageId });
        setImageUrl("");
        setImageId(null);
        toast.success("Image removed successfully!");
      } catch (error) {
        toast.error("Failed to remove image.");
      }
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!stepName) {
      toast.error("Step Name is required!");
      return;
    }
    setLoading(true);
    try {
      await updateApprovalStep({
        variables: {
          id: editData.id,
          data: {
            title: stepName,
            description,
            image_id: imageId,
          },
        },
      });
      toast.success("Approval step updated successfully!");
      setSidePopup(false);
    } catch (error) {
      toast.error("Update failed.");
      console.error("Update error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Edit Approval Step
      </h1>
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <TextField
          label="Step Name"
          variant="outlined"
          fullWidth
          value={stepName}
          onChange={(e) => setStepName(e.target.value)}
        />
        <TextField
          label="Description"
          variant="outlined"
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />

        {imageUrl && (
          <div className="relative">
            <button
              type="button"
              className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1"
              onClick={handleImageRemove}
            >
              ✕
            </button>
            <img
              src={imageUrl}
              alt="Uploaded"
              className="w-[300px] rounded-lg"
            />
          </div>
        )}

        <Button
          variant="outlined"
          component="label"
          className="dark:text-dark-primary w-[250px]"
        >
          Upload Thumbnail
          <input
            type="file"
            hidden
            onChange={(e) =>
              e.target.files?.[0] && handleImageUpload(e.target.files[0])
            }
            disabled={loading}
          />
        </Button>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? "Updating..." : "Update"}
        </Button>
      </form>
    </div>
  );
};

export default EditConsApprovalSteps;
