import React from "react";
import { useForm } from "react-hook-form";
import { Button, TextField } from "@mui/material";
import { useMutation, useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import { nhost } from "~/lib/nhost";
import { INSERT_CONS_APPROVAL_STEPS } from "~/graphql/cons_approval_steps/Mutation";

const AddConsApprovalSteps = ({
  setSidePopup,
  brdId,
}: {
  setSidePopup: (open: boolean) => void;
  brdId: string;
}) => {
  const client = useApolloClient();
  const { register, handleSubmit, setValue, watch, reset } = useForm({
    defaultValues: {
      stepName: "",
      description: "",
      imageId: null as string | null,
      imageUrl: "",
    },
  });

  const imageUrl = watch("imageUrl");
  const imageId = watch("imageId");

  const [insertStep, { loading }] = useMutation(INSERT_CONS_APPROVAL_STEPS, {
    onCompleted: () => {
      client.refetchQueries({ include: "active" });
      toast.success("Approval step added successfully!");
      reset();
      setSidePopup(false);
    },
    onError: (error) => {
      toast.error(`Error: ${error.message}`);
    },
  });

  const onSubmit = (data: any) => {
    insertStep({
      variables: {
        objects: [
          {
            title: data.stepName,
            description: data.description,
            brd_id: brdId,
            image_id: data.imageId,
          },
        ],
      },
    });
  };

  const handleImageRemove = async () => {
    if (imageUrl && imageId) {
      await nhost.storage.delete({ fileId: imageId });
      setValue("imageUrl", "");
      setValue("imageId", null);
    }
  };

  const uploadImage = async (file: File) => {
    const response = await nhost.storage.upload({ file });
    if (response.error) throw new Error(response.error.message);
    setValue("imageId", response.fileMetadata.id);
    setValue(
      "imageUrl",
      `${process.env.REACT_APP_NHOST_STORAGE_URL}${response.fileMetadata.id}`
    );
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Add Approval Step
      </h1>

      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <TextField
          label="Title"
          variant="outlined"
          fullWidth
          {...register("stepName")}
        />
        <TextField
          label="Description"
          variant="outlined"
          fullWidth
          {...register("description")}
        />

        <Button
          variant="outlined"
          component="label"
          className="dark:text-dark-primary w-[250px]"
        >
          Upload Thumbnail
          <input
            type="file"
            hidden
            onChange={async (e) => {
              const file = e.target.files?.[0];
              if (file) await uploadImage(file);
            }}
          />
        </Button>

        {imageUrl && (
          <div className="relative">
            <button
              type="button"
              className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1"
              onClick={handleImageRemove}
            >
              ✕
            </button>
            <img src={imageUrl} alt="Uploaded" className="w-[300px]" />
          </div>
        )}

        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? "Adding..." : "Add Step"}
        </Button>
      </form>
    </div>
  );
};

export default AddConsApprovalSteps;
