import { gql } from "@apollo/client";



export const GET_CAMPUSES_BY_UNIVERSITY = gql`
  query GetCampuses($universityId: uuid!) {
    cons_campus(where: { university_id: { _eq: $universityId } }) {
      id
      name
      description
      slug
      cons_media{
      id
      image_id
      featured
      }
    }
  }
`;
export const GET_CAMPUSES_BY_ID = gql`
  query GetCampuses($id: uuid!) {
    cons_campus(where: { id: { _eq: $id } }) {
      id
      name
      description
      slug
      cons_media{
      id
      image_id
      featured
      }
    }
  }
`;


