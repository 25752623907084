import { gql } from "@apollo/client";

export const GET_CONS_LEADS_WITH_BRD_ID = gql`
  query GetConsLeadsWithBrdID($brdIds: [uuid!]) {
    cons_leads(where: { brd_id: { _in: $brdIds } }) {
      address
      brd_id
      campus_name
      country_name
      created_at
      department_name
      email
      field_of_interset
      id
      intake_name
      marks_prcentage
      name
      phone_number
      previous_degree
      program_name
      university_name
      updated_at
      lead_no
    }
  }
`;

export const GET_CONS_LEADS = gql`
  query getLeads($condition: cons_leads_bool_exp) {
    cons_leads(where: $condition, order_by: { created_at: desc }) {
      email
      id
      name
      phone_number
      previous_degree
      program_name
      university_name
      intake_name
      marks_prcentage
      department_name
      campus_name
      lead_no
      address
    }
  }
`;

export const GET_CONS_LEADS_LIVE_COUNT = gql`
  subscription getConsLiveLeadsCount($condition: cons_leads_bool_exp!) {
    cons_leads_aggregate(where: $condition) {
      aggregate {
        count
      }
    }
  }
`;
