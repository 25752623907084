import SidePopup from "~/components/common/SidePopup";
import ShowManageDesignation from "./components/ShowManageDesignation";
import AddManageDesignation from "./components/AddManageDesignation";
import { useParams } from "react-router-dom";

const ManageDesignation = () => {
  const { brd_id } = useParams();
  return (
    <>
      <ShowManageDesignation brdId={brd_id} />
      <SidePopup component={<AddManageDesignation brdId={brd_id} />} />
    </>
  );
};

export default ManageDesignation;
