import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Button, TextField } from "@mui/material";
import { nhost } from "~/lib/nhost";
import { toast } from "react-toastify";
import { UPDATE_CONS_TEAM_MEMBERS } from "~/graphql/cons_team/Mutation";
import { GET_CONS_TEAM } from "~/graphql/cons_team/Query";


function EditConsTeamMembers({ selectedMember, setSidePopup,brdId}: any) {
  const [name, setName] = useState(selectedMember?.name || "");
  const [designation, setDesignation] = useState(selectedMember?.designation || "");
  const [imageId, setImageId] = useState(selectedMember?.image_id || null);
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    if (selectedMember?.image_id) {
      const url = nhost.storage.getPublicUrl({ fileId: selectedMember.image_id });
      setImageUrl(url);
    }
  }, [selectedMember]); // Ensure image updates when `selectedMember` changes

  const [loading, setLoading] = useState(false);

  const [updateTeamMember] = useMutation(UPDATE_CONS_TEAM_MEMBERS, {
    refetchQueries: [{ query: GET_CONS_TEAM , variables : {brdId} }],
    awaitRefetchQueries : true // Ensure this query is correct
  });

  const handleImageUpload = async (file: File) => {
    if (!file) return;
    setLoading(true);
    try {
      const { fileMetadata } = await nhost.storage.upload({ file });
      if (!fileMetadata) throw new Error("File metadata is null");
      const uploadedUrl = nhost.storage.getPublicUrl({ fileId: fileMetadata.id });
      setImageId(fileMetadata.id);
      setImageUrl(uploadedUrl);
      toast.success("Image uploaded successfully!");
    } catch (error) {
      toast.error("Image upload failed.");
    } finally {
      setLoading(false);
    }
  };

  const handleImageRemove = async () => {
    if (imageUrl && imageId) {
      try {
        await nhost.storage.delete({ fileId: imageId });
        setImageUrl("");
        setImageId(null);
        toast.success("Image removed successfully!");
      } catch (error) {
        toast.error("Failed to remove image.");
      }
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      await updateTeamMember({
        variables: {
          id: selectedMember.id,
          data: {
            name,
            designation,
            image_id: imageId,
          },
        },
      });
      toast.success("Team member updated successfully!");
      setSidePopup(false);
    } catch (error) {
      toast.error("Update failed.");
      console.error("Update error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Edit Team Member
      </h1>
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <TextField label="Name" variant="outlined" fullWidth value={name} onChange={(e) => setName(e.target.value)} />
        <TextField label="Designation" variant="outlined" fullWidth value={designation} onChange={(e) => setDesignation(e.target.value)} />
        {imageUrl && (
          <div className="relative">
            <button type="button" className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1" onClick={handleImageRemove}>
              ✕
            </button>
            <img src={imageUrl} alt="Uploaded" className="w-[300px] rounded-lg" />
          </div>
        )}
        <Button variant="outlined" component="label" className="dark:text-dark-primary w-[250px]">
          Upload Thumbnail
          <input type="file" hidden onChange={(e) => e.target.files?.[0] && handleImageUpload(e.target.files[0])} disabled={loading} />
        </Button>
        <Button type="submit" variant="contained" color="primary" disabled={loading}>
          {loading ? "Updating..." : "Update"}
        </Button>
      </form>
    </div>
  );
}

export default EditConsTeamMembers;
