import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterListIcon from "@mui/icons-material/FilterList";
import TextField from "@mui/material/TextField";
import DateRangePickerCustom from "~/modules/InquiriesModule/components/InquiryTable/components/InquiryFilterButtons/components/DateRangePicker";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";

import CustomerFilter from "~/modules/InquiriesModule/components/InquiryTable/components/InquiryFilterButtons/components/CustomerFilter";
import ServicesFilter from "../../../ServicesLeads/components/LeadsFilter/components/ServicesFilter";
import ConsultancyFilter from "./components/ConsultancyFilter";

const ConsultancyLeadFilter = ({
  selectedLeadNo,
  setSelectedLeadNo,
  selectedDates,
  setSelectedDates,
  // setSelectedCustomer,
  setSelectedName,
}: any) => {
  const { travelHouse }: any = travelHouseStore();
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <div className="flex items-center gap-3">
          <FilterListIcon /> <p>Filters</p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="w-full bg-secondary dark:bg-dark-secondary">
          <div className="grid xl:grid-cols-4 md:grid-cols-2 items-center w-full gap-5">
            <DateRangePickerCustom
              dates={selectedDates}
              setDates={setSelectedDates}
            />
            <div className="mt-[8px]">
              <TextField
                label="Lead No"
                variant="outlined"
                value={selectedLeadNo}
                fullWidth
                onChange={(e: any) => setSelectedLeadNo(e.target.value)}
              />
            </div>
            {/* <div className="mt-[8px]">
              <CustomerFilter
                setSelectedCustomer={setSelectedCustomer}
                travelHouse={travelHouse}
              />
            </div> */}
            <div className="mt-[8px]">
              <ConsultancyFilter setSelectedName={setSelectedName} />
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default ConsultancyLeadFilter;
