import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useQuery, useMutation } from "@apollo/client";
import {
  TextField,
  Button,
  Box,
  Select,
  MenuItem,
  FormLabel,
  FormHelperText,
  FormControlLabel,
  Switch, // Added Switch component
} from "@mui/material";
import ContentEditor from "~/components/common/ContentEditor";
import { GET_UNIVERSITIES_WITH_BRD_ID } from "~/graphql/cons_university/Query";
import { INSERT_BLOG } from "~/graphql/cons_blogs/Mutation";
import { toast } from "react-toastify";
import { nhost } from "~/lib/nhost";
import { useNavigate, useParams } from "react-router-dom"; // Import useNavigate for redirection
import { GET_CONSULTANCY_BLOGS } from "~/graphql/cons_blogs/Query";

function AddConsultancyBlog() {
  const { brd_id } = useParams();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [content, setContent] = useState("");
  const [selectedUniversity, setSelectedUniversity] = useState("");
  const [category, setCategory] = useState("");
  const [imageId, setImageId] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState("");
  const [isFeatured, setIsFeatured] = useState(false); // State for featured switch

  const navigate = useNavigate(); // Initialize useNavigate for redirection

  const {
    data: universityData,
    loading: universityLoading,
    error,
  } = useQuery(GET_UNIVERSITIES_WITH_BRD_ID, {
    variables: { brd_id: "b5157583-c9d8-4cd1-b852-d89e325af75d" }, // Ensure this matches your Hasura test
    fetchPolicy: "network-only", // Ensures it always fetches fresh data
  });

  // Mutation for inserting a blog
  const [insertBlog, { loading: inserting }] = useMutation(INSERT_BLOG, {
    onCompleted: () => {
      // Redirect to the Show Page after successful submission
      navigate(`/admin/brands/${brd_id}/cons_posts`); // Adjust the route as needed
      toast.success("Blog added successfully!");
    },
    onError: (error) => {
      console.error("Error adding blog:", error);
      toast.error("Failed to add blog.");
    },
    refetchQueries: [
      {
        query: GET_CONSULTANCY_BLOGS, // Refetch the query to update the list of blogs
        variables: { brd_id: "b5157583-c9d8-4cd1-b852-d89e325af75d" }, // Adjust variables as needed
      },
    ],
  });

  // Handle image upload
  const uploadImage = async (file: File) => {
    const response = await nhost.storage.upload({ file });
    if (response.error) throw new Error(response.error.message);
    setImageId(response.fileMetadata.id);
    setImageUrl(
      `${process.env.REACT_APP_NHOST_STORAGE_URL}${response.fileMetadata.id}`
    );
  };

  // Handle image removal
  const handleImageRemove = async () => {
    if (imageUrl && imageId) {
      await nhost.storage.delete({ fileId: imageId });
      setImageUrl("");
      setImageId(null);
    }
  };

  const onSubmit = async (formData: any) => {
    if (!selectedUniversity) {
      alert("Please select a university.");
      return;
    }

    try {
      await insertBlog({
        variables: {
          data: [
            {
              brd_id: "b5157583-c9d8-4cd1-b852-d89e325af75d",
              university_id: selectedUniversity,
              content,
              image_id: imageId || "", // Use image_id directly
              slug: formData.slug,
              title: formData.title,
              category: formData.category, // Add category to the mutation
              featured: isFeatured, // Add featured status to the mutation
            },
          ],
        },
      });
    } catch (error) {
      console.error("Error adding blog:", error);
      toast.error("Failed to add blog.");
    }
  };

  return (
    <div className="bg-secondary dark:bg-dark-secondary rounded-lg shadow p-5 mb-5 text-primary dark:text-dark-primary">
      <h1 className="text-xl">Add New Consultancy Blog</h1>
      <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-10">
          {/* Title Input */}
          <Controller
            name="title"
            control={control}
            defaultValue=""
            rules={{ required: "Title is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Title"
                variant="outlined"
                fullWidth
                error={!!errors.title}
                // helperText={errors.title?.message}
              />
            )}
          />

          {/* Slug Input */}
          <Controller
            name="slug"
            control={control}
            defaultValue=""
            rules={{ required: "Slug is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Slug"
                variant="outlined"
                fullWidth
                error={!!errors.slug}
                // helperText={errors.slug?.message}
              />
            )}
          />

          {/* University Dropdown */}
          <div>
            <Select
              labelId="university-label"
              fullWidth
              variant="outlined"
              value={selectedUniversity}
              onChange={(e) => setSelectedUniversity(e.target.value)}
              error={!selectedUniversity}
            >
              {universityLoading ? (
                <MenuItem disabled>Loading...</MenuItem>
              ) : universityData?.cons_university &&
                universityData.cons_university.length > 0 ? (
                universityData.cons_university.map(
                  (uni: { id: string; name: string }) => (
                    <MenuItem key={uni.id} value={uni.id}>
                      {uni.name}
                    </MenuItem>
                  )
                )
              ) : (
                <MenuItem disabled>No universities found</MenuItem>
              )}
            </Select>
            {!selectedUniversity && (
              <FormHelperText error>Please select a university.</FormHelperText>
            )}
          </div>

          {/* Category Dropdown */}
          <div>
            <Controller
              name="category"
              control={control}
              defaultValue=""
              rules={{ required: "Category is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Category"
                  variant="outlined"
                  fullWidth
                  error={!!errors.title}
                  // helperText={errors.title?.message}
                />
              )}
            />
          </div>

          {/* Image Upload */}
          <div>
            <FormLabel>Featured Image</FormLabel>
            <Button
              variant="outlined"
              component="label"
              className="dark:text-dark-primary w-[250px]"
            >
              Upload Thumbnail
              <input
                type="file"
                hidden
                onChange={async (e) => {
                  const file = e.target.files?.[0];
                  if (file) await uploadImage(file);
                }}
              />
            </Button>

            {imageUrl && (
              <div className="relative">
                <button
                  type="button"
                  className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1"
                  onClick={handleImageRemove}
                >
                  ✕
                </button>
                <img src={imageUrl} alt="Uploaded" className="w-[300px]" />
              </div>
            )}
          </div>

          {/* Featured Switch */}
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={isFeatured}
                  onChange={(e) => setIsFeatured(e.target.checked)}
                  color="primary"
                />
              }
              label="Featured"
            />
          </div>
        </div>

        {/* Blog Content */}
        <div className="col-span-2 mt-5">
          <FormLabel>Blog Content</FormLabel>

          <ContentEditor content={content} setContent={setContent} />
        </div>

        {/* Submit Button */}
        <Button
          type="submit"
          variant="contained"
          sx={{ marginTop: "20px" }}
          disabled={inserting}
        >
          {inserting ? "Saving..." : "Save Blog"}
        </Button>
      </Box>
    </div>
  );
}

export default AddConsultancyBlog;
