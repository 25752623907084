import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, TextField, Switch, FormControlLabel } from "@mui/material";
import { useMutation, useApolloClient, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { nhost } from "~/lib/nhost";
import { UPDATE_UNIVERSITY } from "~/graphql/cons_university/Mutation";
import {
  UPDATE_CONS_MEDIA_THUMBNAIL,
  UPDATE_CONS_MEDIAL_LOGO,
} from "~/graphql/cons_media/Mutation";
import { useNavigate, useParams } from "react-router-dom";
import { GET_UNIVERSITIES, GET_UNIVERSITy_BY_ID } from "~/graphql/cons_university/Query";

const EditUniversity = () => {
  const navigate = useNavigate();
  const { brd_id, university_id, country_id } = useParams();

  const { data: universityData, loading: universityLoading, error: Universitydataerror } = useQuery(
    GET_UNIVERSITy_BY_ID,
    {
      variables: { id: university_id },
      skip: !university_id, // Skip the query if university_id is not available
    }
  );

  const selectedUniversity = universityData?.cons_university?.[0];

  const [
    updateThumbnail,
    { data: thumbnailData, loading: thumbnailLoading, error: thumbnailError },
  ] = useMutation(UPDATE_CONS_MEDIA_THUMBNAIL);
  const [
    updateLogo,
    { data: logoData, loading: logoLoading, error: logoError },
  ] = useMutation(UPDATE_CONS_MEDIAL_LOGO);




  const thumbnail = selectedUniversity?.cons_media
    ?.filter(
      (media: {
        id: any;
        image_id: any;
        featured: boolean | null;
        logo: boolean | null;
      }) => media.featured === true && media.logo === null
    )
    ?.map((media: { id: any; image_id: any }) => ({
      id: media.id,
      image_id: media.image_id,
    }));

  const logo = selectedUniversity?.cons_media
    ?.filter(
      (media: {
        id: any;
        image_id: any;
        featured: boolean | null;
        logo: boolean | null;
      }) => media.featured === null && media.logo === true
    )
    ?.map((media: { id: any; image_id: any }) => ({
      id: media.id,
      image_id: media.image_id,
    }));






  const client = useApolloClient();
  const { register, handleSubmit, setValue, watch, reset } = useForm({
    defaultValues: {
      name: selectedUniversity?.name || "",
      address: selectedUniversity?.address || "",
      contact_info: selectedUniversity?.contact_info || "",
      benefits: selectedUniversity?.benefits || "",
      slug: selectedUniversity?.slug || "",
      description: selectedUniversity?.description || "",
      featured: selectedUniversity?.featured || false,
      imageId: selectedUniversity?.cons_media?.image_id || null,
      imageUrl: selectedUniversity?.cons_media?.image_id
        ? nhost.storage.getPublicUrl({
          fileId: selectedUniversity.cons_media.image_id,
        })
        : "",
      logoId: logo?.[0]?.image_id || null,
      logoUrl: logo?.[0]?.image_id
        ? nhost.storage.getPublicUrl({ fileId: logo[0].image_id })
        : "",
    },
  });

  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [newImage, setNewImage] = useState<string | null>(null);
  const [selectedLogo, setSelectedLogo] = useState<string | null>(null);
  const [newLogo, setNewLogo] = useState<string | null>(null);

  useEffect(() => {
    if (selectedUniversity) {
      setValue("name", selectedUniversity.name);
      setValue("address", selectedUniversity.address);
      setValue("contact_info", selectedUniversity.contact_info);
      setValue("benefits", selectedUniversity.benefits);
      setValue("slug", selectedUniversity.slug);
      setValue("description", selectedUniversity.description);
      setValue("featured", selectedUniversity.featured || false);
      setValue("imageId", selectedUniversity.cons_media?.image_id || null);
      setValue(
        "imageUrl",
        selectedUniversity.cons_media?.image_id
          ? nhost.storage.getPublicUrl({
            fileId: selectedUniversity.cons_media.image_id,
          })
          : ""
      );
      setValue("logoId", logo?.[0]?.image_id || null);
      setValue(
        "logoUrl",
        logo?.[0]?.image_id
          ? nhost.storage.getPublicUrl({ fileId: logo[0].image_id })
          : ""
      );
      setSelectedImage(selectedUniversity.cons_media?.[0]?.image_id);
      setSelectedLogo(logo?.[0]?.image_id);
    }
  }, [selectedUniversity, setValue]);

  const imageUrl = watch("imageUrl");
  const imageId = watch("imageId");
  const featured = watch("featured");
  const logoId = watch("logoId");
  const logoUrl = watch("logoUrl");

  const [updateUniversity, { loading }] = useMutation(UPDATE_UNIVERSITY, {
    refetchQueries: [
      {
        query: GET_UNIVERSITIES,
        variables: { id: university_id , brd_id : brd_id , university_id : university_id },
      },
    ],
  });

  const onSubmit = async (data: any) => {
    if (!selectedUniversity?.id) {
      toast.error("Missing university ID");
      return;
    }

    const universityData = {
      name: data.name,
      address: data.address,
      contact_info: data.contact_info,
      benefits: data.benefits,
      slug: data.slug,
      description: data.description,
      featured: data.featured,
    };

    const res = await updateUniversity({
      variables: {
        id: selectedUniversity.id,
        universityData,
      },
    });

    let resLogo: any = null;
    let resThumbnail: any = null;

    if (newLogo || selectedLogo) {
      resLogo = await updateLogo({
        variables: {
          id: logo?.[0]?.id,
          imageId: newLogo || selectedLogo,
        },
        refetchQueries: [
          {
            query: GET_UNIVERSITy_BY_ID,
            variables: { id: university_id },
          },
        ],
      });
    }

    if (newImage || selectedImage) {
      resThumbnail = await updateThumbnail({
        variables: {
          id: thumbnail?.[0]?.id,
          imageId: newImage || selectedImage,
        },
        refetchQueries: [
          {
            query: GET_UNIVERSITy_BY_ID,
            variables: { id: university_id },
          },
        ],
      });
    }

    if (
      res.data?.update_cons_university?.affected_rows > 0 &&
      ((!newLogo && !selectedLogo) ||
        resLogo?.data?.update_cons_media?.affected_rows > 0) &&
      ((!newImage && !selectedImage) ||
        resThumbnail?.data?.update_cons_media?.affected_rows > 0)
    ) {
      toast.success("Successfully updated");
      navigate(`/admin/brands/${brd_id}/countries/${country_id}/university`);
    }
  };

  const handleImageRemove = async () => {
    if (imageUrl && imageId) {
      await nhost.storage.delete({ fileId: imageId });
      setValue("imageUrl", "");
      setValue("imageId", null);
      setSelectedImage(null);
      setNewImage(null);
      toast.success("Thumbnail removed successfully");
    }
  };

  const handleLogoRemove = async () => {
    if (logoUrl && logoId) {
      await nhost.storage.delete({ fileId: logoId });
      setValue("logoUrl", "");
      setValue("logoId", null);
      setSelectedLogo(null);
      setNewLogo(null);
    toast.success("Logo removed successfully");
    }
  };

  const uploadImage = async (file: File) => {
    const response = await nhost.storage.upload({ file });
    if (response.error) throw new Error(response.error.message);
    const uploadedImageUrl = nhost.storage.getPublicUrl({
      fileId: response.fileMetadata.id,
    });
    setValue("imageId", response.fileMetadata.id);
    setValue("imageUrl", uploadedImageUrl);
    setNewImage(response.fileMetadata.id);
  };

  const uploadLogo = async (file: File) => {
    const response = await nhost.storage.upload({ file });
    if (response.error) throw new Error(response.error.message);
    const uploadedLogoUrl = nhost.storage.getPublicUrl({
      fileId: response.fileMetadata.id,
    });
    setValue("logoId", response.fileMetadata.id);
    setValue("logoUrl", uploadedLogoUrl);
    setNewLogo(response.fileMetadata.id);
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-primary dark:bg-dark-primary">
      <div className="w-full p-8 bg-white dark:bg-dark-secondary rounded-lg shadow-lg">
        <h1 className="text-center text-3xl font-bold text-basic dark:text-dark-primary mb-8">
          Edit University
        </h1>

        <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            size="medium"
            {...register("name")}
            className="md:col-span-2"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Address"
            variant="outlined"
            fullWidth
            size="medium"
            {...register("address")}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Contact Info"
            variant="outlined"
            fullWidth
            size="medium"
            {...register("contact_info")}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Benefits"
            variant="outlined"
            fullWidth
            size="medium"
            {...register("benefits")}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Slug"
            variant="outlined"
            fullWidth
            size="medium"
            {...register("slug")}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Description"
            variant="outlined"
            fullWidth
            size="medium"
            multiline
            rows={4}
            {...register("description")}
            className="md:col-span-2"
            InputLabelProps={{ shrink: true }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={featured}
                onChange={(e) => setValue("featured", e.target.checked)}
              />
            }
            label="Featured"
            className="md:col-span-2"
          />

          {newImage ? (
            <>
              <div className="relative md:col-span-2">
                <button
                  type="button"
                  className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1"
                  onClick={handleImageRemove}
                >
                  ✕
                </button>
                <img
                  src={`${process.env.REACT_APP_NHOST_STORAGE_URL}${newImage}`}
                  alt="Uploaded"
                  className="w-[300px]"
                />
              </div>
              <Button
                variant="outlined"
                component="label"
                className="dark:text-dark-primary w-[250px] md:col-span-2"
              >
                Upload Thumbnail
                <input
                  type="file"
                  hidden
                  onChange={async (e) => {
                    const file = e.target.files?.[0];
                    if (file) await uploadImage(file);
                  }}
                />
              </Button>
            </>
          ) : selectedImage ? (
            <>
              <div className="relative md:col-span-2">
                <button
                  type="button"
                  className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1"
                  onClick={handleImageRemove}
                >
                  ✕
                </button>
                <img
                  src={`${process.env.REACT_APP_NHOST_STORAGE_URL}${selectedImage}`}
                  alt="Uploaded"
                  className="w-[300px]"
                />
              </div>
              <Button
                variant="outlined"
                component="label"
                className="dark:text-dark-primary w-[250px] md:col-span-2"
              >
                Upload Thumbnail
                <input
                  type="file"
                  hidden
                  onChange={async (e) => {
                    const file = e.target.files?.[0];
                    if (file) await uploadImage(file);
                  }}
                />
              </Button>
            </>
          ) : (
            <></>
          )}

          {newLogo ? (
            <>
              <div className="relative md:col-span-2">
                <button
                  type="button"
                  className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1"
                  onClick={handleLogoRemove}
                >
                  ✕
                </button>
                <img
                  src={`${process.env.REACT_APP_NHOST_STORAGE_URL}${newLogo}`}
                  alt="Uploaded"
                  className="w-[300px]"
                />
              </div>
              <Button
                variant="outlined"
                component="label"
                className="dark:text-dark-primary w-[250px] md:col-span-2"
              >
                Upload Logo
                <input
                  type="file"
                  hidden
                  onChange={async (e) => {
                    const file = e.target.files?.[0];
                    if (file) await uploadLogo(file);
                  }}
                />
              </Button>
            </>
          ) : selectedLogo ? (
            <>
              <div className="relative md:col-span-2">
                <button
                  type="button"
                  className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1"
                  onClick={handleLogoRemove}
                >
                  ✕
                </button>
                <img
                  src={`${process.env.REACT_APP_NHOST_STORAGE_URL}${selectedLogo}`}
                  alt="Uploaded"
                  className="w-[300px]"
                />
              </div>
              <Button
                variant="outlined"
                component="label"
                className="dark:text-dark-primary w-[250px] md:col-span-2"
              >
                Upload Logo
                <input
                  type="file"
                  hidden
                  onChange={async (e) => {
                    const file = e.target.files?.[0];
                    if (file) await uploadLogo(file);
                  }}
                />
              </Button>
            </>
          ) : (
            <></>
          )}

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
            className="mt-6 md:col-span-2"
          >
            {loading ? "Updating..." : "Update University"}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default EditUniversity;