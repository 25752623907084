import Layout from "~/components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import PointOfContact from "~/modules/BrandModule/segments/Countries/components/PointOfContact";

const PointOfContactPage = () => {
  return (
    <div>
      <Layout>
        <BrandModule>
          <PointOfContact />
        </BrandModule>
      </Layout>
    </div>
  );
};

export default PointOfContactPage;
