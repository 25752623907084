import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useApolloClient, useMutation } from "@apollo/client";
import { INSERT_CONS_COUNTRY } from "~/graphql/cons_country/Mutation";
import { toast } from "react-toastify";

const AddCountries = ({ brdId, setSidePopup }: any) => {
  const { register, handleSubmit } = useForm();
  const client = useApolloClient();
  const [insertCountry] = useMutation(INSERT_CONS_COUNTRY);

  const onSubmit = async (data: any) => {
    try {
      const result = await insertCountry({
        variables: {
          data: [
            {
              name: data.country,
              slug: data.slug,
              brd_id: brdId,
            },
          ],
        },
      });

      if (result?.data?.insert_cons_country?.affected_rows > 0) {
        client.refetchQueries({
          include: "all",
        });
        toast.success("Country Added Successfully!");
        setSidePopup(false);
      }
    } catch (error) {
      toast.error("Failed to add country!");
      console.error(error);
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Add Countries
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Country Name"
          variant="outlined"
          fullWidth
          required
          {...register("country")}
          style={{ marginBottom: "16px" }}
        />
        <TextField
          label="Country Slug"
          variant="outlined"
          fullWidth
          required
          {...register("slug")}
          style={{ marginBottom: "16px" }}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Add
        </Button>
      </form>
    </div>
  );
};

export default AddCountries;
