import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { GET_CAMPUSES_BY_UNIVERSITY } from "~/graphql/cons_campuses/Query";
import { DELETE_CAMPUS } from "~/graphql/cons_campuses/Mutation";


 const ShowCampuses =  ({ universityId  , brdId}: any) => {
  const [fetchCampuses, { data, loading, error }] = useLazyQuery(GET_CAMPUSES_BY_UNIVERSITY);
  const [deleteCampus] = useMutation(DELETE_CAMPUS);
  const [campuses, setCampuses] = useState<any[]>([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedCampus, setSelectedCampus] = useState<any | null>(null);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (universityId) {
      fetchCampuses({ variables: { universityId } });
    }
  }, [universityId, fetchCampuses]);

  useEffect(() => {
    if (data?.cons_campus) {
      setCampuses(data.cons_campus);
    }
  }, [data]);

  const handleEdit = (campus: any) => {
    navigate(`/admin/brands/${brdId}/countries/university/${universityId}/campuses/${campus.id}`, {
    });
  };

  const handleDelete = async (id: string) => {
    try {
      const { data } = await deleteCampus({ variables: { id } });

      if (data?.delete_cons_campus?.affected_rows > 0) {
        setCampuses((prev) => prev.filter((campus) => campus.id !== id));
        toast.success("Campus deleted successfully");
      } else {
        toast.error("Failed to delete campus");
      }
    } catch (err) {
      console.error("Error deleting campus:", err);
      toast.error("An error occurred while deleting the campus");
    }
  };

  
    const handleViewDepartments = (campusId: any) => {
        navigate(`/admin/brands/${brdId}/countries/university/campuses/${campusId}/departments`);
      };
    // brd_id/countries/university/campuses/:campus_id/departments"
//  path=":brd_id/countries/university/campuses/:campus_id/departments"

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading campuses</p>;

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Slug</TableCell>
              <TableCell>Actions</TableCell>
              <TableCell>Departments</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {campuses.map((campus) => (
              <TableRow key={campus.id}>
                <TableCell>{campus.name}</TableCell>
                <TableCell>{campus.description}</TableCell>
                <TableCell>{campus.slug}</TableCell>
                <TableCell>
                  <div className="flex gap-3">
                    <Button onClick={() => handleEdit(campus)}>
                      <FaEdit size={18} />
                    </Button>
                    <Button onClick={() => handleDelete(campus.id)}>
                      <FaTrash size={18} />
                    </Button>
                  </div>
                </TableCell>
                <TableCell>
                  <Button color="primary" onClick={() => handleViewDepartments(campus.id)}>
                    View Departments
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* {openPopup && selectedCampus && (
        <SideDrawer
          component={<EditCampus selectedCampus={selectedCampus} setSidePopup={setOpenPopup} />}
          sidePopup={openPopup}
          setSidePopup={setOpenPopup}
        />
      )} */}
    </>
  );
};

export default ShowCampuses;
