import { gql } from "@apollo/client";

export const INSERT_CONS_HEADINGS = gql`
  mutation InsertConsHeadings($objects: [cons_headings_insert_input!]!) {
    insert_cons_headings(objects: $objects) {
      affected_rows
    }
  }
`;

export const UPDATE_CONS_HEADINGS = gql`
  mutation UpdateConsHeadings(
    $id: uuid!
    $page_name: String!
    $section_name: String!
    $heading: String!
    $description: String!
  ) {
    update_cons_headings(
      where: { id: { _eq: $id } }
      _set: {
        page_name: $page_name
        section_name: $section_name
        heading: $heading
        description: $description
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_CONS_HEADINGS = gql`
  mutation DeleteConsHeadings($id: uuid!) {
    delete_cons_headings(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
