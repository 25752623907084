import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useForm, Controller } from "react-hook-form";
import { Button, TextField, Select, MenuItem, FormControl, InputLabel, FormHelperText, FormControlLabel, Switch } from "@mui/material";
import { toast } from "react-toastify";
import { INSERT_CONS_FAQS } from "~/graphql/cons_faqs/Mutation";
import { GET_CONSULTANCY_FAQS } from "~/graphql/cons_faqs/Query";
import { GET_UNIVERSITIES_WITH_BRD_ID } from "~/graphql/cons_university/Query";

const AddFAQS = ({ brdId , setSidePopup }: any) => {
  const { control, handleSubmit, setValue } = useForm();
  const [loading, setLoading] = useState(false);
  const [selectedUniversity, setSelectedUniversity] = useState("");

  // Fetch universities
  const { data: universityData, loading: universityLoading } = useQuery(GET_UNIVERSITIES_WITH_BRD_ID, {
    variables: { brd_id: brdId },
    fetchPolicy: "network-only",
  });

  // Mutation for inserting FAQ
  const [insertFAQ] = useMutation(INSERT_CONS_FAQS, {
    onCompleted: () => {
      toast.success("FAQ added successfully!");
      setSidePopup(false);
      setValue("title", "");
      setValue("description", "");
      setValue("featured", false);
      setSelectedUniversity(""); // Reset university selection
    },
    onError: (error) => {
      toast.error(`Error: ${error.message}`);
    },
    refetchQueries: [{ query: GET_CONSULTANCY_FAQS, variables: { brdId } , fetchPolicy: "network-only" }],
  });

  // Handle form submission
  const onSubmit = async (data: any) => {
    if (!selectedUniversity) {
      toast.error("Please select a university.");
      return;
    }

    setLoading(true);
    try {
   const response  = await insertFAQ({
        variables: {
          data: [
            {
              brd_id: brdId,
              title: data.title,
              description: data.description,
              featured: data.featured,
              university_id: selectedUniversity, // Include selected university ID
            },
          ],
        },
      });
      console.log("respose" ,  brdId,
         data.title,
         data.description,
        data.featured,
        selectedUniversity,)
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Add New FAQ
      </h1>

      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        {/* Title Input */}
        <Controller
          name="title"
          control={control}
          defaultValue=""
          rules={{ required: "Title is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Title"
              variant="outlined"
              fullWidth
              // error={!!errors.title}
              // helperText={errors.title?.message}
            />
          )}
        />

        {/* Description Input */}
        <Controller
          name="description"
          control={control}
          defaultValue=""
          rules={{ required: "Description is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Description"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              // error={!!errors.description}
              // helperText={errors.description?.message}
            />
          )}
        />

        {/* University Dropdown */}
        <FormControl fullWidth variant="outlined" error={!selectedUniversity}>
          <InputLabel id="university-label">University</InputLabel>
          <Select
            labelId="university-label"
            value={selectedUniversity}
            onChange={(e) => setSelectedUniversity(e.target.value)}
            label="University"
          >
            {universityLoading ? (
              <MenuItem disabled>Loading...</MenuItem>
            ) : universityData?.cons_university && universityData.cons_university.length > 0 ? (
              universityData.cons_university.map((uni: { id: string; name: string }) => (
                <MenuItem key={uni.id} value={uni.id}>
                  {uni.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No universities found</MenuItem>
            )}
          </Select>
          {!selectedUniversity && <FormHelperText error>Please select a university.</FormHelperText>}
        </FormControl>

        {/* Featured Toggle */}
        <Controller
          name="featured"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Switch
                  {...field}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                  color="primary"
                />
              }
              label="Featured"
            />
          )}
        />

        {/* Submit Button */}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading || !selectedUniversity}
        >
          {loading ? "Adding..." : "Add FAQ"}
        </Button>
      </form>
    </div>
  );
};

export default AddFAQS;