import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SideDrawer from "~/components/common/sideDrawer";
import { GET_DEPARTMENTS_BY_CAMPUS } from "~/graphql/cons_departments/Query";
import { DELETE_DEPARTMENT } from "~/graphql/cons_departments/Mutation";
import EditDepartment from "../EditDepartments";

const ShowDepartments = ({ campusId , brdId }: any) => {
  const [fetchDepartments, { data, loading, error }] = useLazyQuery(GET_DEPARTMENTS_BY_CAMPUS);
  const [deleteDepartment, { loading: deleting }] = useMutation(DELETE_DEPARTMENT);
  const [departments, setDepartments] = useState<any[]>([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState<any | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (campusId) {
      fetchDepartments({ variables: { campusId } });
    }
  }, [campusId, fetchDepartments]);

  useEffect(() => {
    if (data?.cons_department) {
      setDepartments(data.cons_department);
    }
  }, [data]);

  const handleEdit = (department: any) => {
    setSelectedDepartment(department);
    setOpenPopup(true);
  };

  const handleDelete = async (id: string) => {
    try {
      const { data } = await deleteDepartment({ variables: { id } });
      if (data?.delete_cons_department?.affected_rows > 0) {
        setDepartments((prev) => prev.filter((dept) => dept.id !== id));
        toast.success("Department deleted successfully");
        
      } else {
        toast.error("Failed to delete department");
      }
    } catch (err) {
      console.error("Error deleting department:", err);
      toast.error("An error occurred while deleting the department");
    }
  };

  const handleViewPrograms = (departmentId: any) => {
    if (!brdId || !departmentId) {
      toast.error("Invalid department or brand ID");
      return;
    }
    navigate(`/admin/brands/${brdId}/countries/university/campuses/departments/${departmentId}/programs`);
};


  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading departments</p>;

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Slug</TableCell>
              <TableCell>Head</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {departments.map((department) => (
              <TableRow key={department.id}>
                <TableCell>{department.name}</TableCell>
                <TableCell>{department.slug}</TableCell>
                <TableCell>{department.head}</TableCell>
                <TableCell>
                  <div className="flex gap-3">
                    <Button onClick={() => handleEdit(department)}>
                      <FaEdit size={18} />
                    </Button>
                    <Button onClick={() => handleDelete(department.id)} disabled={deleting}>
                      <FaTrash size={18} />
                    </Button>
                  </div>
                </TableCell>
                <TableCell>
                  <Button color="primary" onClick={() => handleViewPrograms(department.id)}>
                    View Programs
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {openPopup && selectedDepartment && (
        <SideDrawer
          component={<EditDepartment selectedDepartment={selectedDepartment} setSidePopup={setOpenPopup} />}
          sidePopup={openPopup}
          setSidePopup={setOpenPopup}
        />
      )}
    </>
  );
};

export default ShowDepartments;
