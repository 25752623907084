import React, { useState } from "react";
import { useMutation, useApolloClient } from "@apollo/client";
import { useForm } from "react-hook-form";
import { Button, FormLabel, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { nhost } from "~/lib/nhost";
import { INSERT_CONS_TEAM } from "~/graphql/cons_team/Mutation";
import { GET_CONS_TEAM } from "~/graphql/cons_team/Query";

function AddConsTeamMembers({ brdId, setSidePopup }: any) {
  const { register, handleSubmit, setValue } = useForm();
  const [uploading, setUploading] = useState(false);
  const [imageId, setImageId] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState("");
  const client = useApolloClient();

  const [insertConsTeam, { loading, error }] = useMutation(INSERT_CONS_TEAM, {
    onCompleted: () => {
      toast.success("Team member added successfully!");
      setSidePopup(false);
      client.refetchQueries({ include: [GET_CONS_TEAM] });
    },
    onError: (error) => {
      toast.error(`Error: ${error.message}`);
    },
  });

  // Image Upload Function
  const uploadImage = async (file: File) => {
    setUploading(true);
    try {
      const { fileMetadata } = await nhost.storage.upload({ file });
      if (fileMetadata) {
        setImageId(fileMetadata.id);
        setImageUrl(nhost.storage.getPublicUrl({ fileId: fileMetadata.id }));
        setValue("image_id", fileMetadata.id);
      } else {
        console.error("File metadata is null");
      }
      setUploading(false);
    } catch (err) {
      console.error("Image Upload Error:", err);
      setUploading(false);
    }
  };

  // Handle image removal
  const handleImageRemove = async () => {
    if (imageUrl && imageId) {
      await nhost.storage.delete({ fileId: imageId });
      setImageUrl("");
      setImageId(null);
      setValue("image_id", null);
    }
  };

  // Submit Form
  const onSubmit = async (data: any) => {
    try {
      await insertConsTeam({
        variables: {
          data: {
            brd_id: brdId,
            name: data.name,
            designation: data.designation,
            image_id: data.image_id,
          },
        },
      });
    } catch (err) {
      console.error("Insertion Error:", err);
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Add Team Member
      </h1>

      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <TextField label="Name" variant="outlined" fullWidth {...register("name")} />
        <TextField label="Designation" variant="outlined" fullWidth {...register("designation")} />

        {/* Image Upload */}
        <div>
          <FormLabel>Profile Image</FormLabel>
          <Button variant="outlined" component="label" className="dark:text-dark-primary w-[250px]">
            Upload Image
            <input
              type="file"
              hidden
              onChange={async (e) => {
                const file = e.target.files?.[0];
                if (file) await uploadImage(file);
              }}
              disabled={uploading}
            />
          </Button>

          {imageUrl && (
            <div className="relative">
              <button
                type="button"
                className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1"
                onClick={handleImageRemove}
              >
                ✕
              </button>
              <img src={imageUrl} alt="Uploaded" className="w-[300px]" />
            </div>
          )}
          {uploading && <p className="text-blue-500">Uploading Image...</p>}
        </div>

        <Button type="submit" variant="contained" color="primary" disabled={loading || uploading}>
          {loading ? "Adding..." : "Add"}
        </Button>
        {error && <p className="text-red-500">Error: {error.message}</p>}
      </form>
    </div>
  );
}

export default AddConsTeamMembers;
