import React from "react";
import Layout from "~/components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import EditConsCampus from "~/modules/BrandModule/segments/Countries/components/Campuses/components/EditCampus/inedex";

function EditConsultancyCampusPage() {
  return (
    <div>
      <Layout>
        <BrandModule>
          <EditConsCampus />
        </BrandModule>
      </Layout>
    </div>
  );
}

export default EditConsultancyCampusPage;
