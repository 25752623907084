import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_PAGE_DATA } from "./graphql/Query";
import InputField from "~/components/FormInputs/InputField";
import { useForm } from "react-hook-form";
import EditFeatureSection from "./components/EditFeatureSection";
import HomePageSeo from "./components/HomepageSeo";
import RichContentEditor from "./components/RichContentEditor";
import BrandPgSetHeroSec from "~/components/common/BrandPgSetHeroSec";
import { useState } from "react";
import { toast } from "react-toastify";
import { useFileUpload } from "@nhost/react";
import brandsStore from "~/store/brandsStore/brandsStore";
import {
  INSERT_PAGE,
  UPDATE_HEADINGS,
} from "./components/EditHeroSection/graphql/Mutation";
import BrandSeoSetting from "~/components/common/BrandSeoSetting";
import {
  INSERT_SEO,
  UPDATE_SEO,
} from "./components/HomepageSeo/graphql/Mutation";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import ServicesSEOComp from "./components/ServicesSEOComp";

const MangePages = () => {
  const { travelHouse }: any = travelHouseStore();
  const { slug, brd_id } = useParams();
  const { loading, data } = useQuery(GET_PAGE_DATA, {
    variables: {
      id: brd_id,
      slug: slug === "home" ? `/` : slug,
    },
  });
 
  const [updateHeadings] = useMutation(UPDATE_HEADINGS);
  const [insertPage] = useMutation(INSERT_PAGE);
  const [insertSeo] = useMutation(INSERT_SEO);
  const [updateSeo] = useMutation(UPDATE_SEO);

  const { upload: uploadLogo, progress: logoUploadProgress } = useFileUpload();
  const client = useApolloClient();

  const [logoFile, setLogoFile] = useState<any | null>(null);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const page = data?.brd_pages?.at(0);
  const seo = page?.brd_seo_settings?.at(0);

  const addHeroSecDetails = async (inpData: any) => {
    if (logoFile) {
      const logoUploadRes = await uploadLogo(logoFile);
      inpData["featured_image"] = logoUploadRes?.id || null;
    }
    if (page?.id) {
      const res = await updateHeadings({
        variables: {
          id: { id: page.id },
          data: inpData,
        },
      });
      if (res?.data?.update_brd_pages_by_pk?.id) {
        toast.success("Headings updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    } else {
      const res = await insertPage({
        variables: {
          data: {
            brd_id: brd_id,
            slug: slug,
            name: slug?.replaceAll("-", " "),
            title: slug?.replaceAll("-", " "),
            ...inpData,
          },
        },
      });
      if (res?.data?.insert_brd_pages_one?.id) {
        toast.success("Headings updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    }
  };

  const addSeoSettings = async (inpData: any) => {
    if (seo?.id) {
      const res = await updateSeo({
        variables: {
          id: { id: seo?.id },
          data: {
            default_title: inpData?.title,
            default_description: inpData?.description,
            default_keywords: inpData?.keywords,
            canonical_url: inpData?.canonicalUrl,
            og_image_url: inpData?.openGraphImgUrl,
          },
        },
      });

      if (res?.data?.update_brd_seo_setting_by_pk?.id) {
        toast.success("SEO  updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    } else {
      const res = await insertSeo({
        variables: {
          data: {
            brd_id: brd_id,
            default_title: inpData?.title,
            default_description: inpData?.description,
            default_keywords: inpData?.keywords,
            canonical_url: inpData?.canonicalUrl,
            og_image_url: inpData?.openGraphImgUrl,

            page_id: page.id,
          },
        },
      });

      if (res?.data?.insert_brd_seo_setting_one?.id) {
        toast.success("SEO  updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    }
  };

  if (loading) return <p>Loading...</p>;
  // ;
  return (
    <div className="mb-4 col-span-full xl:mb-2">
      <BrandPgSetHeroSec
        page={page}               
        addHeroSecDetails={addHeroSecDetails}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        setLogoFile={setLogoFile}
      />
      {page?.id && (
        <>
          {slug === "home" ? (
            <EditFeatureSection page={page} />
          ) : (
            <RichContentEditor page={page} />
          )}

          <BrandSeoSetting
            seo={seo}
            insertSeoSettings={addSeoSettings}
            pageType="Home Page"
          />

          {/* {travelHouse.active_brand ===
          "43797e0f-75ab-4629-ad24-3d74037fc3ae" ? (
            <BrandSeoSetting
              seo={seo}
              insertSeoSettings={addSeoSettings}
              pageType="Home Page"
            />
          ) : travelHouse.active_brand ===
            "8091f67a-589b-4ac3-93f0-658e8e0ce967" ? (
            <BrandSeoSetting
              seo={seo}
              insertSeoSettings={addSeoSettings}
              pageType="Home Page"
            />
          ) : travelHouse.active_brand ===
            "54671601-ca1f-4214-8315-9c4396c62999" ? (
            <ServicesSEOComp />
          ) : null} */}
        </>
      )}
    </div>
  );
};

export default MangePages;
