import { gql } from "@apollo/client";

export const GET_CONSULTANCY_FAQS = gql`
  query GetConsultancyFaqs($brdId : uuid!) {
    cons_faqs(where: { brd_id: { _eq: $brdId} }) {
      brd_id
      id
      featured
      description
      title
      university_id
    }
  }
`;
