import React from "react";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { GET_CONS_DESIGNATION_WITH_BRD_ID } from "~/graphql/cons_designation/Query";
import { INSERT_CONS_POINT_OF_CONTACT } from "~/graphql/cons_point_of_contact/Mutation";
import { toast } from "react-toastify";

const AddPointOfContact = ({ universityId, brdId, setSidePopup }: any) => {
  const { register, handleSubmit, setValue, watch } = useForm();
  const [insertPointOfContact] = useMutation(INSERT_CONS_POINT_OF_CONTACT);
  const { data, loading, error } = useQuery(GET_CONS_DESIGNATION_WITH_BRD_ID, {
    variables: { brdId },
  });
  const client = useApolloClient();

  const onSubmit = async (formData: Record<string, any>) => {
    try {
      const { data } = await insertPointOfContact({
        variables: {
          email: formData.email,
          name: formData.name,
          phone_no: formData.phone_no,
          university_id: universityId,
          designation_id: formData.designation_id,
        },
      });

      if (data?.insert_cons_point_of_contact?.affected_rows > 0) {
        toast.success("Point of Contact added successfully!");
        client.refetchQueries({ include: "all" }); // Refresh all queries
        setSidePopup(false); // Close the popup on success
      } else {
        toast.error("Failed to add Point of Contact.");
      }
    } catch (error) {
      console.error("Mutation error:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Add Point of Contact
      </h1>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <TextField label="Name" fullWidth required {...register("name")} />
        <TextField
          label="Email"
          type="email"
          fullWidth
          required
          {...register("email")}
        />
        <TextField
          label="Phone No"
          fullWidth
          required
          {...register("phone_no")}
        />

        <FormControl fullWidth>
          <InputLabel>Designation</InputLabel>
          <Select
            value={watch("designation_id") || ""}
            onChange={(e) => setValue("designation_id", e.target.value)}
            required
          >
            {data?.cons_designation.map((designation: any) => (
              <MenuItem key={designation.id} value={designation.id}>
                {designation.designation}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button type="submit" variant="contained" color="primary" fullWidth>
          Submit
        </Button>
      </form>
    </div>
  );
};

export default AddPointOfContact;
