import React from "react";
import { TextField, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation, useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import { UPDATE_DEPARTMENT } from "~/graphql/cons_departments/Mutation";

const EditDepartment = ({ selectedDepartment, setSidePopup }: any) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      name: selectedDepartment?.name || "",
      slug: selectedDepartment?.slug || "",
    },
  });

  const [updateDepartment, { loading }] = useMutation(UPDATE_DEPARTMENT);
  const client = useApolloClient();

  const onSubmit = async (formData: any) => {
    if (!selectedDepartment?.id) {
      toast.error("Invalid department data: ID is missing");
      return;
    }

    try {
      const { data } = await updateDepartment({
        variables: {
          id: selectedDepartment.id,
          data: {
            name: formData.name,
            slug: formData.slug,
          },
        },
      });

      if (data?.update_cons_department?.affected_rows > 0) {
        toast.success("Department updated successfully!");
        client.refetchQueries({ include: "all" });
        setSidePopup(false);
      } else {
        toast.error("Update failed.");
      }
    } catch (error) {
      console.error("Error updating department:", error);
      toast.error("Failed to update department.");
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Edit Department
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Department Name"
          variant="outlined"
          required
          fullWidth
          {...register("name")}
          style={{ marginBottom: "16px" }}
        />
        <TextField
          label="Slug"
          variant="outlined"
          required
          fullWidth
          {...register("slug")}
          style={{ marginBottom: "16px" }}
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
        >
          {loading ? "Updating..." : "Update Department"}
        </Button>
      </form>
    </div>
  );
};

export default EditDepartment;
