import React, { useState } from "react";
import { useMutation, useApolloClient } from "@apollo/client";
import { useForm } from "react-hook-form";
import { Button, FormLabel, TextField } from "@mui/material";
import { toast } from "react-toastify"; // Toast library
import { nhost } from "~/lib/nhost"; // Nhost SDK import
import { INSERT_CONS_ABOUT_INFO } from "~/graphql/cons_about_info/Mutation";
import { GET_CONSULTANCY_ABOUT_INFO } from "~/graphql/cons_about_info/Query"; // Import the query to refetch

function ConsAddAboutHeroHeading({ brdId, setSidePopup }: any) {
  const { control, handleSubmit, register, setValue } = useForm();
  const [uploading, setUploading] = useState(false);
  const [imageId, setImageId] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState("");
  const client = useApolloClient();

  const [insertAboutInfo, { loading, error }] = useMutation(
    INSERT_CONS_ABOUT_INFO,
    {
      onCompleted: () => {
        toast.success("Consultancy about info added successfully!"); // Success toast
        setSidePopup(false); // Close the side popup
        client.refetchQueries({ include: [GET_CONSULTANCY_ABOUT_INFO] }); // Refetch the query
      },
      onError: (error) => {
        toast.error(`Error: ${error.message}`); // Error toast
      },
    }
  );

  // Image Upload Function
  const uploadImage = async (file: File) => {
    setUploading(true);
    try {
      const { fileMetadata } = await nhost.storage.upload({ file });
      if (fileMetadata) {
        setImageId(fileMetadata.id);
        setImageUrl(nhost.storage.getPublicUrl({ fileId: fileMetadata.id }));
        setValue("image_id", fileMetadata.id); // Set the image_id in the form
      } else {
        console.error("File metadata is null");
      }
      setUploading(false);
    } catch (err) {
      console.error("Image Upload Error:", err);
      setUploading(false);
    }
  };

  // Handle image removal
  const handleImageRemove = async () => {
    if (imageUrl && imageId) {
      await nhost.storage.delete({ fileId: imageId });
      setImageUrl("");
      setImageId(null);
      setValue("image_id", null); // Clear the image_id in the form
    }
  };

  // Submit Form
  const onSubmit = async (data: any) => {
    try {
      await insertAboutInfo({
        variables: {
          data: {
            brd_id: brdId,
            heading: data.heading,
            sub_heading: data.sub_heading,
            description: data.description,
            image_id: data.image_id, // Uploaded Image ID
          },
        },
      });
    } catch (err) {
      console.error("Insertion Error:", err);
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Add Consultancy Heading
      </h1>

      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Heading"
          variant="outlined"
          fullWidth
          {...register("heading")}
        />
        <TextField
          label="Sub Heading"
          variant="outlined"
          fullWidth
          {...register("sub_heading")}
        />
        <TextField
          label="Description"
          variant="outlined"
          fullWidth
          multiline
          rows={3}
          {...register("description")}
        />

        {/* Image Upload */}
        <div>
          <FormLabel>Featured Image</FormLabel>
          <Button
            variant="outlined"
            component="label"
            className="dark:text-dark-primary w-[250px]"
          >
            Upload Thumbnail
            <input
              type="file"
              hidden
              onChange={async (e) => {
                const file = e.target.files?.[0];
                if (file) await uploadImage(file);
              }}
              disabled={uploading}
            />
          </Button>

          {imageUrl && (
            <div className="relative">
              <button
                type="button"
                className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1"
                onClick={handleImageRemove}
              >
                ✕
              </button>
              <img src={imageUrl} alt="Uploaded" className="w-[300px]" />
            </div>
          )}
          {uploading && <p className="text-blue-500">Uploading Image...</p>}
        </div>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading || uploading}
        >
          {loading ? "Adding..." : "Add"}
        </Button>
        {error && <p className="text-red-500">Error: {error.message}</p>}
      </form>
    </div>
  );
}

export default ConsAddAboutHeroHeading;
