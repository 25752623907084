import Layout from "~/components/common/Layout";
import BookingModule from "~/modules/BookingModule";
import Bookings from "~/modules/BookingModule/components/Bookings";

export default function BookingsPage() {
  return (
    <Layout>
      <BookingModule>
        <Bookings />
      </BookingModule>
    </Layout>
  );
}
