import { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import { GET_CONS_HEADINGS } from "~/graphql/cons_headings/Query";

import { toast } from "react-toastify";

import SideDrawer from "~/components/common/sideDrawer";
import { useNavigate } from "react-router-dom";
import EditConsHeadings from "../EditConsHeadings";
import { DELETE_CONS_HEADINGS } from "~/graphql/cons_headings/Mutation";

const ShowConsHeadings = ({ brdId }: any) => {
  const [fetchHeadings, { data, loading, error }] =
    useLazyQuery(GET_CONS_HEADINGS);
  const [
    deleteConsHeadings,
    { data: deleteConHeadingData, error: deleteConHeadingError },
  ] = useMutation(DELETE_CONS_HEADINGS);
  const [openPopup, setOpenPopup] = useState(false);
  const [headings, setHeadings] = useState<any[]>([]);
  const [selectedHeading, setSelectedHeading] = useState<any | null>(null);
  const navigate = useNavigate();
  const client = useApolloClient();

  useEffect(() => {
    if (brdId) {
      fetchHeadings({ variables: { brdId } });
    }
  }, [brdId, fetchHeadings]);

  useEffect(() => {
    if (data?.cons_headings) {
      setHeadings(data.cons_headings);
    }
  }, [data]);

  const handleEdit = (heading: any) => {
    setSelectedHeading(heading);
    setOpenPopup(true);
  };

  const handleDelete = async (id: any) => {
    try {
      const response = await deleteConsHeadings({
        variables: { id },
      });

      if (response.data.delete_cons_headings.affected_rows > 0) {
        client.refetchQueries({
          include: "all",
        });
        toast.success("Deletion successful!");
      } else {
        toast.warning("No rows were deleted.");
      }
    } catch (error) {
      toast.error("Failed to delete heading!");
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading headings</p>;

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Heading</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Page Name</TableCell>
              <TableCell>Section Name</TableCell>

              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {headings.map((heading) => (
              <TableRow key={heading.slug}>
                <TableCell>{heading.heading || "NA"}</TableCell>
                <TableCell>{heading.description || "NA"}</TableCell>
                <TableCell>{heading.page_name || "NA"}</TableCell>
                <TableCell>{heading.section_name || "NA"}</TableCell>

                <TableCell>
                  <div className="flex gap-3">
                    <Button onClick={() => handleEdit(heading)}>
                      <FaEdit size={18} />
                    </Button>
                    <Button onClick={() => handleDelete(heading.id)}>
                      <FaTrash size={18} />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {openPopup && selectedHeading && (
        <SideDrawer
          component={
            <EditConsHeadings
              selectedHeading={selectedHeading}
              setSidePopup={setOpenPopup}
            />
          }
          sidePopup={openPopup}
          setSidePopup={setOpenPopup}
        />
      )}
    </>
  );
};

export default ShowConsHeadings;
