// Import react router v6
import { Routes, Route } from "react-router-dom";
// Import protected route
import { ProtectedRoute } from "./ProtectedRoute";
// Import Pages
import ProfilePage from "../pages/admin/ProfilePage";
import Dashboard from "../pages/admin/Dashboard";
import Customers from "../pages/admin/Customers";
import Subscriptions from "../pages/admin/Subscriptions";
import Teams from "../pages/admin/Teams";
import Vendors from "../pages/admin/Vendors";
import PaymentsMethods from "../pages/admin/PaymentsMethods";
import API from "../pages/admin/API";
import ProfitSlabs from "../pages/admin/ProfitSlabs";
import TicketRequests from "../pages/admin/TicketRequests";
import Destinations from "../pages/admin/Destinations";
import Inquiry from "../pages/admin/Inquiry";
import Inbox from "../pages/admin/Inbox";
import Reports from "~/pages/admin/Reports";
import VendorDeposits from "~/pages/admin/VendorDeposits";
import FlightsPages from "~/pages/admin/FlightsPages";
import CustomerPayments from "~/pages/admin/CustomerPayments";
import Domains from "~/pages/admin/Domains";
import InboxPage from "../pages/admin/Inbox";
import TransactionsPage from "~/pages/admin/TransactionsPage";
import AccountsPage from "~/pages/admin/AccountsPage";

import InquiriesPage from "../pages/admin/InquiriesPage";
import AttendancePage from "~/pages/admin/AttendancePage";
import GeneratePayrollPage from "~/pages/admin/GeneratePayrollPage";
import WhatsappPage from "~/pages/whatsapp/WhatsappPage";
import GatherDataPage from "~/pages/admin/GatherDataPage";
import GoogleAdsPage from "~/pages/admin/GoogleAdsPage";
import GoogleCampaignPage from "~/pages/admin/GoogleCampaignPage";
import GoogleCampaignDetailPage from "~/pages/admin/GoogleCampaignDetailPage";
import GoogleAdDetailPage from "~/pages/admin/GoogleAdDetailPage";
import CallPage from "~/pages/admin/CallPage";
import EamilPage from "~/pages/admin/EamilPage";
import WhatsappTemplatesPage from "~/pages/whatsapp/WhatsappTemplatesPage";
import TemplateCreatePage from "~/pages/whatsapp/TemplateCreatePage";
import WhatsappMainPage from "~/pages/admin/WhatsappMainPage";
import WhatsappPageCommunity from "~/pages/whatsapp/WhatsappPageCommunity";
import CustomerPage from "~/pages/admin/CustomerPage";
import AddPrevData from "~/pages/admin/AddPevData";
import ProfileAccountPage from "~/pages/admin/ProfileAccountPage";
import PostSchedulePage from "~/pages/admin/PostSchedulePage";
import ServicesPage from "~/pages/admin/ServicesPage";
import CategoryPage from "~/pages/admin/CategoryPage";
import ServicesLeadsPage from "~/pages/admin/ServicesLeadsPage";
import EditServicesPage from "~/pages/admin/EditServicesPage";
import CaseStudyPage from "~/pages/admin/CaseStudyPage";
import AddCaseStudyPage from "~/pages/admin/AddCaseStudyPage";
import EditCaseStudyPage from "~/pages/admin/EditCaseStudyPage";
import PageHeadingsPage from "~/pages/brands/PageHeadingsPage";
import AddServicePage from "~/pages/admin/AddServicePage";
import MainCaseStudyPage from "~/pages/admin/MainCaseStudyPage";
import EmailCampaignPage from "~/pages/admin/EmailCampaignPage";
import NewEmailCampaign from "~/pages/admin/NewEmailCampaign";
import EmailContactListPage from "~/pages/admin/EmailContactListPage";
import EmailSenderListPage from "~/pages/admin/EmailSenderListPage";
import FlProjectPage from "~/pages/admin/FlProjectPage";
import AddCsvInqPage from "~/pages/admin/AddCsvInqPage";
import BookingsPage from "~/pages/admin/BookingsPage";
import ConsultancyLeadsPage from "~/pages/admin/ConsultancyLeadsPage";

export default function AdminRoutes() {
  return (
    <Routes>
      <Route path="/admin" element={<ProtectedRoute role="admin" />}>
        <Route index element={<Dashboard />} />
        <Route path="/admin/:brd_id/services" element={<ServicesPage />} />
        <Route
          path="/admin/:brd_id/services/headingsettings/:pageType"
          element={<PageHeadingsPage />}
        />
        <Route path="/admin/:brd_id/casestudy" element={<CaseStudyPage />} />
        <Route
          path="/admin/:brd_id/casestudy/add"
          element={<AddCaseStudyPage />}
        />
        <Route
          path="/admin/:brd_id/casestudy/:case_study_id/edit"
          element={<EditCaseStudyPage />}
        />
        <Route
          path="/admin/:brd_id/services/addservice"
          element={<AddServicePage />}
        />
        <Route
          path="/admin/:brd_id/casestudysetting"
          element={<MainCaseStudyPage />}
        />
        <Route
          path="/admin/:brd_id/services/:service_id/editservice"
          element={<EditServicesPage />}
        />
        <Route path="/admin/category" element={<CategoryPage />} />

        <Route path="customer/:customerId" element={<CustomerPage />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="inquiries" element={<InquiriesPage />} />
        <Route path="customers" element={<Customers />} />
        <Route path="inbox" element={<Inbox />} />
        <Route path="inbox/:code" element={<InboxPage />} />
        <Route path="subscriptions" element={<Subscriptions />} />
        <Route path="inquiry/:inquiry_id" element={<Inquiry />} />
        <Route path="domains" element={<Domains />} />
        <Route path="teams" element={<Teams />} />
        <Route path="vendors" element={<Vendors />} />
        <Route path="vendor-deposits" element={<VendorDeposits />} />
        <Route path="payment-methods" element={<PaymentsMethods />} />
        <Route path="api" element={<API />} />
        <Route path="profit-slabs" element={<ProfitSlabs />} />
        <Route path="ticket-requests" element={<TicketRequests />} />
        <Route path="destinations" element={<Destinations />} />
        <Route path="reports" element={<Reports />} />
        <Route path="flights-pages" element={<FlightsPages />} />
        <Route path="customer-payments" element={<CustomerPayments />} />
        <Route path="transactions" element={<TransactionsPage />} />
        <Route path="accounts" element={<AccountsPage />} />
        <Route path="attendance" element={<AttendancePage />} />
        <Route path="process-payroll" element={<GeneratePayrollPage />} />
        <Route path="gather-data" element={<GatherDataPage />} />
        <Route path="google-ads" element={<GoogleAdsPage />} />
        <Route path="google-campaigns" element={<GoogleCampaignPage />} />
        <Route path="email-campaigns" element={<EmailCampaignPage />} />
        <Route path="email-campaigns/add" element={<NewEmailCampaign />} />
        <Route
          path="/admin/email-campaigns/contacts-list"
          element={<EmailContactListPage />}
        />
        <Route
          path="/admin/email-campaigns/senders-list"
          element={<EmailSenderListPage />}
        />
        <Route path="post-schedule" element={<PostSchedulePage />} />
        <Route
          path="google-campaigns/:campaign_id"
          element={<GoogleCampaignDetailPage />}
        />
        <Route
          path="google-campaigns/:campaign_id/:ad_id"
          element={<GoogleAdDetailPage />}
        />
        <Route path="call" element={<CallPage />} />

        <Route path="emails" element={<EamilPage />} />
        <Route path="emails/:conversation_id" element={<EamilPage />} />

        <Route path="whatsapp-ui" element={<WhatsappMainPage />} />

        <Route path="whatsapp" element={<WhatsappPage />} />

        <Route path="whatsapp/templates" element={<WhatsappTemplatesPage />} />
        <Route
          path="whatsapp/templates/create"
          element={<TemplateCreatePage />}
        />
        <Route path="whatsapp/:conversation_id" element={<WhatsappPage />} />
        <Route
          path="whatsapp/community/:conversation_id"
          element={<WhatsappPageCommunity />}
        />

        <Route path="addPrevData" element={<AddPrevData />} />
        <Route path="addCsvInq" element={<AddCsvInqPage />} />
        <Route path="profileAccount" element={<ProfileAccountPage />} />
        <Route path="service/leads" element={<ServicesLeadsPage />} />
        <Route path="consultancy/leads" element={<ConsultancyLeadsPage />} />
        <Route path="digitalAgen/projects" element={<FlProjectPage />} />
        <Route path="bookings" element={<BookingsPage />} />
      </Route>
    </Routes>
  );
}
