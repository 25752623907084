import { gql } from "@apollo/client";


export const INSERT_PROGRAMS_REQUIREMENTS = gql`
  mutation InsertProgramREquirements($data: [cons_programs_requirements_insert_input!]!) {
    insert_cons_programs_requirements(objects: $data) {
      affected_rows
    }
  }
`;



export const UPDATE_PROGRAMS_REQUIREMENTS= gql`
  mutation UpdateProgramsRequirements($id: uuid!, $data: cons_programs_requirements_set_input!) {
    update_cons_programs_requirements(
      where: { id: { _eq: $id } }
      _set: $data
    ) {
      affected_rows
    }
  }
`;

export const DELETE_PROGRAM_REQUIREMENTS = gql`
  mutation DeleteProgramRequirements($id: uuid!) {
    delete_cons_programs_requirements(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
