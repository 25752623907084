
import {
  Button,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useForm, Controller, useWatch } from "react-hook-form";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";

import { GET_CONS_HEADINGS } from "~/graphql/cons_headings/Query";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { UPDATE_CONS_HEADINGS } from "~/graphql/cons_headings/Mutation";

const pageOptions = [
  { label: "Home", value: "home" },
  { label: "Blogs", value: "blogs" },
  { label: "About", value: "about" },
  { label: "Contact", value: "contact" },
  { label: "Announcment", value: "announcment" },
];

const sectionOptionsMap: Record<string, { label: string; value: string }[]> = {
  home: [
    { label: "Approval Steps", value: "approval_steps" },
    { label: "Featured Universities", value: "featured_universities" },
    {label :"Featured Blogs"  , value : "featured_blogs" } ,
    { label: "Testimonial Section", value: "testimonial_section" },
    { label: "Header Section", value: "heaader_section" },
    { label: "Learn More", value: "learn_more_button" },
    { label: "Submit Form", value: "form_button" },
  ],
  about: [
    { label: "About Hero", value: "about_hero" },
    { label: "FAQs Section", value: "faqs_section" },
    { label: "Our Team Section", value: "team_section" },
  ],
  blogs: [{ label: "Blogs Heading", value: "blogs_heading" }],
  contact: [{label  : "Contact Us" , value : "contact_heading"}],
  announcment: [{label  : "Announcment" , value : "announcment_section"}]
};

interface FormValues {
  title: string;
  description: string;
  pageName: string;
  sectionName: string;
}

const EditConsHeadings = ({
  setSidePopup,
  selectedHeading,
}: {
  setSidePopup: (open: boolean) => void;
  selectedHeading: any;
}) => {
  const { brd_id } = useParams();

  const client = useApolloClient();
  const { register, handleSubmit, control, reset } = useForm<FormValues>({
    defaultValues: {
      title: selectedHeading?.heading || "",
      description: selectedHeading?.description || "",
      pageName: selectedHeading?.page_name || "",
      sectionName: selectedHeading?.section_name || "",
    },
  });

  const selectedPage = useWatch({ control, name: "pageName" });

  const { data, error: headigError } = useQuery(GET_CONS_HEADINGS, {
    variables: { brdId: brd_id },
  });

  const existingSectionNames = new Set(
    data?.cons_headings?.map((item: any) => item.section_name) || []
  );

  const filteredSectionOptions =
    sectionOptionsMap[selectedPage]?.filter(
      (option) => !existingSectionNames.has(option.value)
    ) || [];

  const [updateConsHeadings, { data: dataConsHeadigs, error }] =
    useMutation(UPDATE_CONS_HEADINGS);

  const onSubmit = async (data: FormValues) => {
    try {
      const response = await updateConsHeadings({
        variables: {
          id: selectedHeading.id,
          page_name: data.pageName,
          section_name: data.sectionName,
          heading: data.title,
          description: data.description,
        },
      });

      if (response?.data?.update_cons_headings?.affected_rows) {
        client.refetchQueries({
          include: "all",
        });
        toast.success("Heading updated successfully!");
      }
      setSidePopup(false);
      reset();
    } catch (error) {
      toast.error("Failed to update heading!");
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Edit Consultancy Heading
      </h1>

      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <TextField
          label="Heading"
          variant="outlined"
          fullWidth
          {...register("title")}
        />
        <TextField
          label="Description"
          variant="outlined"
          fullWidth
          {...register("description")}
        />

        <FormControl fullWidth>
          <InputLabel>Page Name</InputLabel>
          <Controller
            name="pageName"
            control={control}
            render={({ field }) => (
              <Select {...field} label="Page Name">
                {pageOptions.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Section Name</InputLabel>
          <Controller
            name="sectionName"
            control={control}
            render={({ field }) => (
              <Select {...field} label="Section Name">
                {filteredSectionOptions.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>

        <Button type="submit" variant="contained" color="primary">
          Update
        </Button>
      </form>
    </div>
  );
};

export default EditConsHeadings;
