// Import react router v6
import { Routes, Route } from "react-router-dom";
// Import protected routes
import { ProtectedRoute } from "./ProtectedRoute";
// Import Pages
import ProfilePage from "../pages/consultant/ProfilePage";
import Dashboard from "../pages/consultant/Dashboard";
import InquiriesPage from "../pages/consultant/InquiriesPage";
import MyComission from "../pages/consultant/MyComission";
import Inquiry from "../pages/consultant/Inquiry";
import InboxPage from "~/pages/consultant/Inbox";
import WhatsappPage from "~/pages/consultant/WhatsappPage";
import EmailPage from "~/pages/consultant/EmailPage";
import WhatsappPageCommunity from "~/pages/whatsapp/WhatsappPageCommunity";
import Customers from "~/pages/consultant/Customers";
import CustomerTimelinePage from "~/pages/consultant/CustomerTimelinePage";
import CustomerDetails from "~/pages/consultant/CustomerDetails";
import BookingsPage from "~/pages/consultant/BookingsPage";
import ConsultancyLeadsPage from "~/pages/admin/ConsultancyLeadsPage";
// import InboxPage from "~/pages/admin/Inbox";

export default function ConsultantRoutes() {
  return (
    <Routes>
      <Route path="/consultant" element={<ProtectedRoute role="consultant" />}>
        <Route path="consultancy/leads" element={<ConsultancyLeadsPage />} />
        <Route index element={<Dashboard />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="inquiries" element={<InquiriesPage />} />
        <Route path="customers" element={<Customers />} />
        <Route path="inbox" element={<InboxPage />} />
        <Route path="emails" element={<EmailPage />} />
        <Route path="emails/:conversation_id" element={<EmailPage />} />
        <Route path="whatsapp" element={<WhatsappPage />} />
        <Route path="whatsapp/:conversation_id" element={<WhatsappPage />} />
        <Route path="inquiry/:inquiry_id" element={<Inquiry />} />
        <Route
          path="whatsapp/community/:conversation_id"
          element={<WhatsappPageCommunity />}
        />

        <Route
          path="brands/:brd_id/customer/:user_id"
          element={<CustomerTimelinePage />}
        />
        <Route path="customer/:customerId" element={<CustomerDetails />} />
        <Route path="bookings" element={<BookingsPage />} />
      </Route>
    </Routes>
  );
}
