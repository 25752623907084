import SidePopup from "~/components/common/SidePopup";
import SideDrawer from "~/components/common/sideDrawer";
import { useState } from "react";
import { Button } from "@mui/material";
import ShowConsSocialLinks from "./component/ShowConsSocialLinks";
import AddConsSocialLinks from "./component/AddConsSocialLinks";


const ConsSocialLinks = ({ brdId }: { brdId: any }) => {
  const [openPopup, setOpenPopup] = useState(false);

  return (
    <div className="bg-secondary dark:bg-dark-secondary rounded-lg p-5 mb-5">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center justify-center h-full ">
          <h1 className="text-xl flex items-center justify-center">
            Consultancy Social Links
          </h1>
        </div>

        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenPopup(true)}
        >
          Add Consultancy Social Links
        </Button>
      </div>
      <ShowConsSocialLinks brdId={brdId} />

      <SideDrawer
        component={
          <AddConsSocialLinks brdId={brdId} setSidePopup={setOpenPopup} />
        }
        sidePopup={openPopup}
        setSidePopup={setOpenPopup}
      />
    </div>
  );
};

export default ConsSocialLinks;
