import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, TextField } from "@mui/material";
import { useMutation, useApolloClient, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { nhost } from "~/lib/nhost";
import { UPDATE_CAMPUS } from "~/graphql/cons_campuses/Mutation";
import { UPDATE_CONS_MEDIA_THUMBNAIL } from "~/graphql/cons_media/Mutation";
import { useNavigate, useParams } from "react-router-dom";
import { GET_CAMPUSES_BY_ID } from "~/graphql/cons_campuses/Query";

const EditConsCampus = () => {
  const navigate = useNavigate();
  const { brd_id, university_id, campus_id } = useParams();

  const {
    data: campusData,
    loading: campusLoading,
    error: campusdataerror,
  } = useQuery(GET_CAMPUSES_BY_ID, {
    variables: { id: campus_id },
    skip: !campus_id, // Skip the query if university_id is not available
  });

  const selectedCampus = campusData?.cons_campus?.[0];

  const [
    updateThumbnail,
    { data: thumbnailData, loading: thumbnailLoading, error: thumbnailError },
  ] = useMutation(UPDATE_CONS_MEDIA_THUMBNAIL);

  // Find the featured thumbnail from cons_media
  const thumbnail = selectedCampus?.cons_media?.find(
    (media: any) => media.featured === true
  );

  console.log("thumbnail", thumbnail);
  const client = useApolloClient();
  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      name: selectedCampus?.name || "",
      slug: selectedCampus?.slug || "",
      description: selectedCampus?.description || "",
      imageId: thumbnail?.image_id || null, // Use thumbnail image_id
      imageUrl: thumbnail?.image_id
        ? nhost.storage.getPublicUrl({ fileId: thumbnail.image_id })
        : "", // Use thumbnail image_id
    },
  });

  const [selectedImage, setSelectedImage] = useState<string | null>(null); // Previous image ID
  const [newImage, setNewImage] = useState<string | null>(null); // New uploaded image ID

  useEffect(() => {
    if (selectedCampus) {
      setValue("name", selectedCampus.name);
      setValue("slug", selectedCampus.slug);
      setValue("description", selectedCampus.description);

      // Set previous thumbnail if it exists
      if (thumbnail) {
        setValue("imageId", thumbnail.image_id);
        setValue(
          "imageUrl",
          nhost.storage.getPublicUrl({ fileId: thumbnail.image_id })
        );
        setSelectedImage(thumbnail.image_id); // Set previous image ID
      } else {
        setValue("imageId", null);
        setValue("imageUrl", "");
        setSelectedImage(null);
      }
    }
  }, [selectedCampus, setValue, thumbnail]);

  const imageUrl = watch("imageUrl");
  const imageId = watch("imageId");

  const [updateCampus, { loading }] = useMutation(UPDATE_CAMPUS);

  const onSubmit = async (data: any) => {
    if (!selectedCampus?.id) {
      toast.error("Missing campus ID");
      return;
    }

    if (!imageId) {
      toast.error("Please select an image!");
      return;
    }

    const campusData = {
      name: data.name,
      slug: data.slug,
      description: data.description,
    };

    try {
      const res = await updateCampus({
        variables: {
          id: selectedCampus.id,
          data: campusData,
        },
      });

      let resThumbnail: any = null;

      // Update thumbnail if a new image is uploaded
      if (newImage) {
        resThumbnail = await updateThumbnail({
          variables: {
            id: thumbnail?.id, // Use thumbnail ID
            imageId: newImage,
          },
        });

        // Delete the previous image from storage
        if (selectedImage) {
          await nhost.storage.delete({ fileId: selectedImage });
        }
      }

      // Check if all updates were successful
      if (
        res.data?.update_cons_campus?.affected_rows > 0 &&
        (!newImage || resThumbnail?.data?.update_cons_media?.affected_rows > 0)
      ) {
        toast.success("Campus updated successfully!");
        await client.refetchQueries({ include: "all" });
        navigate(
          `/admin/brands/${brd_id}/countries/university/${university_id}/campuses`
        );
      } else {
        toast.error("Failed to update campus.");
      }
    } catch (error) {
      console.error("Error updating campus:", error);
      toast.error("Failed to update campus.");
    }
  };

  const handleImageRemove = async () => {
    if (imageUrl && imageId) {
      await nhost.storage.delete({ fileId: imageId });
      setValue("imageUrl", "");
      setValue("imageId", null);
      setSelectedImage(null);
      setNewImage(null);
    }
  };

  const uploadImage = async (file: File) => {
    const response = await nhost.storage.upload({ file });
    if (response.error) throw new Error(response.error.message);
    const uploadedImageUrl = nhost.storage.getPublicUrl({
      fileId: response.fileMetadata.id,
    });
    setValue("imageId", response.fileMetadata.id);
    setValue("imageUrl", uploadedImageUrl);
    setNewImage(response.fileMetadata.id); // Save the new image ID
  };

  return (
    <div className=" w-full h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className=" text-2xl text-basic dark:text-dark-primary mb-8">
        Edit Campus
      </h1>

      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          {...register("name")}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="Slug"
          variant="outlined"
          fullWidth
          {...register("slug")}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="Description"
          variant="outlined"
          fullWidth
          {...register("description")}
          InputLabelProps={{ shrink: true }}
        />

        {/* Display current image (previous or new) */}
        {imageUrl && (
          <div className="relative">
            <button
              type="button"
              className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1"
              onClick={handleImageRemove}
            >
              ✕
            </button>
            <img src={imageUrl} alt="Thumbnail" className="w-[300px]" />
          </div>
        )}

        {/* Upload button */}
        <Button
          variant="outlined"
          component="label"
          className="dark:text-dark-primary w-[250px]"
        >
          Upload Thumbnail
          <input
            type="file"
            hidden
            onChange={async (e) => {
              const file = e.target.files?.[0];
              if (file) await uploadImage(file);
            }}
          />
        </Button>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? "Updating..." : "Update Campus"}
        </Button>
      </form>
    </div>
  );
};

export default EditConsCampus;
