import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import SideDrawer from "~/components/common/sideDrawer";
import { GET_PROGRMS } from "~/graphql/cons_programs/Query";
import { DELETE_PROGRAM } from "~/graphql/cons_programs/Mutation";
import EditPrograms from "../EditPrograms";
import { useNavigate } from "react-router-dom";
import { AnyAaaaRecord } from "dns";


const ShowPrograms = ({ departmentId , brdId }: any) => {
  const [fetchPrograms, { data, loading, error }] = useLazyQuery(GET_PROGRMS);
  const [programs, setPrograms] = useState<any[]>([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState<any | null>(null);
  const navigate = useNavigate()

  const [deleteProgram] = useMutation(DELETE_PROGRAM, {
    onCompleted: () => {
      toast.success("Program deleted successfully!");
    },
    onError: (error) => {
      console.error("Error deleting program:", error);
      toast.error("Failed to delete program.");
    },
  });

  useEffect(() => {
    if (departmentId) {
      fetchPrograms({ variables: { departmentId } });
    }
  }, [departmentId, fetchPrograms]);

  useEffect(() => {
    if (data?.cons_program) {
      setPrograms(data.cons_program);
    }
  }, [data]);

  const handleEdit = (program: any) => {
    setSelectedProgram(program);
    setOpenPopup(true);
  };

  const handleDelete = async (id: string) => {
    try {
      const { data } = await deleteProgram({
        variables: { id },
      });

      if (data?.delete_cons_program?.affected_rows > 0) {
        setPrograms(programs.filter((program) => program.id !== id));
      }
    } catch (error) {
      console.error("Error deleting program:", error);
    }
  };

  const handleViewIntakes = (programId: AnyAaaaRecord) => {
    navigate(`/admin/brands/${brdId}/countries/university/campuses/departments/programs/${programId}/intakes`);
  };
  const handleViewRequirements = (programId: AnyAaaaRecord) => {
    navigate(`/admin/brands/${brdId}/countries/university/campuses/departments/programs/${programId}/requirements`);
  };

  
  
      

  if (loading) return <p>Loading programs...</p>;
  if (error) return <p>Error fetching programs</p>;

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Slug</TableCell>
              <TableCell>Enrollment Criteria</TableCell>
              <TableCell>Minimum Marks Required</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {programs.map((program) => (
              <TableRow key={program.id}>
                <TableCell>{program.name}</TableCell>
                <TableCell>{program.slug}</TableCell>
                <TableCell>{program.enrollment_criteria}</TableCell>
                <TableCell>{program.min_marks_req}</TableCell>
                <TableCell>
                  <div className="flex gap-3">
                    <Button onClick={() => handleEdit(program)}>
                      <FaEdit size={18} />
                    </Button>
                    <Button onClick={() => handleDelete(program.id)}>
                      <FaTrash size={18} />
                    </Button>
                    <Button  color="primary" onClick={() => handleViewIntakes(program.id)}>
                      View Intakes
                    </Button>
                    <Button  color="primary" onClick={() => handleViewRequirements(program.id)}>
                      View Requirements
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {openPopup && selectedProgram && (
        <SideDrawer
          component={<EditPrograms selectedProgram={selectedProgram} setSidePopup={setOpenPopup} />}
          sidePopup={openPopup}
          setSidePopup={setOpenPopup}
        />
      )}
    </>
  );
};

export default ShowPrograms;
