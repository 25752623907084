import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { GET_CONS_DESIGNATION_WITH_BRD_ID } from "~/graphql/cons_designation/Query";
import { FaEdit, FaTrash } from "react-icons/fa";
import { DELETE_CONS_DESIGNATION_WITH_ID } from "~/graphql/cons_designation/Mutation";
import { toast } from "react-toastify";
import SideDrawer from "~/components/common/sideDrawer";
import EditManageDesignation from "../EditManageDesignation";

const ShowManageDesignation = ({ brdId }: any) => {
  const client = useApolloClient();
  const { data, loading, error } = useQuery(GET_CONS_DESIGNATION_WITH_BRD_ID, {
    variables: { brdId },
  });
  const [deleteDesignation] = useMutation(DELETE_CONS_DESIGNATION_WITH_ID);

  const [designations, setDesignations] = useState<any[]>([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedDesignation, setSelectedDesignation] = useState<any | null>(
    null
  );

  useEffect(() => {
    if (data?.cons_designation) {
      setDesignations(data.cons_designation);
    }
  }, [data]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading designations</p>;
  const handleEdit = (designation: any) => {
    setSelectedDesignation(designation);
    setOpenPopup(true);
  };

  const handleDelete = async (id: string) => {
    try {
      const { data } = await deleteDesignation({
        variables: { id },
      });

      if (data?.delete_cons_designation?.affected_rows > 0) {
        toast.success("Designation deleted successfully!");
        client.refetchQueries({ include: "active" });
      } else {
        toast.error("Failed to delete designation");
      }
    } catch (error) {
      console.error("Error deleting designation:", error);
      toast.error("Error deleting designation");
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Designation</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {designations.map((designation) => (
              <TableRow key={designation.id}>
                <TableCell>{designation.designation}</TableCell>
                <TableCell>
                  <div className="flex gap-3">
                    <Button onClick={() => handleEdit(designation)}>
                      <FaEdit size={18} />
                    </Button>
                    <Button onClick={() => handleDelete(designation.id)}>
                      <FaTrash size={18} />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {openPopup && selectedDesignation && (
        <SideDrawer
          component={
            <EditManageDesignation
              selectedDesignation={selectedDesignation}
              setSidePopup={setOpenPopup}
            />
          }
          sidePopup={openPopup}
          setSidePopup={setOpenPopup}
        />
      )}
    </>
  );
};

export default ShowManageDesignation;
