import { gql } from "@apollo/client";

export const GET_SOCIAL_LINKS = gql`
  query GetSocialLinks($brdId : uuid!) {
    cons_social_links(where: { brd_id: { _eq: $brdId} }) {
      link
      name
      id
    }
  }
`;
