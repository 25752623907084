import React from "react";
import { TextField, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation, useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import { UPDATE_DESIGNATION_WITH_ID } from "~/graphql/cons_designation/Mutation";

const EditManageDesignation = ({ selectedDesignation, setSidePopup }: any) => {
  const { register, handleSubmit } = useForm();
  const [updateDesignation] = useMutation(UPDATE_DESIGNATION_WITH_ID);
  const client = useApolloClient();

  const onSubmit = async (formData: any) => {
    if (!selectedDesignation?.id) {
      toast.error("Invalid designation data: ID is missing");
      return;
    }

    try {
      const res = await updateDesignation({
        variables: {
          id: selectedDesignation.id,
          designation: formData.title,
        },
      });

      if (res?.data?.update_cons_designation?.affected_rows > 0) {
        toast.success("Designation updated successfully!");
        client.refetchQueries({ include: "all" });
        setSidePopup(false);
      } else {
        toast.error("Update failed.");
      }
    } catch (error) {
      console.error("Error updating designation:", error);
      toast.error("Failed to update designation.");
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Edit Designation
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Designation"
          variant="outlined"
          required
          fullWidth
          {...register("title")}
          defaultValue={selectedDesignation?.designation}
          style={{ marginBottom: "16px" }}
        />

        <Button type="submit" variant="contained" color="primary" fullWidth>
          Update
        </Button>
      </form>
    </div>
  );
};

export default EditManageDesignation;
