import React from 'react'
import ShowPrograms from './component/ShowPrograms'
import SidePopup from '~/components/common/SidePopup'
import Addprograms from './component/AddPrograms'
import { useParams } from 'react-router-dom'

function Programs() {
    const {department_id , brd_id} = useParams()
  return (
    <>
      <ShowPrograms departmentId = {department_id}  brdId ={brd_id} />
      <SidePopup   component ={<Addprograms departmentId = {department_id}   />}   />
    </>
  )
}

export default Programs
