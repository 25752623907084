import { useState } from "react";
import { useMutation } from "@apollo/client";
import { Button, TextField } from "@mui/material";
import { nhost } from "~/lib/nhost";
import { UPDATED_CONS_ABOUT_INFO } from "~/graphql/cons_about_info/Mutation";
// import { GET_CONS_ABOUT_INFO } from "~/graphql/cons_about_info/Query";
import { toast } from "react-toastify";
import { GET_CONSULTANCY_ABOUT_INFO } from "~/graphql/cons_about_info/Query";

function EditConsAboutHeading({ setSidePopup, editData }: any) {
  const [heading, setHeading] = useState(editData?.heading || "");
  const [subHeading, setSubHeading] = useState(editData?.sub_heading || "");
  const [description, setDescription] = useState(editData?.description || "");
  const [imageId, setImageId] = useState(editData?.image_id || null);
  const [imageUrl, setImageUrl] = useState(
    editData?.image_id ? nhost.storage.getPublicUrl({ fileId: editData.image_id }) : ""
  );
  const [loading, setLoading] = useState(false);

  const [updateAboutInfo] = useMutation(UPDATED_CONS_ABOUT_INFO, {
    refetchQueries: [{ query: GET_CONSULTANCY_ABOUT_INFO }], // Refetch the updated data
  });

  const handleImageUpload = async (file: File) => {
    if (!file) return;
    setLoading(true);
    try {
      const { fileMetadata } = await nhost.storage.upload({ file });
      if (!fileMetadata) throw new Error("File metadata is null");
      const uploadedUrl = nhost.storage.getPublicUrl({ fileId: fileMetadata.id });
      setImageId(fileMetadata.id);
      setImageUrl(uploadedUrl);
      toast.success("Image uploaded successfully!");
    } catch (error) {
      toast.error("Image upload failed.");
    } finally {
      setLoading(false);
    }
  };

  const handleImageRemove = async () => {
    if (imageUrl && imageId) {
      try {
        await nhost.storage.delete({ fileId: imageId });
        setImageUrl("");
        setImageId(null);
        toast.success("Image removed successfully!");
      } catch (error) {
        toast.error("Failed to remove image.");
      }
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      await updateAboutInfo({
        variables: {
          id: editData.id,
          data: {
            heading,
            sub_heading: subHeading,
            description,
            image_id: imageId,
          },
        },
      });
      toast.success("Information updated successfully!");
      setSidePopup(false);
    } catch (error) {
      toast.error("Update failed.");
      console.error("Update error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Edit Consultancy About Info
      </h1>
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <TextField label="Heading" variant="outlined" fullWidth value={heading} onChange={(e) => setHeading(e.target.value)} />
        <TextField label="Sub Heading" variant="outlined" fullWidth value={subHeading} onChange={(e) => setSubHeading(e.target.value)} />
        <TextField label="Description" variant="outlined" fullWidth multiline rows={3} value={description} onChange={(e) => setDescription(e.target.value)} />
        {imageUrl && (
          <div className="relative">
            <button type="button" className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1" onClick={handleImageRemove}>
              ✕
            </button>
            <img src={imageUrl} alt="Uploaded" className="w-[300px] rounded-lg" />
          </div>
        )}
        <Button variant="outlined" component="label" className="dark:text-dark-primary w-[250px]">
          Upload Thumbnail
          <input type="file" hidden onChange={(e) => e.target.files?.[0] && handleImageUpload(e.target.files[0])} disabled={loading} />
        </Button>
        <Button type="submit" variant="contained" color="primary" disabled={loading}>
          {loading ? "Updating..." : "Update"}
        </Button>
      </form>
    </div>
  );
}

export default EditConsAboutHeading;
