import { URLs } from "~/config/enums";

export const GetCustomUrl = (host: any) => {
  if (
    host.split(":")[1] ||
    host.includes("dev.gogency.com") ||
    host.includes("development")
  ) {
    return URLs.THP_DOMAIN;
  } else {
    return host.replace(/^www\./, "");
  }
};
