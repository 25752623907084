import { gql } from "@apollo/client";

export const GET_BRD_TESTIMONIAL = gql`
  query getTestimonial($brdId: uuid!) {
    brd_testimonials(where: { brd_id: { _eq: $brdId } }) {
      id
      name
      designation
      brd_id
      feedback
      rating
    }
  }
`;
