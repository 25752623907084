import SidePopup from "~/components/common/SidePopup";
import AddPageHeadings from "./components/AddPageHeadings";
import ShowPageHeadings from "./components/ShowPageHeadings";
import { GET_BRD_PAGES_HEADINGS } from "~/graphql/brd_pages_headings/Query";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import travelHouseStore from "~/store/travelHouse/travelHouseStore";

const PageHeadings = () => {
  const { brd_id, pageType } = useParams();

  const { travelHouse }: any = travelHouseStore();
  console.log("brands:", travelHouse);
  const { data } = useQuery(GET_BRD_PAGES_HEADINGS, {
    variables: { brd_id, pageType },
  });

  // const SectionTypes = [
  //   { name: "Testimonials Section", value: "testimonial_section" },
  //   { name: "Tips Section", value: "tips_section" },
  //   { name: "Services Section", value: "services_section" },
  //   { name: "FAQs Section", value: "faqs_section" },
  //   { name: "Case Studies Section", value: "caseStudies_section" },
  //   { name: "Our Partners", value: "our_partners" },
  // ];
  const SectionTypes =
    travelHouse?.active_brand === "8091f67a-589b-4ac3-93f0-658e8e0ce967"
      ? [
          { name: "About", value: "about_section" },
          { name: "Top University", value: "top_university_section" },
          { name: "CTA", value: "cta_section" },
          {
            name: "Info with Bullet Points",
            value: "info_bullet_points_section",
          },
          { name: "Visa Approval", value: "visa_approval_section" },
          { name: "Testimonials Section", value: "testimonial_section" },
        ]
      : [
          { name: "Testimonials Section", value: "testimonial_section" },
          { name: "Tips Section", value: "tips_section" },
          { name: "Services Section", value: "services_section" },
          { name: "FAQs Section", value: "faqs_section" },
          { name: "Case Studies Section", value: "caseStudies_section" },
          { name: "Our Partners", value: "our_partners" },
        ];

  const filteredSectionTypes = (() => {
    if (pageType === "home-page") {
      return SectionTypes.filter(
        (section) =>
          section.value !== "tips_section" && section.value !== "our_partners"
      );
    } else if (pageType === "case-study") {
      return SectionTypes.filter(
        (section) => section.value === "testimonial_section"
      );
    }
    return SectionTypes;
  })();

  return (
    <>
      <ShowPageHeadings
        data={data}
        SectionTypes={filteredSectionTypes}
        pageHeadings={data?.brd_pages_headings}
      />
      <SidePopup
        component={
          <AddPageHeadings
            pageHeadings={data?.brd_pages_headings}
            SectionTypes={filteredSectionTypes}
          />
        }
      />
    </>
  );
};

export default PageHeadings;
