import { gql } from "@apollo/client";

export const UPDATE_BRD_TESTIMONIAL = gql`
  mutation UpdateTestimonial(
    $id: uuid!
    $testimonialData: brd_testimonials_set_input!
  ) {
    update_brd_testimonials(where: { id: { _eq: $id } }, _set: $testimonialData) {
      affected_rows
    }
  }
`;

export const INSERT_TESTIMONIALS = gql`
  mutation insertTestimonial($data: brd_testimonials_insert_input!) {
    insert_brd_testimonials(objects: [$data]) {
      affected_rows
    }
  }
`;


export const DELETE_TESTIMONIAL= gql`
  mutation DeleteTestimonial($id: uuid!) {
    delete_brd_testimonials(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

