import { gql } from "@apollo/client";

export const GET_CONS_DESIGNATION_WITH_BRD_ID = gql`
  query GetConsDesignation($brdId: uuid!) {
    cons_designation(where: { brd_id: { _eq: $brdId } }) {
      brd_id
      id
      designation
    }
  }
`;
