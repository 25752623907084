import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import InputField from "~/components/FormInputs/InputField";
import PhoneBox from "~/components/FormInputs/PhoneBox";
import {
  ADD_SUB_DOMAIN_VERCEL_CLOUDFLARE,
  INSERT_BRAND,
} from "./graphql/Mutation";
import { toast } from "react-toastify";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { useNavigate } from "react-router-dom";
import CountryBox from "~/components/FormInputs/CountryBox";
import { GET_DEF_EMAIL_TEMP } from "./graphql/Query";
import { useUserId } from "@nhost/react";

const InsertBrandGeneralInfo = () => {
  const { travelHouse }: any = travelHouseStore();
  const { loading, error, data } = useQuery(GET_DEF_EMAIL_TEMP);
  const navigate = useNavigate();
  const [insertBrand] = useMutation(INSERT_BRAND);
  const [subDomain, setSubDomain] = useState("");
  const client = useApolloClient();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm();

  const onSubmit = async (inpData: any) => {
    let emailTemplate: any = [];
    data.def_emails_templates.map((temp: any) => {
      const tempObj = {
        template_id: temp.id,
        subject: temp.subject,
        content: temp.content,
      };
      emailTemplate.push(tempObj);
    });
    const res = await insertBrand({
      variables: {
        data: {
          name: inpData?.brand_name,
          thp_id: travelHouse.id,
          subdomain: inpData?.sub_domain + ".gogency.com",
          brd_emails_templates: {
            data: emailTemplate,
          },
          brd_contact_details: {
            data: {
              email: inpData?.email,
              phone: inpData?.phone,
              city: inpData?.city,
              country: inpData?.country?.label,
              whatsapp: inpData?.whatsapp,
              address1: inpData?.address1,
            },
          },
          brd_pages: {
            data: [
              {
                name: "Home",
                page_sub_heading: "The Excitement of Adventure",
                slug: "/",
                title: "Gogency",
              },
              {
                name: "Privacy Policy",
                page_sub_heading: "Privacy Policy settings",
                slug: "privacy-policy",
                title: "Title",
              },
              {
                name: "Flights",
                page_sub_heading: "Flights settings",
                slug: "flights",
                title: "Title",
              },
              // {
              //   name: "Terms of Use",
              //   page_sub_heading: "Terms",
              //   slug: "terms-and-conditions",
              //   title: "Title",
              // },
            ],
          },
        },
      },
    });

    const responseADDDomain = await client.query({
      query: ADD_SUB_DOMAIN_VERCEL_CLOUDFLARE,
      variables: {
        subdomain: inpData?.sub_domain + ".gogency.com",
      },
    });
    if (res?.data?.insert_brd_list_one?.id) {
      toast.success("brand updated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      navigate(`/admin/brands/${res?.data?.insert_brd_list_one?.id}/edit`);
      await client.refetchQueries({
        include: "all",
      });
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error in the query {error.message}</div>;

  return (
    <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2  sm:p-6 ">
      <h3 className="mb-4 text-xl font-semibold ">Insert Brand Information</h3>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <div className="grid grid-cols-6 gap-6 items-end">
          <div className="col-span-full">
            {/* <InputField
              register={register}
              errors={errors}
              fieldName="brand_name"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
              label="Brand Name"
              placeHolder="Brand Name"
            /> */}
            <label className="text-[14px] text-[#393939]" htmlFor="">
              Brand Name
            </label>
            <Controller
              name="brand_name"
              control={control}
              render={({ field }) => (
                <input
                  {...field}
                  type="text"
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Brand Name"
                  onChange={(e: any) => {
                    const name = e.target.value;

                    // Transform the company name: spaces to hyphens, all lowercase
                    const transformedDomain = name
                      .replace(/\s+/g, "-")
                      .toLowerCase();

                    setValue("brand_name", name);
                    setSubDomain(transformedDomain); // use the transformed name for domain
                    setValue("sub_domain", transformedDomain);
                    field.onChange(e.target.value);
                  }}
                />
              )}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            {/* <InputField
              register={register}
              errors={errors}
              fieldName="sub_domain"
              inputClasses="shadow-sm bg-gray-50 mr-[10px] border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
              label="Sub Domain"
              placeHolder="Sub Domain"
            /> */}
            <label className="text-[14px] text-[#393939]" htmlFor="">
              Sub Domain
            </label>
            <Controller
              name="sub_domain"
              control={control}
              render={({ field }: { field: any }) => (
                <input
                  {...field}
                  type="text"
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Sub Domain"
                  value={subDomain}
                  onChange={(e) => {
                    const transformedValue = e.target.value
                      .toLowerCase()
                      .replace(/\s+/g, "-") // Replace spaces with hyphens
                      .replace(/[^a-z0-9-]/g, ""); // Remove characters other than letters, numbers, and hyphens

                    field.onChange(transformedValue);
                    setSubDomain(transformedValue);
                  }}
                />
              )}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <input
              type="text"
              value=".gogency.com"
              readOnly
              className="shadow-sm bg-gray-50 p-[8px] border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <CountryBox
              control={control}
              setValue={setValue}
              errors={errors}
              fieldName="country"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
              label="Country"
              placeholder="Country"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <InputField
              register={register}
              errors={errors}
              fieldName="city"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
              label="City"
              placeHolder="City"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <InputField
              register={register}
              errors={errors}
              fieldName="address1"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
              label="Address"
              placeHolder="Address"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <InputField
              register={register}
              errors={errors}
              fieldName="email"
              type="email"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
              label="Email"
              placeHolder="Email"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <PhoneBox
              errors={errors}
              setValue={setValue}
              control={control}
              label="Phone"
              fieldName="phone"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <PhoneBox
              errors={errors}
              setValue={setValue}
              control={control}
              label="Whatsapp"
              fieldName="whatsapp"
              inputClasses="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
            />
          </div>
          <div className="col-span-6 sm:col-full">
            <button
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              type="submit"
            >
              Save Brand Settings
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default InsertBrandGeneralInfo;
