import React, { useState } from "react";
import { useUserDefaultRole } from "@nhost/react";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { filterStatus } from "~/modules/InquiriesModule/data/filters";
import DateRangePickerCustom from "./components/DateRangePicker";
import InquiryFlagFilter from "./components/InquiryFlagFilter";
import Consultantfilter from "./components/Consultantfilter";
import { useForm } from "react-hook-form";
import CustomerFilter from "./components/CustomerFilter";
import Brandfilter from "./components/Brandfilter";
import SourceFilter from "./components/SourceFilter";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  GET_INQUIRIES_DESTINATION,
  GET_INQUIRIES_ORIGIN,
  GET_INQUIRIES_SOURCES,
  GET_INQUIRIES_SOURCE_AD_ID,
  GET_INQUIRIES_SOURCE_KEYWORD,
  GET_INQUIRIES_SOURCE_PLACE,
  GET_INQUIRIES_SOURCE_POSITION,
} from "./components/graphql/Query";

import FilterListIcon from "@mui/icons-material/FilterList";

export default function InquiryFilterButtons({
  selectedFlags,
  setSelectedFlags,
  selectedStatus,
  setSelectedStatus,
  selectedConsultant,
  setSelectedConsultant,
  setSelectedCustomer,
  dates,
  setDates,
  setSearch,
  search,
  allBrand,
  setSource,
  source,
  setLocations,
  locations,
  travelHouse,
  compType,
  setTicketStatus,
}: any) {
  const role = useUserDefaultRole();

  const [inquiryId, setInquiryId] = useState<any>("");

  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Save Passsenger function
  const onSubmit = async (data: any) => {
    setSearch(inquiryId);
  };

  const handleStatusChange = (event: any, newValue: any) => {
    compType === "bookings"
      ? setTicketStatus(newValue)
      : setSelectedStatus(newValue);
  };

  const userRole = useUserDefaultRole();

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <div className="flex items-center gap-3">
          <FilterListIcon /> <p>Filters</p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="w-full bg-secondary dark:bg-dark-secondary">
          <div className="grid xl:grid-cols-4 md:grid-cols-2 items-center w-full ">
            <div className="relative pl-2 pt-[8px]">
              <DateRangePickerCustom dates={dates} setDates={setDates} />
            </div>
            <form
              className="flex pl-2 space-x-1 w-full overflow-x-auto pt-[8px] mt-[8px]"
              onSubmit={handleSubmit(onSubmit)}
            >
              <TextField
                id="outlined-basic"
                label="Inquiry Id"
                variant="outlined"
                name="inquiry_id"
                value={search}
                className="w-full"
                onChange={(e) => setSearch(e.target.value)}
              />
            </form>
            <div className="flex pl-2 space-x-1 w-full overflow-x-auto pt-[8px]  mt-[8px]">
              {compType === "bookings" ? (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={[
                    { name: "Awaiting Vendor", value: "awaiting_vendor" },
                    { name: "Issued", value: "issued" },
                    { name: "Pending Approval", value: "pending_approval" },
                  ]}
                  onChange={handleStatusChange}
                  value={selectedStatus}
                  getOptionLabel={(option: any) => option.name}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Ticket Status" />
                  )}
                />
              ) : (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={filterStatus}
                  onChange={handleStatusChange}
                  value={selectedStatus}
                  getOptionLabel={(option: any) => option.title}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Status" />
                  )}
                />
              )}
            </div>
            <div className="flex pl-2 space-x-1 w-full overflow-x-auto w-[150px] pt-[8px]  mt-[8px]">
              <InquiryFlagFilter
                selectedFlags={selectedFlags}
                setSelectedFlags={setSelectedFlags}
                travelHouse={travelHouse}
              />
            </div>
            {/* {userRole === "admin" && ( */}
            {(compType !== "bookings" || userRole === "admin") && (
              <div className="flex pl-2 space-x-1 w-full overflow-x-auto w-[150px] pt-[8px]  mt-[8px]">
                <Consultantfilter
                  setSelectedConsultant={setSelectedConsultant}
                  selectedConsultant={selectedConsultant}
                />
              </div>
            )}
            {/* // )} */}

            <div className="flex pl-2 space-x-1 w-full overflow-x-auto w-[150px] pt-[8px]  mt-[8px]">
              <CustomerFilter
                setSelectedCustomer={setSelectedCustomer}
                travelHouse={travelHouse}
              />
            </div>
            <div className="flex pl-2 space-x-1 w-full overflow-x-auto w-[150px] pt-[8px]  mt-[8px]">
              <SourceFilter
                allBrand={allBrand}
                setFilters={setSource}
                filters={source}
                target="source"
                label={"Source"}
                query={GET_INQUIRIES_SOURCES}
              />
            </div>
            <div className="flex pl-2 space-x-1 w-full overflow-x-auto w-[150px] pt-[8px]  mt-[8px]">
              <SourceFilter
                allBrand={allBrand}
                setFilters={setSource}
                filters={source}
                target="source_place"
                label={"Place"}
                query={GET_INQUIRIES_SOURCE_PLACE}
              />
            </div>

            <div className="flex pl-2 space-x-1 w-full overflow-x-auto w-[150px] pt-[8px]  mt-[8px]">
              <SourceFilter
                allBrand={allBrand}
                setFilters={setSource}
                filters={source}
                target="source_position"
                label={"Position"}
                query={GET_INQUIRIES_SOURCE_POSITION}
              />
            </div>

            <div className="flex pl-2 xl:col-span-2 space-x-1 w-full overflow-x-auto w-[150px] pt-[8px]  mt-[8px]">
              <SourceFilter
                allBrand={allBrand}
                setFilters={setSource}
                filters={source}
                target="source_keyword"
                label={"Keyword"}
                query={GET_INQUIRIES_SOURCE_KEYWORD}
              />
            </div>
            <div className="flex pl-2 space-x-1 w-full overflow-x-auto w-[150px] pt-[8px]  mt-[8px]">
              <SourceFilter
                allBrand={allBrand}
                setFilters={setSource}
                filters={source}
                target="source_add_id"
                label={"Ad Id"}
                query={GET_INQUIRIES_SOURCE_AD_ID}
              />
            </div>
            <div className="flex pl-2 xl:col-span-2 space-x-1 w-full overflow-x-auto w-[150px] pt-[8px]  mt-[8px]">
              <SourceFilter
                allBrand={allBrand}
                setFilters={setLocations}
                filters={locations}
                target="from"
                label={"Origin"}
                query={GET_INQUIRIES_ORIGIN}
              />
            </div>
            <div className="flex pl-2 xl:col-span-2 space-x-1 w-full overflow-x-auto w-[150px] pt-[8px]  mt-[8px]">
              <SourceFilter
                allBrand={allBrand}
                setFilters={setLocations}
                filters={locations}
                target="to"
                label={"Destination"}
                query={GET_INQUIRIES_DESTINATION}
              />
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
