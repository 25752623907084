import { gql } from "@apollo/client";

export const GET_CONTACT_INFO = gql`
  query getContactInfo($brdId: uuid) {
    brd_contact_detail(where: { brd_id: { _eq: $brdId } }) {
      id
      email
      country
      city
      address1
      office_open
      office_close
      phone
      whatsapp
    }
  }
`;
