import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { GET_INQUIRIES } from "./graphql/Query";
import TicketRequestRow from "./components/TicketRequestRow";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";

export default function TicketRequest() {
  const { travelHouse }: any = travelHouseStore();
  const [filter, setFilter] = useState<any>("");
  const [showIssued, setShowIssued] = useState(false);

  const condition = {
    inq_list: {
      thp_id: { _eq: travelHouse.id },
    },
    ...(filter !== "" && { ibe: { _eq: `${filter}` } }),
    ...(showIssued
      ? { status: { _eq: "issued" } }
      : { status: { _neq: "issued" } }),
  };

  const { loading, data, error } = useQuery(GET_INQUIRIES, {
    variables: {
      condition,
    },
  });

  // if (loading) return <div>Loading...</div>;
  if (error) return <div>Error in the query {error.message}</div>;

  return (
    <>
      <h1 className="text-primary dark:text-dark-primary uppercase text-[18px] font-bold border-b-2 divide-gray-200  dark:divide-gray-700 mb-2">
        Tickets ({data?.inq_ticket.length})
      </h1>
      <main className="w-full pt-2">
        <section className="container">
          <div className="flex flex-col">
            <div className="grid grid-cols-4 mt-2 mb-2 w-full">
              <div className="flex justify-end col-span-4">
                <div className="flex items-center gap-3">
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => setShowIssued(!showIssued)}
                        checked={showIssued}
                      />
                    }
                    className="min-w-[140px]"
                    label="Show Issued"
                  />
                  <TextField
                    fullWidth
                    label="Search IBE"
                    onChange={(e) => {
                      setFilter(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="overflow-x-auto">
              <div className="inline-block min-w-full py-2 align-middle">
                <div className="overflow-hidden shadow md:rounded-lg">
                  <table className="min-w-full text-center shadow divide-y divide-gray-200  dark:divide-gray-700">
                    <thead className="bg-secondary dark:bg-dark-secondary ">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-primary dark:text-dark-primary "
                        >
                          <div className="flex items-center gap-x-3">
                            <button className="flex items-center gap-x-2">
                              <span>Sr No.</span>
                            </button>
                          </div>
                        </th>

                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-primary dark:text-dark-primary "
                        >
                          Sale Price
                        </th>

                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-primary dark:text-dark-primary "
                        >
                          Received
                        </th>

                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-primary dark:text-dark-primary "
                        >
                          Cancelation Charges
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-primary dark:text-dark-primary"
                        >
                          Fair Expiry
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-righttext-primary dark:text-dark-primary"
                        >
                          PNR
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-primary dark:text-dark-primary "
                        >
                          IBE
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-primary dark:text-dark-primary "
                        >
                          Inquiry No
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-primary dark:text-dark-primary "
                        >
                          Cost
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-primary dark:text-dark-primary "
                        >
                          Attol
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-primary dark:text-dark-primary "
                        >
                          Miscellaneous
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-primary dark:text-dark-primary"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-primary dark:text-dark-primary "
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-secondary dark:bg-dark-secondary divide-y divide-gray-200  dark:divide-gray-700 ">
                      {data?.inq_ticket.length > 0 ? (
                        data?.inq_ticket.map((ticket: any, i: number) => (
                          <TicketRequestRow
                            key={i}
                            ticket={ticket}
                            index={i}
                            filter={filter}
                          />
                        ))
                      ) : (
                        <tr className="">
                          <td
                            colSpan={12}
                            className="px-4 py-4 text-sm font-medium text-primary dark:text-dark-primary  whitespace-nowrap"
                          >
                            <div className="inline-flex items-center gap-x-3">
                              <span>No Record Found</span>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
