import { useParams } from "react-router-dom";
import ShowUniversity from "./component/ShowUniversity";
import SidePopup from "~/components/common/SidePopup";
import AddUniversity from "./component/AddUniversity";

const University = () => {
  const { brd_id, country_id } = useParams();

  return (
    <>
      <ShowUniversity brdId={brd_id} countryId={country_id} />
      <SidePopup
        component={<AddUniversity brdId={brd_id} countryId={country_id} />}
      />
    </>
  );
};

export default University;
