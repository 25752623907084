import { gql } from "@apollo/client";

export const GET_INTAKES = gql`
  query GetIntakes($programId: uuid!) {
    cons_intake(where: { program_id: { _eq: $programId } }) {
      id
      name
      scholarship
      total_fee
    }
  }
`;

