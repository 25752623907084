import { Box, Button, TextField, InputAdornment } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { ALLOWED_IMAGE_FORMATS } from "~/config/constants";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { getAiData } from "~/utils/getAiData";
import { typingEffect } from "~/utils/typingEffect";
import { useNavigate, useParams } from "react-router-dom";

const ConsHeroHeading = ({
  page,
  addHeroSecDetails,
  register,
  errors,
  handleSubmit,
  setLogoFile,
  control,
  setValue,
}: any) => {
  const navigate = useNavigate();
  const { brd_id } = useParams();

  // Set default values when page data is available
  useEffect(() => {
    if (page) {
      setValue("page_heading", page.page_heading || "");
      setValue("page_heading_color", page.page_heading_color || "");
      setValue("bg_color", page.bg_color || "");
      setValue("page_sub_heading", page.page_sub_heading || "");
      setValue("page_sub_heading_2", page.page_sub_heading_2 || "");
      setValue("footer_anchor_descriptive_text", page.footer_anchor_descriptive_text || "");
    }
  }, [page, setValue]);

  const handleLogoUpload = async (event: any) => {
    const file = event.target.files[0];

    if (!ALLOWED_IMAGE_FORMATS.includes(file.type.split("/").at(1).toLowerCase())) {
      toast.error("Invalid File format");
    } else {
      setLogoFile({ file });
      setValue("featured_image", file); // Update form state for featured_image
    }
  };

  const onSubmit = async (inpData: any) => {
    addHeroSecDetails(inpData);
  };

  const handleAIClick = async (field: string, prompt: string) => {
    const res = await getAiData(prompt);
    await typingEffect((text: string) => setValue(field, text), res);
  };

  return (
    <>
      <div className="bg-secondary dark:bg-dark-secondary rounded-lg shadow p-5 mb-5 text-primary dark:text-dark-primary">
        <div className="w-full flex justify-between">
          <div className="flex items-center">
            <h1 className="text-xl">Hero Section</h1>
          </div>
        </div>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-10">
            <TextField
              error={!!errors.page_heading}
              {...register("page_heading", { required: "Main Heading is required" })}
              label="Main Heading"
              type="text"
              fullWidth
              InputLabelProps={{ shrink: true }} 
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={() =>
                        handleAIClick(
                          "page_heading",
                          `Write an seo optimized page heading For "${page?.brd_list?.def_brand_type?.name}" type website 3 to 5 Words: unique and without quotes`
                        )
                      }
                    />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              error={!!errors.page_heading_color}
              {...register("page_heading_color")}
              label="Main Heading Color"
              type="text"
              fullWidth
              InputLabelProps={{ shrink: true }} 
            />
            <TextField
              error={!!errors.bg_color}
              {...register("bg_color")}
              label="Background Color"
              type="text"
              helperText="Add opacity adjusted color"
              fullWidth
              InputLabelProps={{ shrink: true }} 
            />
            <TextField
              error={!!errors.page_sub_heading}
              {...register("page_sub_heading", { required: "Sub Heading is required" })}
              label="Sub Heading"
              type="text"
              InputLabelProps={{ shrink: true }} 
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={() =>
                        handleAIClick(
                          "page_sub_heading",
                          `Write an seo optimized Page Sub Heading For ${
                            page?.brd_list?.def_brand_type?.name
                          } 6 to 8 Words: unique and without quotes.`
                        )
                      }
                    />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              error={!!errors.page_sub_heading_2}
              {...register("page_sub_heading_2", { required: "Description is required" })}
              label="Description"
              type="text"
              InputLabelProps={{ shrink: true }} 
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={() =>
                        handleAIClick(
                          "page_sub_heading_2",
                          `Write an seo optimized Page short description For ${
                            page?.brd_list?.def_brand_type?.name
                          } 1 to 2 lines: unique and without quotes.`
                        )
                      }
                    />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              error={!!errors.footer_anchor_descriptive_text}
              {...register("footer_anchor_descriptive_text", {
                required: "Footer Anchor Text is required",
              })}
              label="Footer Anchor Descriptive Text"
              type="text"
              InputLabelProps={{ shrink: true }} 
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AutoFixHighIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={() =>
                        handleAIClick(
                          "footer_anchor_descriptive_text",
                          `Write an seo optimized Page footer anchor descriptive text For ${
                            page?.brd_list?.def_brand_type?.name
                          } 8 to 10 words: unique and without quotes.`
                        )
                      }
                    />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="mt-5 w-[25%]">
            <label className="mb-2">Featured Image</label>
            <TextField
              sx={{ width: "100%" }}
              type="file"
              InputLabelProps={{ shrink: true }} 
              inputProps={{
                style: {
                  paddingTop: "7px",
                  paddingBottom: "27px",
                  paddingLeft: "23px",
                },
              }}
              variant="outlined"
              onChange={handleLogoUpload}
              helperText="SVG, PNG, JPG or GIF (MAX. 800x400px)"
            />
          </div>
          <Button type="submit" variant="contained" sx={{ marginTop: "25px" }}>
            Save Hero Section
          </Button>
        </Box>
      </div>
    </>
  );
};

export default ConsHeroHeading;