import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import { GET_SOCIAL_LINKS } from "~/graphql/cons_social_links/Query";
import { toast } from "react-toastify";
import SideDrawer from "~/components/common/sideDrawer";
import EditConsSocialLinks from "../EditConsSocialLinks";
import { DELETE_SOCIAL_LINKS } from "~/graphql/cons_social_links/Mutation";
// import EditSocialLink from "../EditSocialLink"; // Component for editing social links

const ShowConsSocialLinks = ({ brdId }: any) => {
  const [fetchSocialLinks, { data, loading, error }] =
    useLazyQuery(GET_SOCIAL_LINKS);
  const [deleteSocialLink] = useMutation(DELETE_SOCIAL_LINKS);
  const [socialLinks, setSocialLinks] = useState<any[]>([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedLink, setSelectedLink] = useState<any | null>(null);
  const client = useApolloClient();

  useEffect(() => {
    if (brdId) {
      fetchSocialLinks({ variables: { brdId } });
    }
  }, [brdId, fetchSocialLinks]);

  useEffect(() => {
    if (data?.cons_social_links) {
      setSocialLinks(data.cons_social_links);
    }
  }, [data]);

  const handleEdit = (link: any) => {
    setSelectedLink(link);
    setOpenPopup(true);
  };

  const handleDelete = async (id: any) => {
    try {
      const response = await deleteSocialLink({
        variables: { id },
      });

      if (response.data.delete_cons_social_links.affected_rows > 0) {
        client.refetchQueries({ include: "all" });
        toast.success("Deleted successfully!");
      } else {
        toast.warning("No rows deleted.");
      }
    } catch (error) {
      toast.error("Failed to delete social link!");
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading social links</p>;

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Link</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {socialLinks.map((link) => (
              <TableRow key={link.id}>
                <TableCell>{link.name || "NA"}</TableCell>
                <TableCell>
                  <a href={link.link} target="_blank" rel="noopener noreferrer">
                    {link.link || "NA"}
                  </a>
                </TableCell>
                <TableCell>
                  <div className="flex gap-3">
                    <Button onClick={() => handleEdit(link)}>
                      <FaEdit size={18} />
                    </Button>
                    <Button onClick={() => handleDelete(link.id)}>
                      <FaTrash size={18} />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {openPopup && selectedLink && (
        <SideDrawer
          component={
            <EditConsSocialLinks
              selectedLink={selectedLink}
              setSidePopup={setOpenPopup}
            />
          }
          sidePopup={openPopup}
          setSidePopup={setOpenPopup}
        />
      )}
    </>
  );
};

export default ShowConsSocialLinks
