import { gql } from "@apollo/client";

// export const UPDATE_UNIVERSITY = gql`
//   mutation UpdateUniversity(
//     $id: uuid!
//     $universityData: cons_university_set_input!
//     $mediaData: cons_media_set_input!
//   ) {
//     update_cons_university(where: { id: { _eq: $id } }, _set: $universityData) {
//       affected_rows
//       returning {
//         id
//         name
//         featured
//         cons_media {
//           id
//           image_id
//           type
//           featured
//         }
//       }
//     }
//     update_cons_media(
//       where: { university_id: { _eq: $id } }
//       _set: $mediaData
//     ) {
//       affected_rows
//     }
//   }
// `;
export const UPDATE_UNIVERSITY = gql`
  mutation UpdateUniversity(
    $id: uuid!
    $universityData: cons_university_set_input!
  ) {
    update_cons_university(where: { id: { _eq: $id } }, _set: $universityData) {
      affected_rows
    }
  }
`;

export const INSERT_UNIVERSITY = gql`
  mutation MyMutation($data: cons_university_insert_input!) {
    insert_cons_university(objects: [$data]) {
      affected_rows
    }
  }
`;

export const DELETE_UNIVERSITY = gql`
  mutation DeleteUniversity($id: uuid!) {
    delete_cons_university(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
