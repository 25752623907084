import Layout from "~/components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import Departments from "~/modules/BrandModule/segments/Countries/components/Departments";

const DepartmentsPage = () => {
  return (
    <div>
      <Layout>
        <BrandModule>
          <Departments />
        </BrandModule>
      </Layout>
    </div>
  );
};

export default DepartmentsPage;
