import Layout from "~/components/common/Layout";
import BrandModule from "~/modules/BrandModule";

import ConsultancyGeneralPages from "~/modules/BrandModule/segments/ConsultancyGeneralPages";

export default function ConsultancyBrandPages() {
  return (
    <Layout>
      <BrandModule>
        <ConsultancyGeneralPages />
      </BrandModule>
    </Layout>
  );
}
