import { gql } from "@apollo/client";

export const GET_CONSULTANCY_ABOUT_INFO = gql`
  query GetConsultancyAboutInfo {
    cons_about_info {
      id
      heading
      sub_heading
      image_id
      description
    }
  }
`;
