import React, { useState } from "react";
import ConsApprovalSteps from "./components/ConsApprovalSteps";
import { useParams } from "react-router-dom";
import ConsHeadings from "./components/ConsHeadings";
import ConsAboutHeroHeading from "./components/ConsAboutHero";
import ConsContactInfo from "./components/ConsConatctInfo";
import ConsTeamMembers from "./components/ConsTeamMembers";
import ConsFAQS from "./components/ConsFAQS";
import ConsTestimonials from "./components/ConsTestimonials";
import ConsHeroHeading from "./components/ConsHeroHeading";
import { useForm } from "react-hook-form";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { useFileUpload } from "@nhost/react";
import { INSERT_PAGE, UPDATE_HEADINGS } from "../ManagePages/components/EditHeroSection/graphql/Mutation";
import { toast } from "react-toastify";
import { GET_PAGE_DATA } from "../ManagePages/graphql/Query";
import ConsSocialLinks from "./components/ConsSocialLinks";

const ConsultancyGeneralPages = () => {
  const { brd_id } = useParams();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue, // Add setValue to set default values
  } = useForm();

  const { loading, data, error } = useQuery(GET_PAGE_DATA, {
    variables: {
      id: brd_id,
      slug: "/",
    },
  });

  const [updateHeadings] = useMutation(UPDATE_HEADINGS);
  const [insertPage] = useMutation(INSERT_PAGE);
  const { upload: uploadLogo, progress: logoUploadProgress } = useFileUpload();
  const client = useApolloClient();

  const [logoFile, setLogoFile] = useState<any | null>(null);
  const page = data?.brd_pages?.at(0);

  // Set default values when page data is available
  React.useEffect(() => {
    if (page) {
      setValue("page_heading", page.page_heading || "");
      setValue("page_heading_color", page.page_heading_color || "");
      setValue("bg_color", page.bg_color || "");
      setValue("page_sub_heading", page.page_sub_heading || "");
      setValue("page_sub_heading_2", page.page_sub_heading_2 || "");
      setValue("footer_anchor_descriptive_text", page.footer_anchor_descriptive_text || "");
    }
  }, [page, setValue]);

  const addHeroSecDetails = async (inpData: any) => {
    if (logoFile) {
      const logoUploadRes = await uploadLogo(logoFile);
      inpData["featured_image"] = logoUploadRes?.id || null;
    }

    if (page?.id) {
      const res = await updateHeadings({
        variables: {
          id: { id: page.id },
          data: inpData,
        },
      });
      if (res?.data?.update_brd_pages_by_pk?.id) {
        toast.success("Headings updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    } else {
      const res = await insertPage({
        variables: {
          data: {
            brd_id: brd_id,
            slug: "/",
            name: "/"?.replaceAll("-", " "),
            title: "/"?.replaceAll("-", " "),
            ...inpData,
          },
        },
      });
      if (res?.data?.insert_brd_pages_one?.id) {
        toast.success("Headings updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    }
  };

  return (
    <>
      <ConsHeroHeading
        page={page}
        addHeroSecDetails={addHeroSecDetails}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        setLogoFile={setLogoFile}
        control={control} // Pass control to ConsHeroHeading
        setValue={setValue} // Pass setValue to ConsHeroHeading
      />
      <ConsApprovalSteps brdId={brd_id} />
      <ConsHeadings brdId={brd_id} />
      <ConsAboutHeroHeading brdId={brd_id} />
      <ConsContactInfo brdId={brd_id} />
      <ConsTeamMembers brdId={brd_id} />
      <ConsFAQS brdId={brd_id} />
      <ConsTestimonials brdId={brd_id} />
      <ConsSocialLinks  brdId={brd_id} />
    </>
  );
};

export default ConsultancyGeneralPages;