import React from "react";

const InvoiceCostDetail = ({ invoice }: any) => {
  const stayPassengers =
    invoice?.invoice_suggestions?.filter(
      (suggestion: any) => suggestion.type === "stay"
    ) || [];
  const carPassengers =
    invoice?.invoice_suggestions?.filter(
      (suggestion: any) => suggestion.type === "car"
    ) || [];
  const trainPassengers =
    invoice?.invoice_suggestions?.filter(
      (suggestion: any) => suggestion.type === "train"
    ) || [];
  const flightSuggestion =
    invoice?.invoice_suggestions?.filter(
      (suggestion: any) => suggestion.type === "flights"
    ) || [];
  const visaSuggestion =
    invoice?.invoice_suggestions?.filter(
      (suggestion: any) => suggestion.type === "visa"
    ) || [];

  console.log("stayPassengers", stayPassengers);
  return (
    <>
      {stayPassengers.length > 0 && (
        <>
          {stayPassengers.map((item: any, index: number) => (
            <div
              key={index}
              className="self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap"
            >
              {item?.stay_inq_suggestion?.stay_inq_suggestion_costs?.map(
                (cost: any, costIndex: number) => (
                  <div
                    key={costIndex}
                    className="flex flex-row justify-between w-full"
                  >
                    <div className="relative leading-[14px]">
                      {cost?.stay_inq_room?.booked_by}
                    </div>
                    <div className="relative leading-[14px] font-medium text-right inline-block min-w-[50px] whitespace-nowrap">
                      £{cost?.sale_price}
                    </div>
                  </div>
                )
              )}
            </div>
          ))}
        </>
      )}
      {carPassengers.length > 0 && (
        <>
          {carPassengers.map((item: any, index: number) => (
            <div
              key={index}
              className="self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap"
            >
              {/* Ensure that car_inq_suggestion_costs is correctly accessed */}
              {item?.car_inq_suggestion?.car_inq_suggestion_costs?.map(
                (cost: any, costIndex: number) => (
                  <div
                    key={costIndex}
                    className="flex flex-row justify-between w-full"
                  >
                    <div className="relative leading-[14px]">
                      {cost?.car_inq_passenger?.first_name || "N/A"}{" "}
                      {cost?.car_inq_passenger?.last_name || "N/A"}
                    </div>
                    <div className="relative leading-[14px] font-medium text-right inline-block min-w-[50px] whitespace-nowrap">
                      £{cost?.sale_price}
                    </div>
                  </div>
                )
              )}
            </div>
          ))}
        </>
      )}

      {trainPassengers.length > 0 && (
        <>
          {trainPassengers.map((item: any, index: number) => (
            <div
              key={index}
              className="self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap"
            >
              {/* Ensure that car_inq_suggestion_costs is correctly accessed */}
              {item?.train_inq_suggestion?.train_inq_suggestion_costs?.map(
                (cost: any, costIndex: number) => (
                  <div
                    key={costIndex}
                    className="flex flex-row justify-between w-full"
                  >
                    <div className="relative leading-[14px]">
                      {cost?.train_inq_passenger?.first_name || "N/A"}{" "}
                      {cost?.train_inq_passenger?.last_name || "N/A"}
                    </div>
                    <div className="relative leading-[14px] font-medium text-right inline-block min-w-[50px] whitespace-nowrap">
                      £{cost?.sale_price}
                    </div>
                  </div>
                )
              )}
            </div>
          ))}
        </>
      )}

      {/* {flightSuggestion.length > 0 && (
        <>
          {flightSuggestion[0]?.inq_suggestion?.inq_suggestion_costs?.map(
            (cost: any, index: number) => (
              <div
                key={index}
                className="self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap"
              >
                <div className="flex flex-row justify-between w-full">
                  <div className="relative leading-[14px] min-w-[50px]">
                    {cost?.inq_passenger?.first_name || "N/A"}
                  </div>
                  <div className="relative leading-[14px]  min-w-[60px]">
                    {cost?.inq_passenger?.type}
                  </div>
                  <div className="relative leading-[14px]  min-w-[50px]">
                    Flight
                  </div>
                  <div className="relative leading-[14px] min-w-[50px] font-medium text-right inline-block  whitespace-nowrap">
                    £{cost?.sale_price}
                  </div>
                </div>
              </div>
            )
          )}
        </>
      )} */}
      {flightSuggestion.length > 0 && (
        <>
          {flightSuggestion[0]?.inq_suggestion?.inq_suggestion_costs?.map(
            (cost: any, index: number) => (
              <div
                key={index}
                className="self-stretch grid grid-cols-4 items-start justify-between gap-[20px] mq450:flex-wrap"
              >
                <div className="relative leading-[14px] min-w-[50px]">
                  {cost?.inq_passenger?.first_name || "N/A"}{" "}
                  {cost?.inq_passenger?.last_name || "N/A"}
                </div>
                <div className="relative leading-[14px] min-w-[60px]">
                  {cost?.inq_passenger?.type}
                </div>
                <div className="relative leading-[14px] min-w-[50px]">
                  Flight
                </div>
                <div className="relative leading-[14px] min-w-[50px] font-medium text-right inline-block whitespace-nowrap">
                  £{cost?.sale_price}
                </div>
              </div>
            )
          )}
        </>
      )}
      {visaSuggestion.length > 0 && (
        <>
          {visaSuggestion.map((item: any, index: number) => (
            <div key={index} className="self-stretch flex flex-col">
              {item?.visa_inq_suggestion?.visa_inq_suggestion_costs?.map(
                (cost: any, costIndex: number) => (
                  <div
                    key={costIndex}
                    className="flex flex-row justify-between w-full pb-2"
                  >
                    <div className="relative leading-[14px] min-w-[50px]">
                      {cost?.inq_passenger?.first_name || "N/A"}
                    </div>
                    <div className="relative leading-[14px] min-w-[60px]">
                      {cost?.inq_passenger?.type || "N/A"}
                    </div>
                    <div className="relative leading-[14px] min-w-[50px]">
                      Visa
                    </div>
                    <div className="relative leading-[14px] min-w-[50px] font-medium text-right inline-block whitespace-nowrap">
                      £{cost?.sale_price || "N/A"}
                    </div>
                  </div>
                )
              )}
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default InvoiceCostDetail;
