import React from "react";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { INSERT_DESIGNATION_WITH_BRD_ID } from "~/graphql/cons_designation/Mutation";
import { useApolloClient, useMutation } from "@apollo/client";
import { toast } from "react-toastify";

const AddManageDesignation = ({ brdId, setSidePopup }: any) => {
  const client = useApolloClient();
  const [insertDesignation] = useMutation(INSERT_DESIGNATION_WITH_BRD_ID);

  const { register, handleSubmit, reset } = useForm();

  const onSubmit = async (data: Record<string, any>) => {
    const res = await insertDesignation({
      variables: { designation: data.designation, brd_id: brdId },
    });

    if (res?.data?.insert_cons_designation?.affected_rows > 0) {
      client.refetchQueries({ include: "all" });
      toast.success("Designation added successfully!");
      setSidePopup(false);
      reset();
    } else {
      toast.error("Failed to add designation!");
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Add Designation
      </h1>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <TextField
          label="Designation"
          fullWidth
          required
          {...register("designation")}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Submit
        </Button>
      </form>
    </div>
  );
};

export default AddManageDesignation;
