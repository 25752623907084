import React, { useState } from "react";
import SideDrawer from "~/components/common/sideDrawer";
import AddFiles from "./components/AddFiles";
import ShowFiles from "./components/ShowFiles";

const InqFiles = (inqId: any) => {
  const [popup, setPopup] = useState(false);
  return (
    <>
      <div className="flex items-center dark:text-primary my-[30px]">
        <hr className="w-[2%] dark:border-[#E7E3FC61]" />
        <span className="text-[14px] text-secondary dark:text-dark-secondary min-w-fit text-center px-2">
          Files
        </span>

        <hr className="w-full dark:border-[#E7E3FC61]" />
        <div className="min-w-fit pl-2">
          <div className="flex justify-end gap-3 w-full">
            <button
              onClick={() => setPopup(true)}
              className="block border border-basic font-medium rounded-full px-3 py-1 text-white hover:text-basic dark:hover:bg-dark-primary cursor-pointer bg-basic hover:bg-white"
            >
              Add Files
            </button>
          </div>
        </div>
      </div>
      <ShowFiles inqId={inqId?.inqId} />
      {popup && (
        <SideDrawer
          setSidePopup={setPopup}
          sidePopup={popup}
          component={
            <AddFiles
              setSidePopup={() => setPopup(false)}
              inqId={inqId?.inqId}
            />
          }
        />
      )}
    </>
  );
};

export default InqFiles;
