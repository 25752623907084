import { gql } from "@apollo/client";
export const GET_INQ_FILES = gql`
  query GetInqFiles($inqID: uuid!) {
    inq_files(where: { inq_id: { _eq: $inqID } }) {
      id
      file_type
      file_id
      file_info {
        name
      }
    }
  }
`;
