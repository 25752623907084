import SidePopup from "~/components/common/SidePopup";

import { useParams } from "react-router-dom";
import ShowCountries from "./components/ShowCountries";
import AddCountries from "./components/AddCountries";

const Countries = () => {
  const { brd_id } = useParams();
  return (
    <>
      <ShowCountries brdId={brd_id} />
      <SidePopup component={<AddCountries brdId={brd_id} />} />
    </>
  );
};

export default Countries;
