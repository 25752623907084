import React from 'react'
import ShowIntakes from './component/ShowIntakes'
import SidePopup from '~/components/common/SidePopup'
import AddIntakes from './component/AddIntakes'
import { useParams } from 'react-router-dom'

const Intakes = () => {
    const {program_id  , brd_id} = useParams()
  return (
    <>
      <ShowIntakes  programId ={program_id}  brdId={brd_id} />
      <SidePopup   component ={ <AddIntakes programId ={program_id}   />   } />
    </>
  )
}

export default Intakes
