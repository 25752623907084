import { Button } from '@mui/material';
import React, { useState } from 'react'
import SideDrawer from '~/components/common/sideDrawer';
import AddConsHeadings from '../ConsHeadings/AddConsHeadings';
import ShowConsHeadings from '../ConsHeadings/ShowConsHeadings';
import ConsAddAboutHeroHeading from './component/AddAboutHeroheading';
import ShowConsAboutHeroHeadind from './component/ShowConsAboutHeroHeading';

function ConsAboutHeroHeading({brdId} : any) {
    const [openPopup, setOpenPopup] = useState(false);
    return (
      <div className="bg-secondary dark:bg-dark-secondary rounded-lg  p-5 mb-5">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center justify-center h-full ">
            <h1 className="text-xl flex items-center justify-center">
              Consultancy About Heading
            </h1>
          </div>
  
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenPopup(true)}
          >
            Add Consultancy About Heading
          </Button>
        </div>
        <ShowConsAboutHeroHeadind brdId={brdId} />
  
        <SideDrawer
          component={
            <ConsAddAboutHeroHeading brdId={brdId} setSidePopup={setOpenPopup} />
          }
          sidePopup={openPopup}
          setSidePopup={setOpenPopup}
        />
      </div>
    );
  };


export default ConsAboutHeroHeading
