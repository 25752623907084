import { useMutation } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import { DELETE_ACC_TRANS } from "../../../../graphql/Mutation";
import { toast } from "react-toastify";
import moment from "moment";
import ConfirmPopup from "~/components/common/ConfirmPopup";
import React, { useState } from "react";

export default function TransactionRow({
  transaction,
  compType,
  role,
  refetchTrans,
  prevBalance,
  allTransactions,
  index,
}: any) {
  const [deleteAccTrans] = useMutation(DELETE_ACC_TRANS);
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  function getPrevTransactions(transactions: any, index: any) {
    if (index < 1) {
      return [];
    }
    return transactions.slice(0, index);
  }

  const getBalance = () => {
    const allDebits = getPrevTransactions(allTransactions, index)
      ?.filter((transaction: any) => transaction.type === "debit")
      ?.reduce((sum: any, transaction: any) => sum + transaction.amount, 0);

    const allCredits = getPrevTransactions(allTransactions, index)
      ?.filter((transaction: any) => transaction.type === "credit")
      ?.reduce((sum: any, transaction: any) => sum - transaction.amount, 0);

    const currBalance = prevBalance + (allDebits - allCredits);

    return currBalance + transaction?.amount;
  };

  const handleDelete = async () => {
    const res = await deleteAccTrans({
      variables: {
        id: transaction?.id,
      },
    });

    refetchTrans();

    if (res?.data?.delete_acc_transactions?.returning?.[0]?.id) {
      toast.success("Transaction Deleted Successfully");
    }
    setDeleteConfirm(false);
  };

  return (
    <tr className="">
      <td className="px-4 py-4 text-sm font-medium text-gray-700 dark:text-dark-primary">
        <div className="inline-flex gap-x-3">
          <span>
            #{transaction?.transactions_seq || transaction?.transactions_no}
          </span>
        </div>
      </td>
      {compType !== "inquirSection" && (
        <td className="px-4 py-4 text-sm text-gray-500 dark:text-dark-primary">
          {transaction?.brd_list?.name}
        </td>
      )}
      <td className="px-4 py-4 text-sm text-gray-500 dark:text-dark-primary">
        {moment(transaction?.date).zone(0).format("YYYY-MM-DD")}
      </td>
      <td className="px-4 py-4 text-sm text-gray-500 capitalize dark:text-dark-primary">
        {transaction?.acc_list?.type} {transaction?.def_acc_list?.type}
      </td>
      <td className="px-4 py-4 text-sm text-gray-500 dark:text-dark-primary">
        {transaction?.acc_list?.name} {transaction?.def_acc_list?.name}
      </td>
      {compType !== "inquirSection" && (
        <td className="px-4 py-4 text-sm text-gray-500 dark:text-dark-primary">
          {transaction?.inq_list?.inquiry_no ? (
            <Link
              to={`../inquiry/${transaction.inq_list.inquiry_no}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {transaction.inq_list.inquiry_no}
            </Link>
          ) : (
            <i className="text-[#ff9b00] text-[12px] dark:text-dark-primary">
              Not linked
            </i>
          )}
        </td>
      )}

      <td className="px-4 py-4 text-sm text-gray-500 dark:text-dark-primary">
        {transaction?.users?.defaultRole === "vendor" ? (
          transaction?.inq_ticket?.ibe
        ) : transaction?.linked_inq_transaction?.ref_no ? (
          transaction?.linked_inq_transaction?.ref_no
        ) : (
          <i className="text-[#ff9b00] text-[14px] dark:text-dark-primary">-</i>
        )}
      </td>
      <td className="px-4 py-4 text-sm text-gray-500 dark:text-dark-primary">
        {transaction?.acc_payment_method?.name || (
          <i className="text-[#ff9b00] text-[14px] dark:text-dark-primary">-</i>
        )}
      </td>
      {compType !== "inquirSection" && (
        <td className="px-4 py-4 text-sm text-gray-500 dark:text-dark-primary">
          {transaction?.users?.displayName || (
            <i className="text-[#ff9b00] text-[14px] dark:text-dark-primary">
              -
            </i>
          )}
        </td>
      )}

      <td className="px-4 py-4 text-sm text-gray-500 dark:text-dark-primary">
        {transaction?.note || (
          <i className="text-[#ff9b00] text-[14px] dark:text-dark-primary">-</i>
        )}
      </td>

      <td className="px-4 py-4 text-sm text-gray-500 dark:text-dark-primary">
        {transaction?.detailed_desc || (
          <i className="text-[#ff9b00] text-[14px] dark:text-dark-primary">-</i>
        )}
      </td>
      <td className="px-4 py-4 text-sm dark:text-dark-primary">
        {transaction?.type === "debit" ? (
          transaction?.amount
        ) : (
          <i className="text-[#ff9b00] text-[14px] dark:text-dark-primary">-</i>
        )}
      </td>
      <td className="px-4 py-4 text-sm dark:text-dark-primary">
        {transaction?.type === "credit" ? (
          -transaction?.amount
        ) : (
          <i className="text-[#ff9b00] text-[14px] dark:text-dark-primary">-</i>
        )}
      </td>
      <td className="px-4 py-4 text-sm dark:text-dark-primary">
        {getBalance()}
      </td>
      {compType !== "inquirSection" && (
        <td className="px-4 py-4 text-sm text-gray-500  dark:text-dark-primary">
          {transaction?.inq_list?.picked_user?.thp_users_profile?.[0]
            ?.pseudo_name || (
            <i className="text-[#ff9b00] text-[14px] dark:text-dark-primary">
              -
            </i>
          )}
        </td>
      )}
      {compType === "inquirSection" && role === "admin" && (
        <td className="px-4 py-4 text-sm text-gray-500 dark:text-dark-primary">
          <DeleteIcon
            onClick={() => setDeleteConfirm(true)}
            sx={{ color: "#9254F8", cursor: "pointer" }}
          />
        </td>
      )}
      {deleteConfirm && (
        <ConfirmPopup
          closeModal={() => setDeleteConfirm(false)}
          confirm={handleDelete}
          headingText="Do you want to delete this transaction?"
        />
      )}
    </tr>
  );
}
