import Countries from "~/modules/BrandModule/segments/Countries";
import Layout from "../../components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import ConsulatncyPage from "~/modules/BrandModule/segments/ConsultancyPost";
import AddConsultancyBlog from "~/modules/BrandModule/segments/AddConsultacyBlog";

export default function AddConsultancyBlogsPage() {
  return (
    <div>
      <Layout>
        <BrandModule>
         <AddConsultancyBlog />
        </BrandModule>
      </Layout>
    </div>
  );
}
