import React from 'react'
import ShowProgramRequirements from './component/ShowProgramRequirements';
import SidePopup from '~/components/common/SidePopup';
import AddProgramRequirements from './component/AddProgramRequirements';
import { useParams } from 'react-router-dom';

const ProgramsRequirements = () => {
    const{program_id , brd_id } = useParams()
  return (
    <>
      <ShowProgramRequirements programId ={program_id}  brdId  ={brd_id} />
      <SidePopup component ={<AddProgramRequirements  programId ={program_id} />} />
    </>
  )
}

export default ProgramsRequirements;
