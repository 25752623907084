import { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import SideDrawer from "~/components/common/sideDrawer";
import EditConsTestimonials from "../EditConsTestimonials";
import { GET_BRD_TESTIMONIAL } from "~/graphql/cons_testimonials/Query";
import { DELETE_TESTIMONIAL } from "~/graphql/cons_testimonials/Mutation";

const ShowConsTestimonials = ({ brdId }: any) => {
  const [fetchTestimonials, { data, loading, error }] = useLazyQuery(GET_BRD_TESTIMONIAL);
  const [deleteTestimonial] = useMutation(DELETE_TESTIMONIAL);
  const [testimonials, setTestimonials] = useState<any[]>([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedTestimonial, setSelectedTestimonial] = useState<any | null>(null);
  const client = useApolloClient();

  useEffect(() => {
    if (brdId) {
      fetchTestimonials({ variables: { brdId } });
    }
  }, [brdId, fetchTestimonials]);

  useEffect(() => {
    if (data?.brd_testimonials) {
      setTestimonials(data.brd_testimonials);
    }
  }, [data]);

  const handleEdit = (testimonial: any) => {
    setSelectedTestimonial(testimonial);
    setOpenPopup(true);
  };

  const handleDelete = async (id: any) => {
    try {
      const { data } = await deleteTestimonial({ variables: { id } });
      if (data?.delete_brd_testimonials?.affected_rows > 0) {
        toast.success("Testimonial deleted successfully!");
        client.refetchQueries({ include: "active" });
        setTestimonials((prev) => prev.filter((testimonial) => testimonial.id !== id));
      } else {
        toast.warning("No testimonial was deleted.");
      }
    } catch (error) {
      toast.error("Failed to delete testimonial!");
      console.error("Error deleting testimonial:", error);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading testimonials</p>;

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Designation</TableCell>
              <TableCell>Feedback</TableCell>
              <TableCell>Rating</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {testimonials.map((testimonial) => (
              <TableRow key={testimonial.id}>
                <TableCell>{testimonial.name || "NA"}</TableCell>
                <TableCell>{testimonial.designation || "NA"}</TableCell>
                <TableCell>{testimonial.feedback || "NA"}</TableCell>
                <TableCell>{testimonial.rating || "NA"}</TableCell>
                <TableCell>
                  <div className="flex gap-3">
                    <Button onClick={() => handleEdit(testimonial)}>
                      <FaEdit size={18} />
                    </Button>
                    <Button onClick={() => handleDelete(testimonial.id)}>
                      <FaTrash size={18} />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {openPopup && selectedTestimonial && (
        <SideDrawer
          component={
            <EditConsTestimonials
              selectedTestimonial={selectedTestimonial}
              setSidePopup={setOpenPopup}
              brdId={brdId}
            />
          }
          sidePopup={openPopup}
          setSidePopup={setOpenPopup}
        />
      )}
    </>
  );
};

export default ShowConsTestimonials;
