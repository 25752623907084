import { gql } from "@apollo/client";

export const INSERT_BLOG = gql`
  mutation MyMutation($data: [cons_blogs_insert_input!]!) {
    insert_cons_blogs(objects: $data) {
      affected_rows
    }
  }
`;






export const UPDATED_BLOGS = gql`
  mutation UpdateBlogs($id: uuid!, $data: cons_blogs_set_input!) {
    update_cons_blogs(where: { id: { _eq: $id } }, _set: $data) {
      affected_rows
    }
  }
`;


export const DELETE_BLOGS= gql`
  mutation DeleteBlogs($id: uuid!) {
    delete_cons_blogs(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
