import { gql } from "@apollo/client";

export const INSERT_CONS_FAQS = gql`
  mutation InsertConsFaqs($data: [cons_faqs_insert_input!]!) {
    insert_cons_faqs(objects: $data) {
      affected_rows
    }
  }
`;




export const UPDATE_CONS_FAQS = gql`
  mutation UpdateDepartment($id: uuid!, $data: cons_faqs_set_input!) {
    update_cons_faqs(
      where: { id: { _eq: $id } }
      _set: $data
    ) {
      affected_rows
    }
  }
`;













export const DELETE_FAQS = gql`
  mutation DeleteFAQS($id: uuid!) {
    delete_cons_faqs(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;