import { gql } from "@apollo/client";

export const INSERT_DESIGNATION_WITH_BRD_ID = gql`
  mutation InsertDesignation($designation: String!, $brd_id: uuid!) {
    insert_cons_designation(
      objects: { designation: $designation, brd_id: $brd_id }
    ) {
      affected_rows
    }
  }
`;
export const DELETE_CONS_DESIGNATION_WITH_ID = gql`
  mutation DeleteConsDesignation($id: uuid!) {
    delete_cons_designation(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_DESIGNATION_WITH_ID = gql`
  mutation UpdateDesignation($id: uuid!, $designation: String!) {
    update_cons_designation(
      where: { id: { _eq: $id } }
      _set: { designation: $designation }
    ) {
      affected_rows
    }
  }
`;
