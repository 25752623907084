import React from "react";

import { useQuery } from "@apollo/client";
import brandsStore from "~/store/brandsStore/brandsStore";
import { Autocomplete, TextField } from "@mui/material";
import { GET_CONS_LEADS_WITH_BRD_ID } from "~/graphql/cons_leads/Query";

const ConsultancyFilter = ({ setSelectedName }: any) => {
  const { brands }: any = brandsStore();

  const brdIds = brands.map((item: any) => item.id);

  const { data } = useQuery(GET_CONS_LEADS_WITH_BRD_ID, {
    variables: { brdIds },
  });

  const handleChange = (event: any, newValue: any) => {
    setSelectedName(newValue);
  };

  return (
    <div>
      <Autocomplete
        options={data?.cons_leads || []}
        getOptionLabel={(option: any) => option?.name}
        fullWidth
        onChange={handleChange}
        renderInput={(params) => (
          <TextField {...params} label="Name" variant="outlined" fullWidth />
        )}
      />
    </div>
  );
};

export default ConsultancyFilter;
