import { gql } from "@apollo/client";

export const INSERT_INTAKES = gql`
  mutation InsertIntakes($data: [cons_intake_insert_input!]!) {
    insert_cons_intake(objects: $data) {
      affected_rows
    }
  }
`;

export const UPDATE_INTAKES = gql`
  mutation UpdateIntakes($id: uuid!, $data: cons_intake_set_input!) {
    update_cons_intake(
      where: { id: { _eq: $id } }
      _set: $data
    ) {
      affected_rows
    }
  }
`;


export const DELETE_INTAKE = gql`
  mutation DeleteIntake($id: uuid!) {
    delete_cons_intake(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;





