import { gql } from "@apollo/client";

export const GET_PROGRAM_REQUIREMENTS = gql`
  query GetProgramRequirements($programId: uuid!) {
    cons_programs_requirements(where: { cons_programs_id: { _eq: $programId } }) {
      id
      course_name
      gap_in_months
    }
  }
`;
