import { TextField, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation, useApolloClient } from "@apollo/client";
import { UPDATE_COUNTRY } from "~/graphql/cons_country/Mutation";
import { toast } from "react-toastify";

const EditCountries = ({ selectedCountry, setSidePopup }: any) => {
  const { register, handleSubmit } = useForm();
  const [updateCountry] = useMutation(UPDATE_COUNTRY);
  const client = useApolloClient();

  console.log("Selected Country:", selectedCountry); // Log the selectedCountry object

  const onSubmit = async (formData: any) => {
    if (!selectedCountry?.id) {
      toast.error("Invalid country data: id is missing");
      return;
    }

    try {
      const res = await updateCountry({
        variables: {
          id: selectedCountry.id,
          name: formData.name,
          slug: formData.slug,
        },
      });

      if (res?.data?.update_cons_country?.affected_rows > 0) {
        toast.success("Country updated successfully!");
        client.refetchQueries({
          include: "all",
        });
        setSidePopup(false);
      } else {
        toast.error("Update failed.");
      }
    } catch (error) {
      console.error("Error updating country:", error);
      toast.error("Failed to update country.");
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Edit Country
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Country Name"
          variant="outlined"
          required
          fullWidth
          {...register("name")}
          defaultValue={selectedCountry?.name}
          style={{ marginBottom: "16px" }}
        />
        <TextField
          label="Country Slug"
          variant="outlined"
          required
          fullWidth
          {...register("slug")}
          defaultValue={selectedCountry?.slug}
          style={{ marginBottom: "16px" }}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Update
        </Button>
      </form>
    </div>
  );
};

export default EditCountries;