import React, { useState } from "react";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useMutation, useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import { INSERT_CAMPUSES } from "~/graphql/cons_campuses/Mutation";
import { nhost } from "~/lib/nhost";

const AddCampuses = ({ universityId, setSidePopup }: any) => {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      name: "",
      slug: "",
      description: "",
    },
  });

  const [imageId, setImageId] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState("");
  const [insertCampuses, { loading, error }] = useMutation(INSERT_CAMPUSES);
  const client = useApolloClient();

  const onSubmit = async (data: Record<string, any>) => {
    if (!imageId) {
      toast.error("Please select an image!");
      return;
    }

    const campusData : any = {
      ...data,
      university_id: universityId,
      cons_media: {
        data: [],
      },
    };

    if (imageId) {
      campusData.cons_media.data.push({
        image_id: imageId,
        type: "image",
        featured: true,
      });
    }

    if (campusData.cons_media.data.length === 0) {
      delete campusData.cons_media;
    }

    try {
      await insertCampuses({
        variables: { data: campusData },
      });

      await client.refetchQueries({ include: "all" });
      toast.success("Campus added successfully!");
      reset();
      setImageId(null);
      setImageUrl("");
      setSidePopup(false);
    } catch (err) {
      console.error("Error inserting campus:", err);
      toast.error("Failed to add campus");
    }
  };

  const handleImageRemove = async () => {
    if (imageUrl && imageId) {
      await nhost.storage.delete({ fileId: imageId });
      setImageUrl("");
      setImageId(null);
    }
  };

  const uploadImage = async (file: File) => {
    const response = await nhost.storage.upload({ file });
    if (response.error) throw new Error(response.error.message);
    setImageId(response.fileMetadata.id);
    setImageUrl(
      `${process.env.REACT_APP_NHOST_STORAGE_URL}${response.fileMetadata.id}`
    );
  };


  return (
    <div className="max-w-md w-full p-6 bg-gray-100 dark:bg-gray-800 rounded-lg shadow-md">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Add Campus
      </h1>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <TextField label="Name" fullWidth required {...register("name")} />
        <TextField label="Slug" fullWidth required {...register("slug")} />
        <TextField
          label="Description"
          fullWidth
          multiline
          rows={3}
          required
          {...register("description")}
        />
        <Button
          variant="outlined"
          component="label"
          className="dark:text-dark-primary w-[250px]"
        >
          Upload Thumbnail
          <input
            type="file"
            hidden
            onChange={async (e) => {
              const file = e.target.files?.[0];
              if (file) await uploadImage(file);
            }}
          />
        </Button>

        {imageUrl && (
          <div className="relative">
            <button
              type="button"
              className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1"
              onClick={handleImageRemove}
            >
              ✕
            </button>
            <img src={imageUrl} alt="Uploaded" className="w-[300px]" />
          </div>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
        >
          {loading ? "Submitting..." : "Submit"}
        </Button>
        {error && <p className="text-red-500 text-center">{error.message}</p>}
      </form>
    </div>
  );
};

export default AddCampuses;