import { gql } from "@apollo/client";

export const INSERT_CONS_POINT_OF_CONTACT = gql`
  mutation InsertConsPointOfContact(
    $email: String!
    $name: String!
    $phone_no: String!
    $university_id: uuid!
    $designation_id: uuid!
  ) {
    insert_cons_point_of_contact(
      objects: {
        email: $email
        name: $name
        phone_no: $phone_no
        university_id: $university_id
        designation_id: $designation_id
      }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_UNIVERSITY_CONTACT_WITH_ID = gql`
  mutation UpdateUniversityContact(
    $id: uuid!
    $email: String!
    $designation_id: uuid!
    $phone_no: String!
    $name: String!
    $university_id: uuid!
  ) {
    update_cons_point_of_contact(
      where: { id: { _eq: $id } }
      _set: {
        email: $email
        designation_id: $designation_id
        phone_no: $phone_no
        name: $name
        university_id: $university_id
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_UNIVERSITY_CONTACT_WITH_ID = gql`
  mutation DeleteUniversityContactById($id: uuid!) {
    delete_cons_point_of_contact(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
