import { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { GET_CONS_TEAM } from "~/graphql/cons_team/Query";
import { DELETE_CONS_TEAM_MEMBER } from "~/graphql/cons_team/Mutation";
import SideDrawer from "~/components/common/sideDrawer";
import EditConsTeamMembers from "../EditConsTeamMembers";

const ShowConsTeamMembers = ({ brdId }: any) => {
  const [fetchTeamMembers, { data, loading, error }] = useLazyQuery(GET_CONS_TEAM);
  const [deleteTeamMember] = useMutation(DELETE_CONS_TEAM_MEMBER);
  const [teamMembers, setTeamMembers] = useState<any[]>([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedMember, setSelectedMember] = useState<any | null>(null);
  const client = useApolloClient();

  // Fetch team members when brdId changes
  useEffect(() => {
    if (brdId) {
      fetchTeamMembers({ variables: { brdId } });
    }
  }, [brdId, fetchTeamMembers]);

  // Update state when data is fetched
  useEffect(() => {
    if (data?.cons_team) {
      setTeamMembers(data.cons_team);
    }
  }, [data]);

  // Handle edit button click
  const handleEdit = (member: any) => {
    setSelectedMember(member);
    setOpenPopup(true);
  };

  // Handle delete functionality
  const handleDelete = async (id: any) => {
    try {
      const { data } = await deleteTeamMember({ variables: { id } });
      if (data?.delete_cons_team?.affected_rows > 0) {
        toast.success("Team member deleted successfully!");
        client.refetchQueries({ include: "active" });
        setTeamMembers((prev) => prev.filter((member) => member.id !== id));
      } else {
        toast.warning("No member was deleted.");
      }
    } catch (error) {
      toast.error("Failed to delete team member!");
      console.error("Error deleting team member:", error);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading team members</p>;

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Designation</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teamMembers.map((member) => (
              <TableRow key={member.id}>
                <TableCell>{member.name || "NA"}</TableCell>
                <TableCell>{member.designation || "NA"}</TableCell>
                <TableCell>
                  <div className="flex gap-3">
                    <Button onClick={() => handleEdit(member)}>
                      <FaEdit size={18} />
                    </Button>
                    <Button onClick={() => handleDelete(member.id)}>
                      <FaTrash size={18} />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {openPopup && selectedMember && (
        <SideDrawer
          component={
            <EditConsTeamMembers
              selectedMember={selectedMember}
              setSidePopup={setOpenPopup}
              brdId={brdId}
            />
          }
          sidePopup={openPopup}
          setSidePopup={setOpenPopup}
        />
      )}
    </>
  );
};

export default ShowConsTeamMembers;
