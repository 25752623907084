import { gql } from "@apollo/client";

export const GET_PROGRMS = gql`
  query GetPrograms($departmentId: uuid!) {
  cons_program(where: { department_id: { _eq: $departmentId } }) {
    id
    name
    slug
    min_marks_req
    enrollment_criteria
  }
}
`;
