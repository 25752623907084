import { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_CONS_COUNTRIES } from "~/graphql/cons_country/Query";
import { DELETE_COUNTRY } from "~/graphql/cons_country/Mutation";
import { toast } from "react-toastify";
import EditCountries from "../EditCountries";
import SideDrawer from "~/components/common/sideDrawer";
import { useNavigate } from "react-router-dom";

const ShowCountries = ({ brdId }: any) => {
  const [fetchCountries, { data, loading, error }] =
    useLazyQuery(GET_CONS_COUNTRIES);
  const [deleteCountry] = useMutation(DELETE_COUNTRY, {
    refetchQueries: [{ query: GET_CONS_COUNTRIES, variables: { brdId } }],
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [countries, setCountries] = useState<any[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<any | null>(null); // State for selected country
  const navigate = useNavigate();
  useEffect(() => {
    if (brdId) {
      fetchCountries({ variables: { brdId } });
    }
  }, [brdId, fetchCountries]);

  useEffect(() => {
    if (data?.cons_country) {
      setCountries(data.cons_country);
    }
  }, [data]);

  const handleEdit = (country: any) => {
    setSelectedCountry(country); // Set selected country
    setOpenPopup(true); // Open the drawer
  };

  const handleDelete = async (id: string) => {
    try {
      const { data } = await deleteCountry({ variables: { id } });

      if (data?.delete_cons_country?.affected_rows > 0) {
        setCountries((prev) => prev.filter((country) => country.id !== id));
        toast.success("Deleted successfully");
      } else {
        toast.error("Delete failed. No rows affected.");
      }
    } catch (err) {
      console.error("Error deleting country:", err);
      toast.error("Failed to delete country");
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading countries</p>;
  const handleViewCampuses = (country_Id: any) => {
    navigate(`/admin/brands/${brdId}/countries/${country_Id}/university`);
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Slug</TableCell>
              <TableCell>Actions</TableCell>
              <TableCell>University</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {countries.map((country) => (
              <TableRow key={country.slug}>
                <TableCell>{country.name}</TableCell>
                <TableCell>{country.slug}</TableCell>

                <TableCell>
                  <div className="flex gap-3">
                    <Button onClick={() => handleEdit(country)}>
                      <FaEdit size={18} />
                    </Button>
                    <Button onClick={() => handleDelete(country.id)}>
                      <FaTrash size={18} />
                    </Button>
                  </div>
                </TableCell>
                <TableCell>
                  <Button
                    color="success"
                    onClick={() => handleViewCampuses(country.id)}
                  >
                    View University
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {openPopup && selectedCountry && (
        <SideDrawer
          component={
            <EditCountries
              selectedCountry={selectedCountry} // Pass the selected country as prop
              setSidePopup={setOpenPopup}
            />
          }
          sidePopup={openPopup}
          setSidePopup={setOpenPopup}
        />
      )}
    </>
  );
};

export default ShowCountries;
