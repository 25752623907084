import React from "react";
import Layout from "../../components/common/Layout";
import BrandModule from "~/modules/BrandModule";

import ManageDesignation from "~/modules/BrandModule/segments/Countries/components/ManageDesignation";
export default function ManageDesignationPage() {
  return (
    <div>
      <Layout>
        <BrandModule>
          {/* <UpdateBrand /> */}
          <ManageDesignation />
        </BrandModule>
      </Layout>
    </div>
  );
}
