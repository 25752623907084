import { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useForm, Controller } from "react-hook-form";
import { Button, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { INSERT_TESTIMONIALS } from "~/graphql/cons_testimonials/Mutation";
import { GET_BRD_TESTIMONIAL } from "~/graphql/cons_testimonials/Query";

const AddConsTestimonials = ({ brdId, setSidePopup }: any) => {
  const { control, handleSubmit, reset, setValue } = useForm();
  const [loading, setLoading] = useState(false);

  // Mutation for inserting testimonial
  const [insertTestimonial] = useMutation(INSERT_TESTIMONIALS, {
    onCompleted: async () => {
      toast.success("Testimonial added successfully!");
      setValue("name", "");
      setValue("designation", "");
      setValue("rating", "");
      setValue("feedback", "");
      setSidePopup(false); // Close the side popup
      reset(); // Reset the form fields
    },
    onError: (error) => {
      toast.error(`Error: ${error.message}`);
    },
    // Optional: Refetch using Apollo's built-in refetchQueries
    refetchQueries: [
      {
        query: GET_BRD_TESTIMONIAL,
        variables: { brdId }, // Pass the brand ID as a variable/
      },
    ],
  });

  // Handle form submission
  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      await insertTestimonial({
        variables: {
          data: {
            brd_id: brdId, // Include the brand ID
            name: data.name,
            designation: data.designation,
            feedback: data.feedback,
            rating: parseFloat(data.rating), // Convert rating to a number
          },
        },
      });
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to add testimonial. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Add New Testimonial
      </h1>
      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        {/* Name Input */}
        <Controller
          name="name"
          control={control}
          rules={{ required: "Name is required" }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Name"
              variant="outlined"
              fullWidth
              error={!!error}
              helperText={error ? error.message : ""}
            />
          )}
        />

        {/* Designation Input */}
        <Controller
          name="designation"
          control={control}
          rules={{ required: "Designation is required" }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Designation"
              variant="outlined"
              fullWidth
              error={!!error}
              helperText={error ? error.message : ""}
            />
          )}
        />

        {/* Rating Input */}
        <Controller
          name="rating"
          control={control}
          rules={{ required: "Rating is required" }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Rating"
              variant="outlined"
              fullWidth
              type="number"
              inputProps={{ min: 0, max: 5, step: 0.1 }} // Assuming rating is between 0 and 5
              error={!!error}
              helperText={error ? error.message : ""}
            />
          )}
        />

        {/* Feedback Input */}
        <Controller
          name="feedback"
          control={control}
          rules={{ required: "Feedback is required" }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Feedback"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              error={!!error}
              helperText={error ? error.message : ""}
            />
          )}
        />

        {/* Submit Button */}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? "Adding..." : "Add Testimonial"}
        </Button>
      </form>
    </div>
  );
};

export default AddConsTestimonials;
