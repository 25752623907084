import React, { useEffect, useState } from "react";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { FaEdit, FaTrash } from "react-icons/fa";
import { GET_UNIVERSITY_CONTACTS_WITH_BRD_ID } from "~/graphql/cons_point_of_contact/query";

import SideDrawer from "~/components/common/sideDrawer";
import { DELETE_UNIVERSITY_CONTACT_WITH_ID } from "~/graphql/cons_point_of_contact/Mutation";
import { toast } from "react-toastify";
import EditPointOfContact from "../EditPointOfContact";

const ShowPointOfContact = ({ universityId, brdId }: any) => {
  const [deletePointOfContact] = useMutation(DELETE_UNIVERSITY_CONTACT_WITH_ID);
  const { data, loading, error } = useQuery(
    GET_UNIVERSITY_CONTACTS_WITH_BRD_ID,
    {
      variables: { universityId },
    }
  );

  const [contacts, setContacts] = useState<any[]>([]);
  const [openPopup, setOpenPopup] = useState(false);

  const [selectedPOC, setSelectedPOC] = useState<any>(null);
  const client = useApolloClient();

  useEffect(() => {
    if (data?.cons_point_of_contact) {
      setContacts(data.cons_point_of_contact);
    }
  }, [data]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading contacts</p>;

  const handleEdit = (contact: any) => {
    setSelectedPOC(contact);
    setOpenPopup(true);
  };

  const handleDelete = async (id: string) => {
    try {
      const { data } = await deletePointOfContact({ variables: { id } });

      if (data?.delete_cons_point_of_contact?.affected_rows > 0) {
        toast.success("Point of Contact deleted successfully!");
        client.refetchQueries({ include: "all" });
      } else {
        toast.warn("No record found to delete!");
      }
    } catch (error) {
      toast.error("Failed to delete Point of Contact!");
      console.error("Delete failed:", error);
    }
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone No</TableCell>
              <TableCell>Designation</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contacts.map((contact) => (
              <TableRow key={contact.email}>
                <TableCell>{contact.name}</TableCell>
                <TableCell>{contact.email}</TableCell>
                <TableCell>{contact.phone_no}</TableCell>
                <TableCell>
                  {contact.cons_designation?.designation || "N/A"}
                </TableCell>
                <TableCell>
                  <div className="flex gap-3">
                    <Button onClick={() => handleEdit(contact)}>
                      <FaEdit size={18} />
                    </Button>
                    <Button onClick={() => handleDelete(contact.id)}>
                      <FaTrash size={18} />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {openPopup && selectedPOC && (
        <SideDrawer
          component={
            <EditPointOfContact
              selectedPOC={selectedPOC}
              setSidePopup={setOpenPopup}
              brdId={brdId}
            />
          }
          sidePopup={openPopup}
          setSidePopup={setOpenPopup}
        />
      )}
    </>
  );
};

export default ShowPointOfContact;
