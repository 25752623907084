import React, { useState } from "react";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useMutation, useApolloClient } from "@apollo/client";

import { toast } from "react-toastify";
import { nhost } from "~/lib/nhost";
import { INSERT_UNIVERSITY } from "~/graphql/cons_university/Mutation";

const AddUniversity = ({ countryId, brdId, setSidePopup }: any) => {
  const { register, handleSubmit, setValue, watch, reset } = useForm({
    defaultValues: {
      name: "",
      address: "",
      contact_info: "",
      benefits: "",
      slug: "",
      description: "",
    },
  });

  const [featured, setFeatured] = useState(false);
  const [imageId, setImageId] = useState<string | null>(null);
  const [logoId, setLogoId] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [insertUniversity, { loading: universityLoading }] =
    useMutation(INSERT_UNIVERSITY);
  const client = useApolloClient();

  const onSubmit = async (data: Record<string, any>) => {
    try {
      const universityData: any = {
        ...data,
        featured,
        brd_id: brdId,
        country_id: countryId,
        cons_media: {
          data: [],
        },
      };

      if (imageId) {
        universityData.cons_media.data.push({
          image_id: imageId,
          type: "image",
          featured: true,
        });
      }

      if (logoId) {
        universityData.cons_media.data.push({
          image_id: logoId,
          type: "image",
          logo: true,
        });
      }

      if (universityData.cons_media.data.length === 0) {
        delete universityData.cons_media;
      }

      await insertUniversity({
        variables: { data: universityData },
      });

      await client.refetchQueries({ include: "all" });
      toast.success("University added successfully!");
      reset();
      setFeatured(false);
      setImageId(null);
      setImageUrl("");
      setLogoUrl("");
      setLogoId(null);
      setSidePopup(false);
    } catch (err) {
      console.error("Error inserting university:", err);
      toast.error("Failed to add university");
    }
  };

  const handleImageRemove = async () => {
    if (imageUrl && imageId) {
      await nhost.storage.delete({ fileId: imageId });
      setImageUrl("");
      setImageId(null);
    }
  };
  const handleLogoImageRemove = async () => {
    if (logoUrl && logoId) {
      await nhost.storage.delete({ fileId: logoId });

      setLogoUrl("");
      setLogoId(null);
    }
  };

  const uploadImage = async (file: File) => {
    const response = await nhost.storage.upload({ file });
    if (response.error) throw new Error(response.error.message);
    setImageId(response.fileMetadata.id);
    setImageUrl(
      `${process.env.REACT_APP_NHOST_STORAGE_URL}${response.fileMetadata.id}`
    );
  };
  const uploadlogoImage = async (file: File) => {
    const response = await nhost.storage.upload({ file });
    if (response.error) throw new Error(response.error.message);
    setLogoId(response.fileMetadata.id);
    setLogoUrl(
      `${process.env.REACT_APP_NHOST_STORAGE_URL}${response.fileMetadata.id}`
    );
  };

  return (
    <div className="max-w-md w-full p-6 bg-gray-100 dark:bg-gray-800 rounded-lg shadow-md">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Add University
      </h1>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <TextField label="Name" fullWidth required {...register("name")} />
        <TextField label="Address" fullWidth {...register("address")} />
        <TextField
          label="Contact Info"
          fullWidth
          {...register("contact_info")}
        />
        <TextField label="Benefits" fullWidth {...register("benefits")} />
        <TextField label="Slug" fullWidth {...register("slug")} />
        <TextField label="Description" fullWidth {...register("description")} />
        <FormControlLabel
          control={
            <Switch
              checked={featured}
              onChange={() => setFeatured(!featured)}
            />
          }
          label="Featured"
        />
        <Button
          variant="outlined"
          component="label"
          className="dark:text-dark-primary w-[250px]"
        >
          Upload Thumbnail
          <input
            type="file"
            hidden
            onChange={async (e) => {
              const file = e.target.files?.[0];
              if (file) await uploadImage(file);
            }}
          />
        </Button>

        {imageUrl && (
          <div className="relative">
            <button
              type="button"
              className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1"
              onClick={handleImageRemove}
            >
              ✕
            </button>
            <img src={imageUrl} alt="Uploaded" className="w-[300px]" />
          </div>
        )}

        <Button
          variant="outlined"
          component="label"
          className="dark:text-dark-primary w-full"
        >
          Upload Logo
          <input
            type="file"
            hidden
            onChange={async (e) => {
              const file = e.target.files?.[0];
              if (file) await uploadlogoImage(file);
            }}
          />
        </Button>

        {logoUrl && (
          <div className="relative">
            <button
              type="button"
              className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1"
              onClick={handleLogoImageRemove}
            >
              ✕
            </button>
            <img src={logoUrl} alt="Uploaded" className="w-[300px]" />
          </div>
        )}

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={universityLoading}
        >
          {universityLoading ? "Submitting..." : "Submit"}
        </Button>
      </form>
    </div>
  );
};

export default AddUniversity;
