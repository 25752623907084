import { useMutation } from "@apollo/client";
import { Button, TextField } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { INSERT_CONTACT_DETAIL } from "~/graphql/cons_contact_info/Mutation";
import { GET_CONTACT_INFO } from "~/graphql/cons_contact_info/Query";

function AddConsContactInfo({ brdId, setSidePopup }: any) {
  const { register, handleSubmit, reset } = useForm();

  const [insertContactDetail, { loading }] = useMutation(INSERT_CONTACT_DETAIL, {
    refetchQueries: [
      {
        query: GET_CONTACT_INFO,
        variables: { brdId }, // Pass the brdId variable to the query
      },
    ],
    awaitRefetchQueries: true, // Ensure refetch completes before resolving the mutation
  });

  const onSubmit = async (formData: any) => {
    if (!brdId) {
      toast.error("Board ID is required!");
      return;
    }

    try {
      const response = await insertContactDetail({
        variables: {
          data: [
            {
              brd_id: brdId,
              address1: formData.address1,
              city: formData.city,
              country: formData.country,
              email: formData.email,
              office_close: formData.office_close,
              office_open: formData.office_open,
              phone: formData.phone,
              whatsapp: formData.whatsapp,
            },
          ],
        },
      });

      if (response.data.insert_brd_contact_detail.affected_rows > 0) {
        toast.success("Contact info added successfully!");
        reset();
        setSidePopup(false); // Close the popup
      } else {
        toast.warning("No records were added.");
      }
    } catch (err) {
      console.error("Error adding contact:", err);
      toast.error("Failed to add contact.");
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Add Consultancy Contact Info
      </h1>

      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <TextField label="Address" variant="outlined" fullWidth {...register("address1")} />
        <TextField label="City" variant="outlined" fullWidth {...register("city")} />
        <TextField label="Country" variant="outlined" fullWidth {...register("country")} />
        <TextField label="Email" variant="outlined" fullWidth {...register("email")} />
        <TextField label="Phone" variant="outlined" fullWidth {...register("phone")} />
        <TextField label="WhatsApp" variant="outlined" fullWidth {...register("whatsapp")} />
        <TextField label="Office Open Time" variant="outlined" fullWidth {...register("office_open")} />
        <TextField label="Office Close Time" variant="outlined" fullWidth {...register("office_close")} />

        <Button type="submit" variant="contained" color="primary" disabled={loading}>
          {loading ? "Adding..." : "Add"}
        </Button>
      </form>
    </div>
  );
}

export default AddConsContactInfo;