import { Button } from '@mui/material';
import { useState } from 'react'
import SideDrawer from '~/components/common/sideDrawer';
import ShowConsContactInfo from './component/ShowConsContactInfo';
import AddConsContactInfo from './component/AddConsContactInfo';


function ConsContactInfo ({brdId} : any) {
       const [openPopup, setOpenPopup] = useState(false);
  return (
    <div className="bg-secondary dark:bg-dark-secondary rounded-lg  p-5 mb-5">
    <div className="flex justify-between items-center mb-4">
      <div className="flex items-center justify-center h-full ">
        <h1 className="text-xl flex items-center justify-center">
          Consultancy Contact Info
        </h1>
      </div>

      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpenPopup(true)}
      >
        Add Consultancy Contact Info
      </Button>
    </div>
    <ShowConsContactInfo brdId={brdId} />
    <SideDrawer
      component={
        <AddConsContactInfo brdId={brdId}  setSidePopup={setOpenPopup} />
      }
      sidePopup={openPopup}
      setSidePopup={setOpenPopup}
    />
  </div>
  )
}

export default ConsContactInfo
