import { gql } from "@apollo/client";

export const GET_UNIVERSITIES = gql`
  query GetUniversities($brd_id: uuid!, $country_id: uuid!) {
    cons_university(
      where: {brd_id: { _eq: $brd_id }, country_id: { _eq: $country_id } }
    ) {
      brd_id
      id
      name
      slug
      address
      contact_info
      benefits
      description
      featured
      cons_media {
        id
        image_id
        featured
        logo
      }
    }
  }
`;
export const GET_UNIVERSITIES_WITH_BRD_ID = gql`
  query GetUniversitiesWithBrdId($brd_id: uuid!) {
    cons_university(where: { brd_id: { _eq: $brd_id } }) {
      id
      name
    }
  }
`;



export const GET_UNIVERSITy_BY_ID = gql`
query GET_UNIVERSITIES($id: uuid!) {
  cons_university(
    where: {
      id: { _eq: $id }
    }
  ) {
    id
    name
    brd_id
    address
    contact_info
    benefits
    slug
    description
    featured
    cons_media {
      id
      image_id
      featured
      logo
    }
  }
}

`