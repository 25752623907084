import { gql } from "@apollo/client";

export const INSERT_CONTACT_DETAIL = gql`
 mutation InsertContactDetail($data: [brd_contact_detail_insert_input!]!) {
  insert_brd_contact_detail(objects: $data) {
    affected_rows
  }
}
`;


export const UPDATE_BRD_CONTACT_DETAIL = gql`
  mutation UpdateBrdContactDetail($id: uuid!, $data: brd_contact_detail_set_input!) {
    update_brd_contact_detail(
      where: { id: { _eq: $id } }
      _set: $data
    ) {
      affected_rows
    }
  }
`;









export const DELETE_CONTACT_INFO = gql`
  mutation DeleteContactInfo($id: uuid!) {
    delete_brd_contact_detail(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
