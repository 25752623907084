import React from "react";
import {
  Button,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useApolloClient, useMutation } from "@apollo/client";
import { INSERT_SOCIAL_LINKS } from "~/graphql/cons_social_links/Mutation";
import { toast } from "react-toastify";

const socialPlatforms = [
  { label: "Facebook", value: "Facebook" },
  { label: "LinkedIn", value: "Linkedin" },
  { label: "Instagram", value: "Instagram" },
  { label: "Youtube", value: "Youtube" },
];

interface FormValues {
  name: string;
  link: string;
}

const AddConsSocialLinks = ({
  setSidePopup,
  brdId,
}: {
  setSidePopup: (open: boolean) => void;
  brdId: any;
}) => {
  const client = useApolloClient();
  const { register, handleSubmit, control, reset } = useForm<FormValues>({
    defaultValues: {
      name: "",
      link: "",
    },
  });

  const [insertSocialLinks] = useMutation(INSERT_SOCIAL_LINKS);

  const onSubmit = async (data: FormValues) => {
    try {
      const response = await insertSocialLinks({
        variables: {
          data: [{
            brd_id: brdId,
            name: data.name,
            link: data.link,
          }],
        },
      });

      if (response?.data?.insert_cons_social_links?.affected_rows) {
        client.refetchQueries({ include: "all" });
        toast.success("Social link added successfully!");
      }

      setSidePopup(false);
      reset();
    } catch (error) {
      toast.error("Failed to add social link!");
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Add Social Link
      </h1>

      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
        {/* Social Platform Name Dropdown */}
        <FormControl fullWidth>
          <InputLabel>Platform</InputLabel>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Select {...field} label="Platform">
                {socialPlatforms.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>

        {/* Social Link Input */}
        <TextField
          label="Profile URL"
          variant="outlined"
          fullWidth
          {...register("link")}
        />

        <Button type="submit" variant="contained" color="primary">
          Add
        </Button>
      </form>
    </div>
  );
};

export default AddConsSocialLinks;
