// import { useApolloClient, useMutation } from "@apollo/client";
// import {
//   Button,
//   MenuItem,
//   Select,
//   TextField,
//   FormControl,
//   InputLabel,
// } from "@mui/material";
// import { useForm } from "react-hook-form";
// import { UPDATE_BRD_HOTEL } from "~/graphql/brd_hotels/Mutation";
// import { toast } from "react-toastify";
// import { useEffect, useState } from "react";
// import { nhost } from "~/lib/nhost";
// import { DELETE_HOTEL_GALLERY } from "~/graphql/brd_hotels_gallery/Mutation";

// const EditHotel = ({ selectedHotel, setSidePopup, brd_id }: any) => {
//   const [NewImageId, setNewImageId] = useState<any>(null);
//   const [imageIds, setImageIds] = useState<string[]>([]);

//   const { register, handleSubmit, reset, setValue, watch } = useForm<any>({
//     defaultValues: {
//       name: selectedHotel?.name || "",
//       coordinates: selectedHotel?.coordinates || "",
//       city: selectedHotel?.city || "",
//       price: selectedHotel?.price || "",
//     },
//   });

//   const client = useApolloClient();
//   const [updateHotel, { loading: hotelloading, error: hotelerror }] =
//     useMutation(UPDATE_BRD_HOTEL);
//   const [deleteHotelGallery, { data, loading, error }] =
//     useMutation(DELETE_HOTEL_GALLERY);

//   const onSubmit = async (data: any) => {
//     try {
//       const response = await updateHotel({
//         variables: {
//           id: selectedHotel?.id,
//           name: data.name,
//           coordinates: data.coordinates,
//           city: data.city,
//           price: data.price,
//         },
//       });

//       if (response?.data?.update_brd_hotels?.affected_rows > 0) {
//         await client.refetchQueries({
//           include: "all",
//         });
//         toast.success("Hotel Package Updated Successfully");
//         setSidePopup(false);
//         reset();
//       }
//     } catch (error) {
//       console.error("Error updating hotel:", error);
//       toast.error("Error updating hotel package");
//     }
//   };
//   const convertImageToWebP = async (file: any) => {
//     return new Promise((resolve, reject) => {
//       const img = new Image();
//       img.src = URL.createObjectURL(file);

//       img.onload = () => {
//         const canvas = document.createElement("canvas");
//         canvas.width = img.width;
//         canvas.height = img.height;
//         const ctx: any = canvas.getContext("2d");
//         ctx.drawImage(img, 0, 0);

//         canvas.toBlob(
//           (blob) => {
//             if (blob) {
//               resolve(
//                 new File([blob], file.name.replace(/\.\w+$/, ".webp"), {
//                   type: "image/webp",
//                 })
//               );
//             } else {
//               reject(new Error("Conversion to WebP failed"));
//             }
//           },
//           "image/webp",
//           0.8
//         );
//       };

//       img.onerror = (error) => reject(error);
//     });
//   };

//   const handleImageChange = async (
//     event: React.ChangeEvent<HTMLInputElement>
//   ) => {
//     const file = event.target.files?.[0];

//     if (!file) {
//       return;
//     }

//     try {
//       const webpFile = (await convertImageToWebP(file)) as File;
//       const response = await nhost.storage.upload({ file: webpFile });
//       if (response.error) {
//         throw new Error(response.error.message);
//       }
//       setNewImageId(response.fileMetadata.id);
//     } catch (error) {}
//   };

//   const imgUrl = NewImageId
//     ? `${process.env.REACT_APP_NHOST_STORAGE_URL}${NewImageId}`
//     : `${process.env.REACT_APP_NHOST_STORAGE_URL}${selectedHotel?.brd_hotels_galleries[0]?.img_id}`;

//   return (
//     <div className="md:w-[500px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
//       <div className="bg-secondary mb-4 sm:p-6 rounded-lg shadow dark:bg-dark-secondary">
//         <h1 className="text-center text-3xl text-basic dark:text-dark-primary mb-6">
//           Edit Hotel
//         </h1>
//         <form
//           onSubmit={handleSubmit(onSubmit)}
//           className="text-primary dark:text-dark-primary flex-col"
//         >
//           <div className="flex flex-col space-y-4">
//             <TextField
//               label="Name"
//               variant="outlined"
//               fullWidth
//               required
//               {...register("name")}
//               className="dark:text-dark-primary"
//             />

//             <TextField
//               label="Coordinates"
//               variant="outlined"
//               fullWidth
//               required
//               {...register("coordinates")}
//               className="dark:text-dark-primary"
//             />

//             <FormControl fullWidth>
//               <InputLabel id="city-label">City</InputLabel>
//               <Select
//                 labelId="city-label"
//                 {...register("city")}
//                 required
//                 value={watch("city")}
//                 onChange={(e) => setValue("city", e.target.value)}
//                 className="dark:text-dark-primary"
//               >
//                 <MenuItem value="makkah">Makkah</MenuItem>
//                 <MenuItem value="madinah">Madinah</MenuItem>
//               </Select>
//             </FormControl>
//             <TextField
//               label="Price Per Night"
//               variant="outlined"
//               fullWidth
//               required
//               type="number"
//               {...register("price")}
//               className="dark:text-dark-primary"
//             />
//             <Button
//               variant="outlined"
//               component="label"
//               className="dark:text-dark-primary"
//             >
//               Replace Image
//               <input
//                 type="file"
//                 onChange={handleImageChange}
//                 hidden
//                 accept="image/*"
//               />
//             </Button>
//             {imgUrl && (
//               <img
//                 src={imgUrl}
//                 alt={"Image"}
//                 className="max-h-[200px] rounded-lg shadow-md mt-4"
//               />
//             )}
//             <Button
//               type="submit"
//               variant="contained"
//               color="primary"
//               disabled={loading}
//             >
//               {loading ? "Saving..." : "Save Changes"}
//             </Button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default EditHotel;

import { useApolloClient, useMutation } from "@apollo/client";
import {
  Button,
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { UPDATE_BRD_HOTEL } from "~/graphql/brd_hotels/Mutation";
import {
  DELETE_BRD_HOTELS_GALLERY,
  INSERT_BRD_HOTELS_GALLERY,
} from "~/graphql/brd_hotels_gallery/Mutation";
import { toast } from "react-toastify";
import { useState } from "react";
import { nhost } from "~/lib/nhost";

const EditHotel = ({ selectedHotel, setSidePopup }: any) => {
  const [imageIds, setImageIds] = useState<string[]>(
    selectedHotel?.brd_hotels_galleries?.map(
      (gallery: any) => gallery.img_id
    ) || []
  );
  const [insertHotelGallery] = useMutation(INSERT_BRD_HOTELS_GALLERY);

  const { register, handleSubmit, reset, setValue, watch } = useForm<any>({
    defaultValues: {
      name: selectedHotel?.name || "",
      coordinates: selectedHotel?.coordinates || "",
      city: selectedHotel?.city || "",
      price: selectedHotel?.price || "",
    },
  });

  const client = useApolloClient();
  const [updateHotel, { loading }] = useMutation(UPDATE_BRD_HOTEL);
  const [deleteHotelGallery] = useMutation(DELETE_BRD_HOTELS_GALLERY);

  const convertImageToWebP = async (file: any) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx: any = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        canvas.toBlob(
          (blob) =>
            blob
              ? resolve(
                  new File([blob], file.name.replace(/\.\w+$/, ".webp"), {
                    type: "image/webp",
                  })
                )
              : reject(new Error("Conversion to WebP failed")),
          "image/webp",
          0.8
        );
      };

      img.onerror = reject;
    });
  };

  const handleImageChange = async (event: any) => {
    const files = event.target.files;

    try {
      const uploadedImageIds = await Promise.all(
        Array.from(files).map(async (file) => {
          const webpFile = (await convertImageToWebP(file)) as File;
          const response = await nhost.storage.upload({ file: webpFile });
          if (response.error) throw new Error(response?.error?.message);

          await insertHotelGallery({
            variables: {
              img_id: response.fileMetadata.id,
              hotel_id: selectedHotel?.id,
            },
          });

          return response.fileMetadata.id;
        })
      );

      setImageIds((prev) => [...prev, ...uploadedImageIds]);
      toast.success("Images uploaded and added to gallery successfully");
    } catch (error) {
      toast.error("Error uploading images");
    }
  };

  const handleImageDelete = async (index: number) => {
    const imageIdToRemove = imageIds[index];
    try {
      const { error } = await nhost.storage.delete({ fileId: imageIdToRemove });
      if (error) throw error;
      await deleteHotelGallery({ variables: { img_id: imageIdToRemove } });
      setImageIds((prev) => prev.filter((_, i) => i !== index));
    } catch {
      toast.error("Error deleting image");
    }
  };

  const onSubmit = async (data: any) => {
    try {
      const response = await updateHotel({
        variables: {
          id: selectedHotel?.id,
          name: data.name,
          coordinates: data.coordinates,
          city: data.city,
          price: data.price,
        },
      });

      if (response?.data?.update_brd_hotels?.affected_rows > 0) {
        await client.refetchQueries({ include: "all" });
        toast.success("Hotel Package Updated Successfully");
        setSidePopup(false);
        reset();
      }
    } catch {
      toast.error("Error updating hotel package");
    }
  };

  return (
    <div className="md:w-[500px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
      <div className="bg-secondary mb-4 sm:p-6 rounded-lg shadow dark:bg-dark-secondary">
        <h1 className="text-center text-3xl text-basic dark:text-dark-primary mb-6">
          Edit Hotel
        </h1>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="text-primary dark:text-dark-primary flex-col"
        >
          <div className="flex flex-col space-y-4">
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              required
              {...register("name")}
              className="dark:text-dark-primary"
            />
            <TextField
              label="Coordinates"
              variant="outlined"
              fullWidth
              required
              {...register("coordinates")}
              className="dark:text-dark-primary"
            />
            <FormControl fullWidth>
              <InputLabel id="city-label">City</InputLabel>
              <Select
                labelId="city-label"
                {...register("city")}
                required
                value={watch("city")}
                onChange={(e) => setValue("city", e.target.value)}
                className="dark:text-dark-primary"
              >
                <MenuItem value="makkah">Makkah</MenuItem>
                <MenuItem value="madinah">Madinah</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Price Per Night"
              variant="outlined"
              fullWidth
              required
              type="number"
              {...register("price")}
              className="dark:text-dark-primary"
            />
            <Button
              variant="outlined"
              component="label"
              className="dark:text-dark-primary"
            >
              Add Images
              <input
                type="file"
                onChange={handleImageChange}
                hidden
                accept="image/*"
                multiple
              />
            </Button>
            <div className="grid grid-cols-2 gap-2">
              {imageIds.map((id, index) => (
                <div key={id} className="relative">
                  <img
                    src={`${process.env.REACT_APP_NHOST_STORAGE_URL}${id}`}
                    alt="Uploaded"
                    className="max-h-[100px] rounded-lg shadow-md"
                  />
                  <button
                    type="button"
                    onClick={() => handleImageDelete(index)}
                    className="absolute top-0 right-0 bg-basic flex items-center w-5 h-5 justify-center text-white p-1 rounded-full"
                  >
                    ✕
                  </button>
                </div>
              ))}
            </div>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? "Saving..." : "Save Changes"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditHotel;
