// import { Link, useParams } from "react-router-dom";
// import { Button } from "@mui/material";
import ShowConsultancyBlogs from "./component/ShowConsultancyBlogs";
// import ShowConsultancyBlogs from "./ShowConsultancyBlogs"; // Adjust the import path as needed

function ConsulatncyPage() {
//   const { brd_id } = useParams();

  return (
    <>
            {/* Right Side: Add Blogs Button */} 
            {/* <div> */}
              {/* <Button
                component={Link}
                to={`/admin/brands/${brd_id}/cons_posts/add_cons_post`}
                variant="contained"
              >
                Add Blogs
              </Button>
      {/* ShowConsultancyBlogs Component */}
      <ShowConsultancyBlogs />
    </>
  );
}

export default ConsulatncyPage;