import { useState } from "react";
// import LeadsFilter from "./components/LeadsFilter";
// import AllLeads from "./components/AllLeads";
import dayjs from "dayjs";
import ConsultancyLeadFilter from "./components/ConsultancyLeadFilter";
import ConsultancyAllLeads from "./components/ConsultancyAllLeads";

const ConsultancyLeads = () => {
  const today = dayjs();

  const currentMonth = [
    today.startOf("month").subtract(1, "month"),
    today.endOf("month"),
  ];

  const [selectedLeadNo, setSelectedLeadNo] = useState<any>();
  const [selectedDates, setSelectedDates] = useState<any>(currentMonth);
  const [selectedCustomer, setSelectedCustomer] = useState<any>();
  const [selectedName, setSelectedName] = useState<any>(null);

  return (
    <div>
      <ConsultancyLeadFilter
        selectedLeadNo={selectedLeadNo}
        setSelectedLeadNo={setSelectedLeadNo}
        setSelectedDates={setSelectedDates}
        selectedDates={selectedDates}
        setSelectedCustomer={setSelectedCustomer}
        setSelectedName={setSelectedName}
      />
      <ConsultancyAllLeads
        selectedLeadNo={selectedLeadNo}
        selectedDates={selectedDates}
        selectedCustomer={selectedCustomer}
        selectedName={selectedName}
      />
    </div>
  );
};

export default ConsultancyLeads;
