import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import SideDrawer from "~/components/common/sideDrawer";
import { GET_INTAKES } from "~/graphql/cons_intakes/Query";
import { DELETE_INTAKE } from "~/graphql/cons_intakes/Mutation";
import EditIntakes from "../EditIntakes";

const ShowIntakes = ({ programId }: any) => {
  const [fetchIntakes, { data, loading, error }] = useLazyQuery(GET_INTAKES);
  const [intakes, setIntakes] = useState<any[]>([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedIntake, setSelectedIntake] = useState<any | null>(null);

  const [deleteIntake, { loading: deleteLoading }] = useMutation(DELETE_INTAKE, {
    onCompleted: () => {
      toast.success("Intake deleted successfully!");
    },
    onError: (error) => {
      console.error("Error deleting intake:", error);
      toast.error("Failed to delete intake.");
    },
  });

  useEffect(() => {
    if (programId) {
      fetchIntakes({ variables: { programId } });
    }
  }, [programId, fetchIntakes]);

  useEffect(() => {
    if (data?.cons_intake) {
      setIntakes(data.cons_intake);
    }
  }, [data]);

  const handleEdit = (intake: any) => {
    setSelectedIntake(intake);
    setOpenPopup(true);
  };

  const handleDelete = async (id: string) => {
    try {
      const { data } = await deleteIntake({ variables: { id } });

      if (data?.delete_cons_intake?.affected_rows > 0) {
        setIntakes((prev) => prev.filter((intake) => intake.id !== id));
      }
    } catch (error) {
      console.error("Error deleting intake:", error);
    }
  };

  if (loading) return <p>Loading intakes...</p>;
  if (error) return <p>Error fetching intakes</p>;

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Scholarship</TableCell>
              <TableCell>Total Fee</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {intakes.map((intake) => (
              <TableRow key={intake.id}>
                <TableCell>{intake.name}</TableCell>
                <TableCell>{intake.scholarship}</TableCell>
                <TableCell>{intake.total_fee}</TableCell>
                <TableCell>
                  <div className="flex gap-3">
                    <Button onClick={() => handleEdit(intake)}>
                      <FaEdit size={18} />
                    </Button>
                    <Button onClick={() => handleDelete(intake.id)} disabled={deleteLoading}>
                      <FaTrash size={18} />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {openPopup && selectedIntake && (
        <SideDrawer
          component={<EditIntakes selectedIntake={selectedIntake} setSidePopup={setOpenPopup} />}
          sidePopup={openPopup}
          setSidePopup={setOpenPopup}
        />
      )}
    </>
  );
};

export default ShowIntakes;
