import React from "react";
import Layout from "~/components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import University from "~/modules/BrandModule/segments/Countries/components/University";

const UniversityPage = () => {
  return (
    <div>
      <Layout>
        <BrandModule>
          <University />
        </BrandModule>
      </Layout>
    </div>
  );
};

export default UniversityPage;
