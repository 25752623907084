import React from "react";
import { TextField, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation, useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import { UPDATE_PROGRAMS } from "~/graphql/cons_programs/Mutation";

const EditPrograms = ({ selectedProgram, setSidePopup }: any) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      name: selectedProgram?.name || "",
      enrollment_criteria: selectedProgram?.enrollment_criteria || "",
      min_marks_req: selectedProgram?.min_marks_req || "",
      slug : selectedProgram ?.slug
    },
  });

  const [updateProgram, { loading }] = useMutation(UPDATE_PROGRAMS);
  const client = useApolloClient();

  const onSubmit = async (formData: any) => {
    if (!selectedProgram?.id) {
      toast.error("Invalid program data: ID is missing");
      return;
    }

    try {
      const { data } = await updateProgram({
        variables: {
          id: selectedProgram.id,
          data: {
            name: formData.name,
            enrollment_criteria: formData.enrollment_criteria,
            min_marks_req: parseInt(formData.min_marks_req),
            slug : formData.slug 
          },
        },
      });

      if (data?.update_cons_program?.affected_rows > 0) {
        toast.success("Program updated successfully!");
        client.refetchQueries({ include: "all" });
        setSidePopup(false);
      } else {
        toast.error("Update failed.");
      }
    } catch (error) {
      console.error("Error updating program:", error);
      toast.error("Failed to update program.");
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Edit Program
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Program Name"
          variant="outlined"
          required
          fullWidth
          {...register("name")}
          style={{ marginBottom: "16px" }}
        />
        <TextField
          label="Slug"
          variant="outlined"
          required
          fullWidth
          {...register("slug")}
          style={{ marginBottom: "16px" }}
        />
        <TextField
          label="Enrollment Criteria"
          variant="outlined"
          required
          fullWidth
          {...register("enrollment_criteria")}
          style={{ marginBottom: "16px" }}
        />
        <TextField
          label="Minimum Marks Required"
          variant="outlined"
          required
          type="number"
          fullWidth
          {...register("min_marks_req")}
          style={{ marginBottom: "16px" }}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
          {loading ? "Updating..." : "Update Program"}
        </Button>
      </form>
    </div>
  );
};

export default EditPrograms;
