import { Button } from '@mui/material'
import  { useState } from 'react'

import SideDrawer from '~/components/common/sideDrawer'
import ShowFAQS from './ShowConsFAQS';
import AddFAQS from './AddConsFAQS';

function ConsFAQS({brdId} :any) {
    const [openPopup, setOpenPopup] = useState(false);
  return (
    <div className="bg-secondary dark:bg-dark-secondary rounded-lg  p-5 mb-5">
    <div className="flex justify-between items-center mb-4">
      <div className="flex items-center justify-center h-full ">
        <h1 className="text-xl flex items-center justify-center">
          Consultancy FAQS
        </h1>
      </div>

      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpenPopup(true)}
      >
        Add Consultancy FAQS 
      </Button>
    </div>
    <ShowFAQS brdId={brdId} />
    <SideDrawer
      component={
        <AddFAQS brdId={brdId}  setSidePopup={setOpenPopup} />
      }
      sidePopup={openPopup}
      setSidePopup={setOpenPopup}
    />
  </div>
  )
}

export default ConsFAQS
