import { gql } from "@apollo/client";

export const GET_UNIVERSITY_CONTACTS_WITH_BRD_ID = gql`
  query GetUniversityContactsWithBrdId($universityId: uuid!) {
    cons_point_of_contact(where: { university_id: { _eq: $universityId } }) {
      phone_no
      name
      id
      university_id
      email
      cons_designation {
        designation
      }
    }
  }
`;
