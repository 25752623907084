import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import CustomList from "../CustomList";
const EducationalSideBar = ({
  role,
  activeDropdown,
  setActiveDropdown,
}: any) => {
  return (
    <>
      {role === "admin" ? (
        <div className="text-primary mt-[70px] z-30">
          <CustomList
            setActiveDropdown={setActiveDropdown}
            activeDropdown={activeDropdown}
            listItems={[
              {
                name: "Inquiries",
                icon: <DescriptionOutlinedIcon />,
                link: "/admin/consultancy/leads",
              },
            ]}
          />
          <CustomList
            setActiveDropdown={setActiveDropdown}
            activeDropdown={activeDropdown}
            listItems={[
              {
                name: "Vendors",
                icon: <GroupOutlinedIcon />,
                link: "/admin/vendors",
              },
            ]}
          />
          <CustomList
            setActiveDropdown={setActiveDropdown}
            activeDropdown={activeDropdown}
            listItems={[
              {
                name: "Customers",
                icon: <GroupOutlinedIcon />,
                link: "/admin/customers",
              },
            ]}
          />
        </div>
      ) : role === "consultant" ? (
        <div className="text-primary mt-[70px] z-30">
          <CustomList
            setActiveDropdown={setActiveDropdown}
            activeDropdown={activeDropdown}
            listItems={[
              {
                name: "Inquiries",
                icon: <DescriptionOutlinedIcon />,
                link: "/consultant/consultancy/leads",
              },
            ]}
          />
          <CustomList
            setActiveDropdown={setActiveDropdown}
            activeDropdown={activeDropdown}
            listItems={[
              {
                name: "Customers",
                icon: <GroupOutlinedIcon />,
                link: "/consultant/customers",
              },
            ]}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default EducationalSideBar;
