import {
  Button,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useForm, Controller, useWatch } from "react-hook-form";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { INSERT_CONS_HEADINGS } from "~/graphql/cons_headings/Mutation";
import { GET_CONS_HEADINGS } from "~/graphql/cons_headings/Query";
import { toast } from "react-toastify";

const pageOptions = [
  { label: "Home", value: "home" },
  { label: "Blogs", value: "blogs" },
  { label: "About", value: "about" },
  { label: "Contact", value: "contact" },
  { label: "Announcment", value: "announcment" },
];

const sectionOptionsMap: Record<string, { label: string; value: string }[]> = {
  home: [
    { label: "Approval Steps", value: "approval_steps" },
    { label: "Featured Universities", value: "featured_universities" },
    { label: "Featured Blogs", value: "featured_blogs" },
    { label: "Testimonial Section", value: "testimonial_section" },
    { label: "Header Section", value: "heaader_section" },
    { label: "Footer Section", value: "footer_section" },
    { label: "Learn More", value: "learn_more_button" },
    { label: "Submit Form", value: "form_button" },
  ],
  about: [
    { label: "About Hero", value: "about_hero" },
    { label: "FAQs Section", value: "faqs_section" },
    { label: "Our Team Section", value: "team_section" },
  ],
  blogs: [{ label: "Blogs Heading", value: "blogs_heading" }],
  contact: [{ label: "Contact Us", value: "contact_heading" }],
  announcment: [{ label: "Announcment", value: "announcment_section" }],
};

interface FormValues {
  title: string;
  description: string;
  pageName: string;
  sectionName: string;
}

const AddConsHeadings = ({
  setSidePopup,
  brdId,
}: {
  setSidePopup: (open: boolean) => void;
  brdId: any;
}) => {
  const client = useApolloClient();

  const { register, handleSubmit, control, reset, watch } = useForm<FormValues>(
    {
      defaultValues: {
        title: "",
        description: "",
        pageName: "",
        sectionName: "",
      },
    }
  );

  const selectedPage = watch("pageName");

  const { data, error: headigError } = useQuery(GET_CONS_HEADINGS, {
    variables: { brdId: brdId },
  });
  const existingSectionNames = new Set(
    data?.cons_headings?.map((item: any) => item.section_name) || []
  );

  const filteredSectionOptions =
    sectionOptionsMap[selectedPage]?.filter(
      (option) => !existingSectionNames.has(option.value)
    ) || [];

  const [insertConsHeadings, { error }] = useMutation(INSERT_CONS_HEADINGS);

  const onSubmit = async (data: FormValues) => {
    try {
      const response = await insertConsHeadings({
        variables: {
          objects: [
            {
              page_name: data.pageName,
              section_name: data.sectionName,
              heading: data.title,
              description: data.description,
              brd_id: brdId,
            },
          ],
        },
      });
      console.log("response", response, error);
      if (response?.data?.insert_cons_headings?.affected_rows) {
        client.refetchQueries({
          include: "all",
        });
        toast.success("Heading added successfully!");
      }

      setSidePopup(false);
      reset();
    } catch (error) {
      toast.error("Failed to add heading!");
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Add Consultancy Heading
      </h1>

      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <TextField
          label="Heading"
          variant="outlined"
          fullWidth
          {...register("title")}
        />
        <TextField
          label="Description"
          variant="outlined"
          fullWidth
          {...register("description")}
        />

        {/* Page Name Select */}
        <FormControl fullWidth>
          <InputLabel>Page Name</InputLabel>
          <Controller
            name="pageName"
            control={control}
            render={({ field }) => (
              <Select {...field} label="Page Name">
                {pageOptions.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>

        {/* Section Name Select */}
        <FormControl fullWidth>
          <InputLabel>Section Name</InputLabel>
          <Controller
            name="sectionName"
            control={control}
            render={({ field }) => (
              <Select {...field} label="Section Name">
                {filteredSectionOptions.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>

        <Button type="submit" variant="contained" color="primary">
          Add
        </Button>
      </form>
    </div>
  );
};

export default AddConsHeadings;
