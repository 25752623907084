import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { GET_CONS_DESIGNATION_WITH_BRD_ID } from "~/graphql/cons_designation/Query";
import { UPDATE_UNIVERSITY_CONTACT_WITH_ID } from "~/graphql/cons_point_of_contact/Mutation";
import { toast } from "react-toastify";

const EditPointOfContact = ({ selectedPOC, setSidePopup, brdId }: any) => {
  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      name: selectedPOC?.name || "",
      email: selectedPOC?.email || "",
      phone_no: selectedPOC?.phone_no || "",
      designation_id: selectedPOC?.designation_id || "",
    },
  });
  const [updatePointOfContact] = useMutation(UPDATE_UNIVERSITY_CONTACT_WITH_ID);

  // const [updatePointOfContact] = useMutation(UPDATE_CONS_POINT_OF_CONTACT);
  const { data } = useQuery(GET_CONS_DESIGNATION_WITH_BRD_ID, {
    variables: { brdId: brdId },
  });
  const client = useApolloClient();

  useEffect(() => {
    setValue("name", selectedPOC?.name || "");
    setValue("email", selectedPOC?.email || "");
    setValue("phone_no", selectedPOC?.phone_no || "");
    setValue(
      "designation_id",
      selectedPOC?.cons_designation?.designation_id || ""
    );
  }, [selectedPOC, setValue]);

  const onSubmit = async (formData: Record<string, any>) => {
    try {
      const { data } = await updatePointOfContact({
        variables: {
          id: selectedPOC?.id,
          name: formData.name,
          email: formData.email,
          phone_no: formData.phone_no,
          designation_id: formData.designation_id,
          university_id: selectedPOC?.university_id,
        },
      });

      if (data?.update_cons_point_of_contact?.affected_rows > 0) {
        toast.success("Point of Contact updated successfully!");
        client.refetchQueries({ include: "all" });
        setSidePopup(false);
      }
    } catch {
      toast.error("Failed to update Point of Contact!");
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Edit Point of Contact
      </h1>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <TextField label="Name" fullWidth required {...register("name")} />
        <TextField
          label="Email"
          type="email"
          fullWidth
          required
          {...register("email")}
        />
        <TextField
          label="Phone No"
          fullWidth
          required
          {...register("phone_no")}
        />
        <FormControl fullWidth>
          <InputLabel>Designation</InputLabel>
          <Select
            value={watch("designation_id") || ""}
            onChange={(e) => setValue("designation_id", e.target.value)}
            required
          >
            {data?.cons_designation.map((designation: any) => (
              <MenuItem key={designation.id} value={designation.id}>
                {designation.designation}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Update
        </Button>
      </form>
    </div>
  );
};

export default EditPointOfContact;
