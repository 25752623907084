import React from 'react'
import Layout from '~/components/common/Layout'
import BrandModule from '~/modules/BrandModule'
import EditUniversity from '~/modules/BrandModule/segments/Countries/components/University/component/EditUniversity'

function EditUniversitiesPage() {
  return (
    <div>
    <Layout>
      <BrandModule>
       <EditUniversity />
      </BrandModule>
    </Layout>
  </div>
  )
}

export default EditUniversitiesPage
