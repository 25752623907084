// import { DataGrid, GridColDef } from "@mui/x-data-grid";
// import Paper from "@mui/material/Paper";
// import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
// import moment from "moment";

// const columns: GridColDef[] = [
//   {
//     field: "leadNo",
//     headerName: "Lead No.",
//     flex: 1,
//   },
//   {
//     field: "date",
//     headerName: "Date",
//     flex: 1,
//   },
//   {
//     field: "name",
//     headerName: "Name",
//     flex: 1,
//   },
//   {
//     field: "email",
//     headerName: "Email",
//     flex: 1,
//   },
//   {
//     field: "phoneNumber",
//     headerName: "Phone Number",
//     flex: 1,
//   },
//   {
//     field: "intakeName",
//     headerName: "Intake Name",
//     flex: 1,
//   },
//   {
//     field: "education",
//     headerName: "Education Details",
//     flex: 1,
//     renderCell: (params) => (
//       <Box display="flex" flexDirection="column">
//         <Typography variant="body2">
//           Previous Degree: {params.value.previousDegree || "N/A"}
//         </Typography>
//         <Typography variant="body2" color="textSecondary">
//           Percentage: {params.value.marksPercentage || "N/A"}
//         </Typography>
//       </Box>
//     ),
//   },
//   {
//     field: "selectedInfo",
//     headerName: "Selected Details",
//     flex: 1,
//     renderCell: (params) => (
//       <Box display="flex" flexDirection="column">
//         <Typography variant="body2">
//           University Name : {params.value.university_name || "N/A"}
//         </Typography>
//         <Typography variant="body2" color="textSecondary">
//           Campus Name: {params.value.campus_name || "N/A"}
//         </Typography>

//         <Typography variant="body2" color="textSecondary">
//           Department Name: {params.value.department_name || "N/A"}
//         </Typography>
//         <Typography variant="body2" color="textSecondary">
//           Program Name: {params.value.program_name || "N/A"}
//         </Typography>
//         <Typography variant="body2" color="textSecondary">
//           Intake Name: {params.value.intakeName || "N/A"}
//         </Typography>
//       </Box>
//     ),
//   },
// ];

// const paginationModel = { page: 0, pageSize: 5 };

// const ConsultancyLeadTable = ({ leads }: any) => {
//   console.log("sadfsdfasd:", leads?.[0]);
//   const rows =
//     leads?.map((lead: any) => {
//       const date = moment(lead?.created_at).format("YYYY-MM-DD");

//       return {
//         id: lead?.id,
//         leadNo: lead?.lead_no || "N/A",
//         date,
//         name: lead.name || "N/A",
//         email: lead.email || "N/A",
//         phoneNumber: lead.phone_number || "N/A",
//         intakeName: lead.intake_name || "N/A",
//         education: {
//           previousDegree: lead.previous_degree || "N/A",
//           marksPercentage: lead.marks_prcentage || "N/A",
//         },
//         selectedInfo: {
//           university_name: lead.university_name || "N/A",
//           campus_name: lead.campus_name || "N/A",
//           department_name: lead.department_name || "N/A",
//           program_name: lead.program_name || "N/A",
//         },
//       };
//     }) || [];

//   return (
//     <Paper
//     // sx={{ width: "100%" }}
//     >
//       <Box sx={{ width: "100%", overflowX: "auto" }}>
//         <DataGrid
//           rows={rows}
//           columns={columns}
//           initialState={{ pagination: { paginationModel } }}
//           pageSizeOptions={[5, 10]}
//           getRowHeight={() => "auto"}
//           sx={{ border: 0 }}
//         />
//       </Box>
//     </Paper>
//   );
// };

// export default ConsultancyLeadTable;

import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import moment from "moment";

const columns: GridColDef[] = [
  { field: "leadNo", headerName: "Lead No.", flex: 1 },
  { field: "date", headerName: "Date", flex: 1 },
  { field: "name", headerName: "Name", flex: 1 },
  { field: "email", headerName: "Email", flex: 1 },
  { field: "phoneNumber", headerName: "Phone Number", flex: 1 },
  { field: "intakeName", headerName: "Intake Name", flex: 1 },
  {
    field: "previousDegree",
    headerName: "Previous Degree",
    flex: 1,
  },
  {
    field: "marksPercentage",
    headerName: "Percentage",
    flex: 1,
  },
  {
    field: "universityName",
    headerName: "University Name",
    flex: 1,
  },
  {
    field: "campusName",
    headerName: "Campus Name",
    flex: 1,
  },
  {
    field: "departmentName",
    headerName: "Department Name",
    flex: 1,
  },
  {
    field: "programName",
    headerName: "Program Name",
    flex: 1,
  },
];

const paginationModel = { page: 0, pageSize: 5 };

const ConsultancyLeadTable = ({ leads }: any) => {
  const rows =
    leads?.map((lead: any) => {
      const date = moment(lead?.created_at).format("YYYY-MM-DD");

      return {
        id: lead?.id,
        leadNo: lead?.lead_no || "N/A",
        date,
        name: lead.name || "N/A",
        email: lead.email || "N/A",
        phoneNumber: lead.phone_number || "N/A",
        intakeName: lead.intake_name || "N/A",
        previousDegree: lead.previous_degree || "N/A",
        marksPercentage: lead.marks_prcentage || "N/A",
        universityName: lead.university_name || "N/A",
        campusName: lead.campus_name || "N/A",
        departmentName: lead.department_name || "N/A",
        programName: lead.program_name || "N/A",
      };
    }) || [];

  return (
    <Paper sx={{ width: "100%", overflowX: "auto" }}>
      {/* <Box sx={{ width: "100%", overflowX: "auto" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[5, 10]}
          getRowHeight={() => "auto"}
          sx={{ minWidth: "1500px", border: 0 }}
        />
      </Box> */}
      {rows.length === 0 ? (
        <p>No Inquiry Found</p>
      ) : (
        <Box sx={{ width: "100%", overflowX: "auto" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{ pagination: { paginationModel } }}
            pageSizeOptions={[5, 10]}
            getRowHeight={() => "auto"}
            sx={{ minWidth: "1500px", border: 0 }}
          />
        </Box>
      )}
    </Paper>
  );
};

export default ConsultancyLeadTable;
