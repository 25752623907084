import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { Button } from "@mui/material";
import React, { useState } from "react";
import { MdDelete } from "react-icons/md";
import { DELETE_INQ_FILE } from "~/graphql/inq_file/Mutation";
import { GET_INQ_FILES } from "~/graphql/inq_file/Query";
import { toast } from "react-toastify";

import { useNhostClient } from "@nhost/react";
import ConfirmPopup from "~/components/common/ConfirmPopup";

const ShowFiles = ({ inqId }: { inqId: string }) => {
  const nhost = useNhostClient();
  const client = useApolloClient();
  const { loading, error, data, refetch } = useQuery(GET_INQ_FILES, {
    variables: { inqID: inqId },
  });

  const [deleteInqFile] = useMutation(DELETE_INQ_FILE, {
    onCompleted: (data) => {
      if (data.delete_inq_files.affected_rows > 0) {
        toast.success("File deleted successfully!");
        refetch();
      } else {
        toast.error("Failed to delete the file.");
      }
    },
  });

  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [fileToDelete, setFileToDelete] = useState<{
    id: string;
    fileId: string;
  } | null>(null);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading files.</p>;

  const handleDelete = async () => {
    if (!fileToDelete) return;
    try {
      const { error } = await nhost.storage.delete({
        fileId: fileToDelete.fileId,
      });
      if (error) {
        toast.error("Failed to delete the file from storage.");
        return;
      }
      deleteInqFile({ variables: { id: fileToDelete.id, inqID: inqId } });
      await client.refetchQueries({
        include: "all",
      });
    } catch (error) {
      console.error("Error deleting file:", error);
      toast.error("Failed to delete the file.");
    } finally {
      setDeleteConfirm(false);
      setFileToDelete(null);
    }
  };

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-5">
        {data?.inq_files?.map((file: any) => (
          <div
            key={file.id}
            className="bg-secondary dark:bg-dark-secondary text-primary dark:text-dark-primary shadow rounded-lg w-full"
          >
            <div className="min-h-[120px] p-5 flex justify-between flex-col items-end">
              <div className="flex justify-end gap-3">
                <MdDelete
                  className="text-basic cursor-pointer dark:text-dark-primary h-6 w-6"
                  onClick={() => {
                    setFileToDelete({ id: file.id, fileId: file.file_id });
                    setDeleteConfirm(true);
                  }}
                />
              </div>
              <span className="text-base font-bold leading-[130%] w-full line-clamp-1">
                {file?.file_info?.name?.split(".").slice(0, -1).join(".")}
              </span>
            </div>

            <div className="self-stretch p-5 flex flex-row items-center justify-between rounded-b-lg gap-[10px] text-base bg-basic text-white">
              <span className="bg-white text-basic px-2 font-medium rounded-2xl">
                {file?.file_info?.name?.split(".").pop()?.toUpperCase()}
              </span>
              <a
                href={`${process.env.REACT_APP_NHOST_STORAGE_URL}${file.file_id}`}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-white text-basic px-2 font-medium rounded-2xl"
              >
                View File
              </a>
            </div>
          </div>
        ))}
      </div>

      {deleteConfirm && (
        <ConfirmPopup
          closeModal={() => setDeleteConfirm(false)}
          confirm={handleDelete}
          headingText="Do you want to delete this file?"
        />
      )}
    </div>
  );
};

export default ShowFiles;
