import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useForm, Controller } from "react-hook-form";
import { Button, TextField, Select, MenuItem, FormControl, InputLabel, FormHelperText, FormControlLabel, Switch } from "@mui/material";
import { toast } from "react-toastify";
// import { UPDATE_CONS_FAQ } from "~/graphql/cons_faqs/Mutation";
import { GET_CONSULTANCY_FAQS } from "~/graphql/cons_faqs/Query";
import { GET_UNIVERSITIES_WITH_BRD_ID } from "~/graphql/cons_university/Query";
import { UPDATE_CONS_FAQS } from "~/graphql/cons_faqs/Mutation";

const EditFAQS = ({ selectedFAQ, brdId, setSidePopup }: any) => {
  console.log("data" ,selectedFAQ )
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      title: "",
      description: "",
      featured: false,
      university_id: "",
    },
  });

  const [loading, setLoading] = useState(false);

  // Fetch universities
  const { data: universityData, loading: universityLoading } = useQuery(GET_UNIVERSITIES_WITH_BRD_ID, {
    variables: { brd_id: brdId },
    fetchPolicy: "network-only",
  });

  // Ensure previous values load into the form
  useEffect(() => {
    if (selectedFAQ) {
      setValue("title", selectedFAQ.title || "");
      setValue("description", selectedFAQ.description || "");
      setValue("featured", selectedFAQ.featured || false);
      setValue("university_id", selectedFAQ.university_id || "");
    }
  }, [selectedFAQ, setValue]);

  // Mutation for updating FAQ
  const [updateFAQ] = useMutation(UPDATE_CONS_FAQS, {
    onCompleted: () => {
      toast.success("FAQ updated successfully!");
      setSidePopup(false);
    },
    onError: (error) => {
      toast.error(`Error: ${error.message}`);
    },
    refetchQueries: [{ query: GET_CONSULTANCY_FAQS, variables: { brdId } }],
  });

  // Handle form submission
  const onSubmit = async (data: any) => {
    if (!data.university_id) {
      toast.error("Please select a university.");
      return;
    }

    setLoading(true);
    try {
      await updateFAQ({
        variables: {
          id: selectedFAQ.id, // Ensure FAQ ID is passed
          data: {
            brd_id: brdId,
            title: data.title,
            description: data.description,
            featured: data.featured,
            university_id: data.university_id, // Ensure university ID is included
          },
        },
      });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Edit FAQ
      </h1>

      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        {/* Title Input */}
        <Controller
          name="title"
          control={control}
          rules={{ required: "Title is required" }}
          render={({ field }) => (
            <TextField {...field} label="Title" variant="outlined" fullWidth />
          )}
        />

        {/* Description Input */}
        <Controller
          name="description"
          control={control}
          rules={{ required: "Description is required" }}
          render={({ field }) => (
            <TextField {...field} label="Description" variant="outlined" fullWidth multiline rows={4} />
          )}
        />

        {/* University Dropdown */}
        <FormControl fullWidth variant="outlined">
          <InputLabel id="university-label">University</InputLabel>
          <Controller
            name="university_id"
            control={control}
            render={({ field }) => (
              <Select {...field} labelId="university-label" label="University">
                {universityLoading ? (
                  <MenuItem disabled>Loading...</MenuItem>
                ) : universityData?.cons_university?.length > 0 ? (
                  universityData.cons_university.map((uni: { id: string; name: string }) => (
                    <MenuItem key={uni.id} value={uni.id}>
                      {uni.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No universities found</MenuItem>
                )}
              </Select>
            )}
          />
        </FormControl>

        {/* Featured Toggle */}
        <Controller
          name="featured"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={<Switch {...field} checked={field.value} onChange={(e) => field.onChange(e.target.checked)} color="primary" />}
              label="Featured"
            />
          )}
        />

        {/* Submit Button */}
        <Button type="submit" variant="contained" color="primary" disabled={loading}>
          {loading ? "Updating..." : "Update FAQ"}
        </Button>
      </form>
    </div>
  );
};

export default EditFAQS;
