import add from "../../../../assets/img/Add.svg";
import addDark from "../../../../assets/img/Add-dark.svg";
import priceBreakDown from "../../../../assets/img/PriceBreakdown.svg";
import priceBreakDownDark from "../../../../assets/img/PriceBreakdown-dark.svg";
import edit from "../../../../assets/img/Edit.svg";
import editDark from "../../../../assets/img/Edit-dark.svg";
import remove from "../../../../assets/img/Delete.svg";
import removeDark from "../../../../assets/img/Delete-dark.svg";
import {
  CAR_DELETE_SUGGESTION,
  DELETE_SUGGESTION,
  STAY_DELETE_SUGGESTION,
  TRAIN_DELETE_SUGGESTION,
  VISA_DELETE_SUGGESTION,
} from "../SuggestionBox/graphql/Mutation";
import { useMutation, useApolloClient } from "@apollo/client";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import { toast } from "react-toastify";
import { useState } from "react";
import AddSuggestion from "../AddSuggestion";
import EditSuggestion from "../SuggestionBox/components/EditSuggestion";
import CostBreakDown from "../SuggestionBox/components/CostBreakDown";
import SelectButton from "../SuggestionBox/components/SelectButton";
import { useEffect } from "react";
import AllSugesstionForms from "./components/AllSugesstionForms";

export default function SuggestionNav({
  suggestions,
  active,
  setActive,
  inquiry,
  tab,
}: any) {
  const [wait, setWait] = useState(false);
  const client = useApolloClient();
  const userRole = useUserDefaultRole();
  const user_id = useUserId();
  const userDefaultRole = useUserDefaultRole();
  const userLoggedIn = useUserId();
  const [showAddModal, setShowAddModal] = useState<any>(false);
  const [showEditModal, setShowEditModal] = useState<any>(false);
  const [showCostBreakdownModal, setShowCostBreakdownModal] =
    useState<any>(false);

  const [darkMode, setDarkMode] = useState(false);
  const [deleteStaySuggestion] = useMutation(STAY_DELETE_SUGGESTION);
  const [deleteCarSuggestion] = useMutation(CAR_DELETE_SUGGESTION);
  const [deleteTrainSuggestion] = useMutation(TRAIN_DELETE_SUGGESTION);
  const [deleteDefaultSuggestion] = useMutation(DELETE_SUGGESTION);
  const [deleteVisaSuggestion] = useMutation(VISA_DELETE_SUGGESTION);

  useEffect(() => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);

    const handleStorageChange = () => {
      const updatedData = localStorage.getItem("darkMode");
      setDarkMode(updatedData ? JSON.parse(updatedData) : false);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const getSelectedSuggestionId = (inquiry: any, tab: any) => {
    switch (tab) {
      case "Hotel":
        return inquiry?.stay_inq_details?.map(
          (detail: any) => detail?.selected_stay_suggestion_id
        ); // Replace with actual field for stays
      case "Cars":
        return inquiry?.car_inq_details[0]?.selected_car_suggestion_id; // Replace with actual field for cars
      case "Train":
        return inquiry?.train_inq_details[0]?.selected_train_suggestion_id; // Replace with actual field for trains
      case "Visa":
        return inquiry?.visa_inq_details[0]?.selected_visa_suggestion_id;
      case "Flights":
      default:
        return inquiry?.suggestion_id; // Default suggestion ID
    }
  };

  const removeSuggestion = async (id: string) => {
    setWait(true);

    try {
      let res: any = null;

      // Use useMutation hook for each mutation

      // Map each tab to its corresponding mutation function
      const mutationMap: { [key: string]: any } = {
        Hotel: deleteStaySuggestion,
        Cars: deleteCarSuggestion,
        Train: deleteTrainSuggestion,
        Visa: deleteVisaSuggestion,
        Default: deleteDefaultSuggestion, // Default mutation for unspecified tabs
      };

      // Select the mutation based on the tab label
      const selectedMutation = mutationMap[tab] || mutationMap["Default"];

      // Dynamically execute the selected mutation
      res = await selectedMutation({
        variables: { id },
      });
      // Parse the result based on the tab
      if (tab === "Hotel" && res?.data?.delete_stay_inq_suggestion_by_pk?.id) {
        toast.success("Hotel suggestion deleted successfully!");
      } else if (
        tab === "Cars" &&
        res?.data?.delete_car_inq_suggestion_by_pk?.id
      ) {
        toast.success("Car suggestion deleted successfully!");
      } else if (
        tab === "Train" &&
        res?.data?.delete_train_inq_suggestion_by_pk?.id
      ) {
        toast.success("Train suggestion deleted successfully!");
      } else if (
        tab === "Visa" &&
        res?.data?.delete_visa_inq_suggestion_by_pk?.id
      ) {
        toast.success("Visa suggestion deleted successfully!");
      } else if (res?.data?.delete_inq_suggestion_by_pk?.id) {
        toast.success("Suggestion deleted successfully!");
      } else {
        toast.error("Something went wrong!");
      }

      // Optional: Reset Apollo client store after mutation
      await client.resetStore();
    } catch (error) {
      toast.error("An error occurred while removing the suggestion.");
    } finally {
      setWait(false);
    }
  };

  const activeSuggestion = suggestions.find((sg: any) => sg.id === active);

  return (
    <div
      className={`self-stretch flex flex-row py-2 px-0 items-center gap-[10px] bg-white dark:bg-dark-secondary ${
        suggestions.length
          ? `rounded-t-2xl dark:bg-dark-secondary justify-start pt-4 pb-[5px]`
          : `rounded-lg justify-between`
      } shadow px-4 `}
    >
      {suggestions.length > 0 ? (
        <div
          className={`flex-1 flex flex-row items-center justify-start gap-[10px]`}
        >
          {suggestions.map((sg: any, i: any) => (
            <>
              <>
                <div
                  key={i}
                  onClick={() => {
                    setActive(sg?.id);
                  }}
                  className={`cursor-pointer flex flex-row py-0 pr-2.5 pl-0 items-center justify-start ${
                    active === sg.id
                      ? `text-white rounded-3xl py-1 bg-basic`
                      : `text-basic dark:text-dark-primary`
                  }`}
                >
                  <div className="flex flex-row py-0 pr-0 pl-2.5 items-start justify-start">
                    <b className="relative leading-[20px]">
                      Suggestion # {i + 1}
                    </b>
                  </div>
                </div>
              </>
            </>
          ))}
        </div>
      ) : (
        <div className="text-basic dark:text-dark-primary font-bold capitalize">
          Suggestion
        </div>
      )}
      <div className="flex gap-3">
        {inquiry?.status !== "completed" && (
          <>
            {suggestions.length > 0 && (
              <>
                <SelectButton
                  selected={
                    inquiry?.suggestion_id === activeSuggestion?.id
                      ? true
                      : false
                  }
                  brand_domain={inquiry?.brd_list?.domain}
                  suggestion={activeSuggestion}
                  inquiry={inquiry}
                  inquiry_id={inquiry?.id}
                  user_email={inquiry?.users?.email}
                  brand_url={inquiry?.brd_list?.subdomain}
                  brand_name={inquiry?.brd_list?.name}
                  selected_suggestion_id={getSelectedSuggestionId(inquiry, tab)}
                  picked_by={inquiry?.picked_by}
                  user_id={inquiry?.users?.id}
                  tab={tab}
                  activeSuggestion={activeSuggestion}
                />
                <img
                  src={darkMode ? priceBreakDownDark : priceBreakDown}
                  alt=""
                  className="cursor-pointer"
                  title="Price Breakdown"
                  onClick={() => setShowCostBreakdownModal(true)}
                />
                {(userLoggedIn === inquiry?.picked_by ||
                  userRole === "admin") && (
                  <img
                    src={darkMode ? editDark : edit}
                    alt=""
                    title="Edit Suggestion"
                    className="cursor-pointer"
                    onClick={() => {
                      setShowEditModal(!showEditModal);
                    }}
                  />
                )}
              </>
            )}
          </>
        )}
        {inquiry.status !== "completed" &&
          (user_id === inquiry?.picked_by || userDefaultRole === "admin") &&
          inquiry?.status !== "refunded" &&
          inquiry?.status !== "completed" && (
            <img
              src={darkMode ? addDark : add}
              alt=""
              title="Add Suggestion"
              className="cursor-pointer"
              onClick={() => setShowAddModal(!showAddModal)}
            />
          )}
        {!inquiry?.invoice_id &&
          suggestions.length > 0 &&
          (userLoggedIn === inquiry?.picked_by || userRole === "admin") && (
            <>
              {!(
                inquiry?.suggestion_id === activeSuggestion?.id ||
                inquiry?.stay_inq_details?.some(
                  (stayDetail: any) =>
                    stayDetail.selected_stay_suggestion_id ===
                    activeSuggestion?.id
                ) ||
                inquiry?.car_inq_details?.[0]?.selected_car_suggestion_id ===
                  activeSuggestion?.id ||
                inquiry?.train_inq_details?.[0]
                  ?.selected_train_suggestion_id === activeSuggestion?.id ||
                inquiry?.visa_inq_details?.[0]?.selected_visa_suggestion_id ===
                  activeSuggestion?.id
              ) && (
                <img
                  src={darkMode ? removeDark : remove}
                  alt=""
                  className="cursor-pointer"
                  title="Delete Suggestion"
                  onClick={() => removeSuggestion(active)}
                />
              )}
            </>
          )}
      </div>
      {showAddModal && (
        <AllSugesstionForms
          tab={tab}
          inquiry={inquiry}
          setShowAddModal={setShowAddModal}
          setActive={setActive}
          passengers={inquiry?.inq_passengers}
          stayRooms={
            inquiry?.stay_inq_details?.flatMap(
              (detail: any) => detail.stay_inq_rooms
            ) || []
          }
          carPassengers={inquiry?.car_inq_details[0]?.car_inq_passengers}
        />
        // <AddSuggestion
        //   setShowAddModal={setShowAddModal}
        //   inquiry_id={inquiry.id}
        //   inquiry={inquiry}
        //   brand_name={inquiry.brd_list.name}
        //   brand_url={inquiry.brd_list.subdomain}
        //   brand_domain={inquiry.brd_list.domain}
        //   passengers={inquiry?.inq_passengers}
        //   arrival={inquiry?.to}
        //   user_id={inquiry?.users?.id}
        //   user_email={inquiry?.users?.email}
        //   user_phone={inquiry?.users?.phoneNumber}
        //   setActive={setActive}
        //   tab={tab}
        // />
      )}

      {showEditModal && // Use && to conditionally render if showEditModal is true
        activeSuggestion && (
          <EditSuggestion
            suggestions={activeSuggestion || []} // Make sure you pass the specific suggestion here
            setShowAddModal={setShowEditModal}
            inquiry={inquiry}
            inquiry_id={inquiry.id}
            passengers={inquiry?.inq_passengers}
            arrival={inquiry?.to}
            tabLabel={tab}
            user_id={inquiry.users.id}
            stayRooms={inquiry?.stay_inq_details[0]?.stay_inq_rooms}
            trainPassengers={inquiry?.inq_passengers}
            carPassengers={inquiry?.car_inq_details[0]?.car_inq_passengers}
          />
        )}

      {showCostBreakdownModal && (
        <CostBreakDown
          tabLabel={tab}
          staySuggestionCost={activeSuggestion.stay_suggestion_rooms}
          visa_inq_suggestion_cost={activeSuggestion.visa_inq_suggestion_costs}
          car_inq_suggestion_cost={activeSuggestion.car_inq_suggestion_costs}
          train_inq_suggestion_cost={
            activeSuggestion.train_inq_suggestion_costs
          }
          inq_suggestion_cost={activeSuggestion.inq_suggestion_costs}
          setShowCostBreakdownModal={setShowCostBreakdownModal}
        />
      )}
    </div>
  );
}
