import Layout from "~/components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import Campuses from "~/modules/BrandModule/segments/Countries/components/Campuses";


const CampusPage  = () => {
  return (
    <div>
      <Layout>
        <BrandModule>
          <Campuses/>
        </BrandModule>
      </Layout>
    </div>
  );
};

export default CampusPage;