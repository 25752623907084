import Layout from "~/components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import Programs from '~/modules/BrandModule/segments/Countries/components/Programs';

const ProgramsPage = () => {
  return (
    <div>
    <Layout>
      <BrandModule>
       <Programs />
      </BrandModule>
    </Layout>
  </div>
  )
}

export default ProgramsPage



