import { gql } from "@apollo/client";

export const GET_CONS_COUNTRIES = gql`
  query MyQuery($brdId: uuid!) {
    cons_country(where: { brd_id: { _eq: $brdId } }) {
      slug
      id
      name
    }
  }
`;
