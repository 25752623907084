import { useMutation } from "@apollo/client";
import { Button, TextField } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { UPDATE_BRD_CONTACT_DETAIL } from "~/graphql/cons_contact_info/Mutation";
// import { UPDATE_CONTACT_INFO } from "~/graphql/cons_contact_info/Mutation";
import { GET_CONTACT_INFO } from "~/graphql/cons_contact_info/Query";

function EditConsContactInfo({ selectedContact, setSidePopup, brdId }: any) {
  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    if (selectedContact) {
      reset(selectedContact); // Prefill form with existing data
    }
  }, [selectedContact, reset]);

  const [updateContactInfo, { loading }] = useMutation(
    UPDATE_BRD_CONTACT_DETAIL,
    {
      refetchQueries: [
        {
          query: GET_CONTACT_INFO,
          variables: { brdId },
        },
      ],
      awaitRefetchQueries: true,
    }
  );
  console.log("selectedId", typeof selectedContact?.id);

  //   console.log("res" , response)
  const onSubmit = async (formData: any) => {
    if (!selectedContact?.id) {
      toast.error("Contact ID is missing!");
      return;
    }

    try {
      console.log("Data being sent:", {
        variables: {
          id: selectedContact?.id,
          data: {
            address1: formData.address1,
            city: formData.city,
            country: formData.country,
            email: formData.email,
            office_close: formData.office_close,
            office_open: formData.office_open,
            phone: formData.phone,
            whatsapp: formData.whatsapp,
          },
        },
      });
      const response = await updateContactInfo({
        variables: {
          id: selectedContact?.id,
          data: {
            address1: formData.address1,
            city: formData.city,
            country: formData.country,
            email: formData.email,
            office_close: formData.office_close,
            office_open: formData.office_open,
            phone: formData.phone,
            whatsapp: formData.whatsapp,
          },
        },
      });

      if (response.data.update_brd_contact_detail.affected_rows > 0) {
        toast.success("Contact info updated successfully!");
        setSidePopup(false); // Close popup after update
      } else {
        toast.warning("No changes were made.");
      }
    } catch (err) {
      console.error("Error updating contact:", err);
      toast.error("Failed to update contact.");
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Edit Consultancy Contact Info
      </h1>

      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <TextField
          label="Address"
          variant="outlined"
          fullWidth
          {...register("address1")}
        />
        <TextField
          label="City"
          variant="outlined"
          fullWidth
          {...register("city")}
        />
        <TextField
          label="Country"
          variant="outlined"
          fullWidth
          {...register("country")}
        />
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          {...register("email")}
        />
        <TextField
          label="Phone"
          variant="outlined"
          fullWidth
          {...register("phone")}
        />
        <TextField
          label="WhatsApp"
          variant="outlined"
          fullWidth
          {...register("whatsapp")}
        />
        <TextField
          label="Office Open Time"
          variant="outlined"
          fullWidth
          {...register("office_open")}
        />
        <TextField
          label="Office Close Time"
          variant="outlined"
          fullWidth
          {...register("office_close")}
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? "Updating..." : "Update"}
        </Button>
      </form>
    </div>
  );
}

export default EditConsContactInfo;
