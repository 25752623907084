import React, { useState } from "react";
import { useApolloClient, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { INSERT_INQ_FILES } from "../../../../../../../../graphql/inq_file/Mutation";
import { useNhostClient } from "@nhost/react";
import { Button, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const AddFiles = ({
  inqId,
  setSidePopup,
}: {
  inqId: string;
  setSidePopup: () => void;
}) => {
  const nhost = useNhostClient();

  const client = useApolloClient();
  const [files, setFiles] = useState<File[]>([]);
  const [insertInqFiles] = useMutation(INSERT_INQ_FILES);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFiles((prevFiles) => [
        ...prevFiles,
        ...Array.from(event.target.files || []),
      ]);
    }
  };

  const handleUpload = async () => {
    let success = false;
    let errorOccurred = false;

    for (const file of files) {
      try {
        const { fileMetadata, error } = await nhost.storage.upload({ file });

        if (error) {
          console.error("Error uploading file:", file.name, error);
          errorOccurred = true;
          continue;
        }

        const fileType = file.type.startsWith("image/") ? "image" : file.type;

        const { data } = await insertInqFiles({
          variables: {
            data: {
              file_id: fileMetadata.id,
              file_type: file.type,
              inq_id: inqId,
            },
          },
        });

        console.log("Inserted rows:", data?.insert_inq_files?.affected_rows);
        if (data?.insert_inq_files?.affected_rows > 0) {
          success = true;
        } else {
          errorOccurred = true;
        }
      } catch (error) {
        console.error("Error uploading file:", file.name, error);
        errorOccurred = true;
      }
    }

    setFiles([]);
    if (success) {
      toast.success("Files added successfully!");
      setSidePopup();
      await client.refetchQueries({
        include: "all",
      });
    }
    if (errorOccurred) {
      toast.error("Some files failed to upload.");
      setSidePopup(); // Close popup after error toast
    }
  };

  const handleRemoveFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <div className="w-[500px] h-full overflow-y-scroll bg-primary dark:bg-dark-primary p-5">
      <Typography
        variant="h5"
        className="text-center text-basic dark:text-dark-primary"
      >
        Add Files
      </Typography>

      <input
        type="file"
        multiple
        onChange={handleFileChange}
        className="my-3"
      />

      {files.length > 0 && (
        <div className="grid grid-cols-2 gap-4">
          {files.map((file, index) => (
            <div key={index} className="relative w-[200px] h-[200px]">
              <img
                src={URL.createObjectURL(file)}
                alt={file.name}
                className="w-[200px] h-[200px] object-cover"
              />
              <div
                onClick={() => handleRemoveFile(index)}
                className="absolute -top-2 bg-purple-500 flex items-center justify-center rounded-full text-sm font-bold w-5 h-5 text-white -right-2 cursor-pointer"
              >
                <CloseIcon className="text-sm" />
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="mt-3">
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpload}
          disabled={files.length === 0}
        >
          Upload Files
        </Button>
      </div>
    </div>
  );
};

export default AddFiles;
