import { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { GET_CONSULTANCY_FAQS } from "~/graphql/cons_faqs/Query";
import { DELETE_FAQS } from "~/graphql/cons_faqs/Mutation";
import SideDrawer from "~/components/common/sideDrawer";
import EditConsFAQS from "../EditConsFAQS";

const ShowConsFAQS = ({ brdId }: any) => {
  const [fetchFAQs, { data, loading, error }] = useLazyQuery(GET_CONSULTANCY_FAQS);
  const [deleteFAQ] = useMutation(DELETE_FAQS);
  const [faqs, setFaqs] = useState<any[]>([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedFAQ, setSelectedFAQ] = useState<any | null>(null);
  const client = useApolloClient();

  // Fetch FAQs when brdId changes
  useEffect(() => {
    if (brdId) {
      fetchFAQs({ variables: { brdId } });
    }
  }, [brdId, fetchFAQs]);

  // Update state when data is fetched
  useEffect(() => {
    if (data?.cons_faqs) {
      setFaqs(data.cons_faqs);
    }
  }, [data]);

  // Handle edit button click
  const handleEdit = (faq: any) => {
    setSelectedFAQ(faq);
    setOpenPopup(true);
  };

  // Handle delete functionality
  const handleDelete = async (id: any) => {
    try {
      const { data } = await deleteFAQ({
        variables: { id },
        refetchQueries: [
          {
            query: GET_CONSULTANCY_FAQS,
            variables: { brdId },
          },
        ],
      });

      if (data?.delete_cons_faqs?.affected_rows > 0) {
        toast.success("FAQ deleted successfully!");
        // Manually update the state to reflect the deletion
        setFaqs((prev) => prev.filter((faq) => faq.id !== id));
      } else {
        toast.warning("No FAQ was deleted.");
      }
    } catch (error) {
      toast.error("Failed to delete FAQ!");
      console.error("Error deleting FAQ:", error);
    }
  };

  if (loading) return <p>Loading FAQs...</p>;
  if (error) return <p>Error loading FAQs</p>;

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {faqs.map((faq) => (
              <TableRow key={faq.id}>
                <TableCell>{faq.title || "NA"}</TableCell>
                <TableCell>{faq.description || "NA"}</TableCell>
                <TableCell>
                  <div className="flex gap-3">
                    <Button onClick={() => handleEdit(faq)}>
                      <FaEdit size={18} />
                    </Button>
                    <Button onClick={() => handleDelete(faq.id)}>
                      <FaTrash size={18} />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {openPopup && selectedFAQ && (
        <SideDrawer
          component={
            <EditConsFAQS
              selectedFAQ={selectedFAQ}
              setSidePopup={setOpenPopup}
              brdId={brdId}
            />
          }
          sidePopup={openPopup}
          setSidePopup={setOpenPopup}
        />
      )}
    </>
  );
};

export default ShowConsFAQS;