import { Button } from '@mui/material';
import React, { useState } from 'react'
import ShowConsTeamMembers from './ShowConsTeamMembers';
import SideDrawer from '~/components/common/sideDrawer';
import AddConsTeamMembers from './AddConsTeamMembers';

function ConsTeamMembers({brdId} :any) {
    const [openPopup, setOpenPopup] = useState(false);
  return (
    <div className="bg-secondary dark:bg-dark-secondary rounded-lg  p-5 mb-5">
    <div className="flex justify-between items-center mb-4">
      <div className="flex items-center justify-center h-full ">
        <h1 className="text-xl flex items-center justify-center">
          Consultancy Team Members
        </h1>
      </div>

      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpenPopup(true)}
      >
        Add Consultancy Team Members 
      </Button>
    </div>
    <ShowConsTeamMembers brdId={brdId} />
    <SideDrawer
      component={
        <AddConsTeamMembers brdId={brdId}  setSidePopup={setOpenPopup} />
      }
      sidePopup={openPopup}
      setSidePopup={setOpenPopup}
    />
  </div>
  )
}

export default ConsTeamMembers
