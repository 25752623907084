import React from "react";
import { TextField, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation, useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import { UPDATE_PROGRAMS_REQUIREMENTS } from "~/graphql/cons_programs_requirements/Mutation";

const EditProgramRequirements = ({ selectedRequirement, setSidePopup }: any) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      course_name: selectedRequirement?.course_name || "",
      gap_in_months: selectedRequirement?.gap_in_months || "",
    },
  });

  const [updateRequirement, { loading }] = useMutation(UPDATE_PROGRAMS_REQUIREMENTS);
  const client = useApolloClient();

  const onSubmit = async (formData: any) => {
    if (!selectedRequirement?.id) {
      toast.error("Invalid requirement data: ID is missing");
      return;
    }

    try {
      const { data } = await updateRequirement({
        variables: {
          id: selectedRequirement.id,
          data: {
            course_name: formData.course_name,
            gap_in_months: parseInt(formData.gap_in_months),
          },
        },
      });

      if (data?.update_cons_programs_requirements?.affected_rows > 0) {
        toast.success("Requirement updated successfully!");
        client.refetchQueries({ include: "all" });
        setSidePopup(false);
      } else {
        toast.error("Update failed.");
      }
    } catch (error) {
      console.error("Error updating requirement:", error);
      toast.error("Failed to update requirement.");
    }
  };

  return (
    <div className="max-w-[500px] w-[1000px] h-full py-10 px-5 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-2xl text-basic dark:text-dark-primary mb-8">
        Edit Program Requirement
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Course Name"
          variant="outlined"
          required
          fullWidth
          {...register("course_name")}
          style={{ marginBottom: "16px" }}
        />
        <TextField
          label="Gap in Months"
          variant="outlined"
          required
          type="number"
          fullWidth
          {...register("gap_in_months")}
          style={{ marginBottom: "16px" }}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
          {loading ? "Updating..." : "Update Requirement"}
        </Button>
      </form>
    </div>
  );
};

export default EditProgramRequirements;
