// Import react router v6
import { Routes, Route } from "react-router-dom";
// Import protected route
import { ProtectedRoute } from "./ProtectedRoute";

import Brands from "~/pages/brands/Brands";
import EditBrands from "~/pages/brands/EditBrand";
import EditFAQs from "~/pages/brands/EditFAQs";
import EditTestimonials from "~/pages/brands/EditTestimonials";
import PageSettings from "~/pages/brands/PageSettings";
import BrandDestinationPage from "~/pages/brands/BrandDestinationPage";
import BrandDestinationAddPage from "~/pages/brands/BrandDestinationAddPage";
import BrandDestinationUpdatePage from "~/pages/brands/BrandDestinationUpdatePage";
import AddBrandPage from "~/pages/brands/AddBrandPage";
import BrandPostsPage from "~/pages/brands/BrandPostsPage";
import BrandPostsUpdatePage from "~/pages/brands/BrandPostsUpdatePage";
import BrandPostsAddPage from "~/pages/brands/BrandPostsAddPage";
import BrandEmailTemplatePage from "~/pages/brands/BrandEmailTemplatePage";
import AnalyticsPage from "~/pages/brands/AnalyticsPage";
import BrandAirlinesPage from "~/pages/admin/BrandAirlinesPage";
import BrandAirlinesAddPage from "~/pages/admin/BrandAirlinesAddPage";
import BrandAirlinesUpdatePage from "~/pages/admin/BrandAirlinesUpdatePage";
import WhatsappPage from "~/pages/brands/WhatsappPage";
import InAppAlertsTemplatesPage from "~/pages/brands/InAppAlertsTemplatesPage";
import WhatsappTemplatesPage from "~/pages/brands/WhatsappTemplatesPage";
import ImportFlightPages from "~/pages/brands/ImportFlightPages";
import AutoBotMessagesPage from "~/pages/brands/AutoBotMessagesPage";
import EmailSettingPage from "~/pages/brands/EmailSettingPage";
import CustomerTimelinePage from "~/pages/brands/CustomerTimelinePage";
import BrdSeasonalFares from "~/pages/brands/BrdSeasonalFares";
import SocialConnectPage from "~/pages/brands/SocialConnectPage";
import UniversitiesPage from "~/pages/brands/UniversitiesPage";
import CampusCoursesPage from "~/pages/brands/CampusCoursesPage";
import CampusesPage from "~/pages/brands/CampusesPage";
import AdmissionRequirementsPage from "~/pages/brands/AdmissionRequirementsPage";
import UniCoursesPage from "~/pages/brands/UniCoursesPage";
import BrandPartnersPage from "~/pages/brands/BrandPartnersPage";
import UmrahPackagesPage from "~/pages/brands/UmrahPackagesPage";

import VisasPage from "~/pages/brands/VisasPage";
import ActivateSkillsPage from "~/pages/brands/ActivateSkillsPage";
import ActivateCoutriesPage from "~/pages/brands/ActivateCoutriesPage";
import HotelPage from "~/pages/brands/HotelPage";
import BrandPages from "~/pages/brands/BrandPages";
import UmrahPackageUpdatePg from "~/pages/brands/UmrahPackageUpdatePg";
import UmrahPackageAddPg from "~/pages/brands/UmrahPackageAddPg";
import CountryPage from "~/pages/brands/CountryPage";
import UniversityPage from "~/pages/brands/UniversityPage";
import CampusPage from "~/pages/brands/CampusPage";
import DepartmentsPage from "~/pages/brands/DepartmentPage";
import ProgramsPage from "~/pages/brands/ProgramsPage";
import IntakesPage from "~/pages/brands/IntakesPage";
import ConsultancyBrandPages from "~/pages/brands/ConsultancyBrandPages";
import ProgramRequirementsPage from "~/pages/brands/ProgramsRequirementsPage";
import ConsultancyPostPage from "~/pages/brands/ConsultancyPostsPage";
import AddConsultancyPost from "~/modules/BrandModule/segments/AddConsultacyBlog";
import EditConsultancyBlogs from "~/modules/BrandModule/segments/ConsultancyPost/component/EditConsultancyBlogs";
import AddConsultancyBlog from "~/modules/BrandModule/segments/AddConsultacyBlog";
import AddConsultancyBlogsPage from "~/pages/brands/AddConsultancyBlogsPage";
import EditConsultancyBlogsPage from "~/pages/brands/EditConsultancyBlogPage";
import EditUniversitiesPage from "~/pages/brands/EditUniversitiesPage";
import EditConsultancyCampusPage from "~/pages/brands/EditConsultancyCampusPage";
import PointOfContactPage from "~/pages/brands/PointOfContactPage";


import ManageDesignationPage from "~/pages/brands/ManageDesignationPage";

export default function BrandsRoutes() {
  return (
    <Routes>
      <Route path="/admin/brands" element={<ProtectedRoute role="admin" />}>
        <Route index element={<Brands />} />
        <Route path="add" element={<AddBrandPage />} />
        <Route path=":brd_id/edit" element={<EditBrands />} />
        <Route
          path=":brd_id/manage-designation"
          element={<ManageDesignationPage />}
        />
        <Route path=":brd_id/faqs" element={<EditFAQs />} />

        {/* <Route
          path=":brd_id/email-templates"
          element={<BrandEmailTemplatePage />}
        /> */}

        <Route
          path=":brd_id/email-templates"
          element={<BrandEmailTemplatePage />}
        />
        <Route path=":brd_id/brand-partners" element={<BrandPartnersPage />} />

        <Route
          path=":brd_id/in-app-alerts-templates"
          element={<InAppAlertsTemplatesPage />}
        />
        {/* <Route
          path=":brd_id/auto-bot-templates"
          element={<InAppAlertsTemplatesPage />}
        /> */}
        <Route
          path=":brd_id/whatsapp-templates"
          element={<WhatsappTemplatesPage />}
        />
        <Route path=":brd_id/brdSeasonalFares" element={<BrdSeasonalFares />} />
        <Route path=":brd_id/testimonials" element={<EditTestimonials />} />

        <Route path=":brd_id/pages" element={<BrandPages />} />
        <Route path=":brd_id/consPages" element={<ConsultancyBrandPages />} />

        <Route path=":brd_id/pages/:slug?" element={<PageSettings />} />

        <Route path=":brd_id/testimonials" element={<EditTestimonials />} />
        <Route path=":brd_id/destinations" element={<BrandDestinationPage />} />
        <Route
          path=":brd_id/destinations/add"
          element={<BrandDestinationAddPage />}
        />
        <Route
          path=":brd_id/destinations/update/:page_id"
          element={<BrandDestinationUpdatePage />}
        />
        <Route
          path=":brd_id/destinations/auto-bot-messages/:page_id"
          element={<AutoBotMessagesPage />}
        />
        <Route path=":brd_id/umrah-packages" element={<UmrahPackagesPage />} />
        <Route
          path=":brd_id/umrah-packages/add"
          element={<UmrahPackageAddPg />}
        />

        <Route
          path=":brd_id/umrah-packages/update/:pkg_Id"
          element={<UmrahPackageUpdatePg />}
        />

        <Route path=":brd_id/hotel" element={<HotelPage />} />
        <Route path=":brd_id/visas" element={<VisasPage />} />
        <Route
          path=":brd_id/import-flight-pages"
          element={<ImportFlightPages />}
        />
        <Route path=":brd_id/airlines" element={<BrandAirlinesPage />} />
        <Route path=":brd_id/airlines/add" element={<BrandAirlinesAddPage />} />
        <Route
          path=":brd_id/airlines/update/:page_id"
          element={<BrandAirlinesUpdatePage />}
        />

        <Route path=":brd_id/posts" element={<BrandPostsPage />} />
        <Route path=":brd_id/cons_posts" element={<ConsultancyPostPage />} />
        <Route
          path=":brd_id/cons_posts/add_cons_post"
          element={<AddConsultancyBlogsPage />}
        />
        <Route
          path=":brd_id/cons_posts/update_cons_post"
          element={<EditConsultancyBlogsPage />}
        />

        <Route path=":brd_id/posts/add" element={<BrandPostsAddPage />} />
        <Route
          path=":brd_id/posts/update/:post_id"
          element={<BrandPostsUpdatePage />}
        />
        <Route path=":brd_id/whatsapp" element={<WhatsappPage />} />
        <Route path=":brd_id/email-setting" element={<EmailSettingPage />} />
        <Route
          path=":brd_id/email-setting/:token"
          element={<EmailSettingPage />}
        />
        <Route
          path=":brd_id/customer/:user_id"
          element={<CustomerTimelinePage />}
        />
        <Route path=":brd_id/social-connect/" element={<SocialConnectPage />} />
        <Route path=":brd_id/universities" element={<UniversitiesPage />} />
        <Route path=":brd_id/countries" element={<CountryPage />} />
        <Route
          path=":brd_id/countries/:country_id/university"
          element={<UniversityPage />}
        />
        <Route
          path=":brd_id/countries/:country_id/university/:university_id"
          element={<EditUniversitiesPage />}
        />
        <Route
          path=":brd_id/countries/university/:university_id/campuses"
          element={<CampusPage />}
        />
        <Route
          path=":brd_id/countries/university/:university_id/campuses/:campus_id"
          element={<EditConsultancyCampusPage />}
        />
  <Route 
     path=":brd_id/countries/university/:university_id/point_of_contact"
     element={<PointOfContactPage />}/>

 
        <Route
          path=":brd_id/countries/university/campuses/:campus_id/departments"
          element={<DepartmentsPage />}
        />
        <Route
          path=":brd_id/countries/university/campuses/departments/:department_id/programs"
          element={<ProgramsPage />}
        />
        <Route
          path=":brd_id/countries/university/campuses/departments/programs/:program_id/intakes"
          element={<IntakesPage />}
        />
        <Route
          path=":brd_id/countries/university/campuses/departments/programs/:program_id/requirements"
          element={<ProgramRequirementsPage />}
        />
        <Route
          path=":brd_id/activate-skills"
          element={<ActivateSkillsPage />}
        />
        <Route
          path=":brd_id/activate-countries"
          element={<ActivateCoutriesPage />}
        />
        <Route
          path="/admin/brands/universities/:uni_id/campuses"
          element={<CampusesPage />}
        />
        <Route
          path="/admin/brands/universities/:uni_id/courses"
          element={<UniCoursesPage />}
        />
        <Route
          path="/admin/brands/universities/:uni_id/campus/:campus_id/courses"
          element={<CampusCoursesPage />}
        />
        <Route
          path="/admin/brands/universities/campus/course/:campus_course_id/admission_requirements"
          element={<AdmissionRequirementsPage />}
        />
      </Route>
    </Routes>
  );
}
