import Layout from "~/components/common/Layout";

import InquiriesModule from "~/modules/InquiriesModule";
import ConsultancyLeads from "~/modules/InquiriesModule/segments/ConsultancyLeads";

const ConsultancyLeadsPage = () => {
  return (
    <Layout>
      <InquiriesModule>
        <ConsultancyLeads />
      </InquiriesModule>
    </Layout>
  );
};

export default ConsultancyLeadsPage;
