import Layout from "../../components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import EditConsultancyBlogs from "~/modules/BrandModule/segments/ConsultancyPost/component/EditConsultancyBlogs";

export default function EditConsultancyBlogsPage() {
  return (
    <div>
      <Layout>
        <BrandModule>
        <EditConsultancyBlogs />
        </BrandModule>
      </Layout>
    </div>
  );
}
