import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  Button,
  FormLabel,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  Switch, 
} from "@mui/material";
import { useMutation, useQuery, useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import { nhost } from "~/lib/nhost";
import { UPDATED_BLOGS } from "~/graphql/cons_blogs/Mutation";
import { GET_CONSULTANCY_BLOGS } from "~/graphql/cons_blogs/Query";
import { GET_UNIVERSITIES_WITH_BRD_ID } from "~/graphql/cons_university/Query";
import ContentEditor from "~/components/common/ContentEditor";

const EditConsultancyBlog = () => {
  const { brd_id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { blog } = location.state || {};

  const client = useApolloClient();
  const { register, handleSubmit, setValue, watch, reset, control } = useForm({
    defaultValues: {
      title: blog?.title || "",
      slug: blog?.slug || "",
      content: blog?.content || "",
      category: blog?.category || "",
      imageId: blog?.image_id || null,
      imageUrl: blog?.image_id
        ? nhost.storage.getPublicUrl({ fileId: blog.image_id })
        : "",
      university_id: blog?.university_id || "",
      featured: blog?.featured || false, // Added featured field
    },
  });

  const { data: universityData, loading: universityLoading } = useQuery(GET_UNIVERSITIES_WITH_BRD_ID, {
    variables: { brd_id: brd_id },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (blog) {
      setValue("title", blog.title);
      setValue("slug", blog.slug);
      setValue("content", blog.content);
      setValue("category", blog.category);
      setValue("imageId", blog.image_id || null);
      setValue(
        "imageUrl",
        blog.image_id
          ? nhost.storage.getPublicUrl({ fileId: blog.image_id })
          : ""
      );
      setValue("university_id", blog.university_id || "");
      setValue("featured", blog.featured || false); // Set featured value
    }
  }, [blog, setValue]);

  const imageUrl = watch("imageUrl");
  const imageId = watch("imageId");
  const content = watch("content");
  const featured = watch("featured"); // Watch featured value

  const [updateBlog, { loading }] = useMutation(UPDATED_BLOGS, {
    onCompleted: () => {
      toast.success("Blog updated successfully!");
      reset();
      navigate(`/admin/brands/${brd_id}/cons_posts`);
    },
    onError: (error) => {
      toast.error(`Error: ${error.message}`);
    },
    refetchQueries: [
      {
        query: GET_CONSULTANCY_BLOGS,
        variables: { brd_id: brd_id },
      },
    ],
  });

  const onSubmit = (data: any) => {
    if (!blog?.id) {
      toast.error("Missing blog ID");
      return;
    }

    const updateData = {
      title: data.title,
      slug: data.slug,
      content: data.content,
      category: data.category,
      image_id: data.imageId || null,
      university_id: data.university_id,
      featured: data.featured, // Include featured status
    };
    updateBlog({
      variables: {
        id: blog.id,
        data: updateData,
      },
    });
  };

  const handleImageRemove = async () => {
    if (imageUrl && imageId) {
      await nhost.storage.delete({ fileId: imageId });
      setValue("imageUrl", "");
      setValue("imageId", null);
    }
  };

  const uploadImage = async (file: File) => {
    const response = await nhost.storage.upload({ file });
    if (response.error) throw new Error(response.error.message);
    const uploadedImageUrl = nhost.storage.getPublicUrl({
      fileId: response.fileMetadata.id,
    });
    setValue("imageId", response.fileMetadata.id);
    setValue("imageUrl", uploadedImageUrl);
  };

  return (
    <div className="bg-secondary dark:bg-dark-secondary rounded-lg shadow p-5 mb-5 text-primary dark:text-dark-primary">
      <h1 className="text-xl font-semibold mb-5">Edit Consultancy Blog</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <TextField
          label="Title"
          variant="outlined"
          fullWidth
          {...register("title")}
          className="bg-primary dark:bg-dark-primary"
        />
        <TextField
          label="Slug"
          variant="outlined"
          fullWidth
          {...register("slug")}
          className="bg-primary dark:bg-dark-primary"
        />
        <TextField
          label="Category"
          variant="outlined"
          fullWidth
          {...register("category")}
          className="bg-primary dark:bg-dark-primary"
        />

        <FormControl fullWidth variant="outlined" className="mt-4">
          <InputLabel id="university-label">University</InputLabel>
          <Controller
            name="university_id"
            control={control}
            defaultValue={blog?.university_id || ""}
            render={({ field }) => (
              <Select {...field} labelId="university-label" label="University">
                {universityLoading ? (
                  <MenuItem disabled>Loading...</MenuItem>
                ) : universityData?.cons_university?.length > 0 ? (
                  universityData.cons_university.map((uni: { id: string; name: string }) => (
                    <MenuItem key={uni.id} value={uni.id}>
                      {uni.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No universities found</MenuItem>
                )}
              </Select>
            )}
          />
        </FormControl>

        {imageUrl && (
          <div className="relative">
            <button
              type="button"
              className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1"
              onClick={handleImageRemove}
            >
              ✕
            </button>
            <img src={imageUrl} alt="Uploaded" className="w-[300px] rounded-lg" />
          </div>
        )}

        <Button
          variant="outlined"
          component="label"
          className="dark:text-dark-primary w-[250px]"
        >
          Upload Thumbnail
          <input
            type="file"
            hidden
            onChange={async (e) => {
              const file = e.target.files?.[0];
              if (file) await uploadImage(file);
            }}
          />
        </Button>

        {/* Featured Switch */}
        <FormControlLabel
          control={
            <Switch
              checked={featured}
              onChange={(e) => setValue("featured", e.target.checked)}
              color="primary"
            />
          }
          label="Featured"
        />

        <div className="col-span-2 mt-5">
          <FormLabel>Blog Content</FormLabel>
          <ContentEditor content={content} setContent={(value: string) => setValue("content", value)} />
        </div>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="mt-5"
          disabled={loading}
        >
          {loading ? "Updating..." : "Update Blog"}
        </Button>
      </form>
    </div>
  );
};

export default EditConsultancyBlog;