import { gql } from "@apollo/client";

export const GET_CONSULTANCY_BLOGS = gql`
  query GetConsultancyBlogs($brd_id: uuid!) {
    cons_blogs(where: { brd_id: { _eq: $brd_id } }) {
      category
      content
      image_id
      slug
      title
      id
      university_id
      featured
    }
  }
`;


export const GET_BLOG_BY_ID = gql`
  query GetBlogById($id: uuid!) {
    cons_blogs(where: { id: { _eq: $id } }) {
      id
      title
      slug
      content
      university_id
      image_id
      category
      brd_id
    }
  }
`;


