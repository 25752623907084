import React from "react";
import ShowCampuses from "./components/ShowCampuses";
import SidePopup from "~/components/common/SidePopup";
import AddCampuses from "./components/AddCampuses";
import { useParams } from "react-router-dom";

const Campuses = () => {
  const { university_id, brd_id } = useParams();
  return (
    <>
      <ShowCampuses universityId={university_id} brdId={brd_id} />
      <SidePopup component={<AddCampuses universityId={university_id} />} />
    </>
  );
};

export default Campuses;
