import React from 'react'
import Layout from "~/components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import Intakes from '~/modules/BrandModule/segments/Countries/components/Intakes';


const IntakesPage = () => {
  return (
    <div>
    <Layout>
      <BrandModule>
       <Intakes/>
      </BrandModule>
    </Layout>
  </div>
  )
}

export default IntakesPage