import React, { useState } from "react";
import { TextField, Button, Paper, Typography } from "@mui/material";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { INSERT_PROGRAMS } from "~/graphql/cons_programs/Mutation";
import { GET_PROGRMS } from "~/graphql/cons_programs/Query";
// import { GET_PROGRAMS } from "~/graphql/cons_programs/Query"; // Import Query for refetch

const AddPrograms = ({ departmentId , setSidePopup }: any) => {
  const [programData, setProgramData] = useState({
    name: "",
    enrollment_criteria: "", // Fixed spelling
    min_marks_req: "",
    slug :""
  });

  const [insertProgram, { loading }] = useMutation(INSERT_PROGRAMS, {
    refetchQueries: [{ query: GET_PROGRMS, variables: { departmentId } }], // Auto-refetch programs
    awaitRefetchQueries: true, // Ensures refetch is completed before moving on
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProgramData({ ...programData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!programData.name || !programData.enrollment_criteria || !programData.min_marks_req) {
      toast.error("All fields are required");
      return;
    }

    try {
      const { data } = await insertProgram({
        variables: {
          data: [
            {
              name: programData.name,
              enrollment_criteria: programData.enrollment_criteria, // Fixed spelling
              min_marks_req: parseInt(programData.min_marks_req),
              department_id: departmentId,
              slug : programData.slug
            },
          ],
        },
      });

      if (data?.insert_cons_program?.affected_rows > 0) {
        toast.success("Program added successfully!");
        setProgramData({ name: "", enrollment_criteria: "", min_marks_req: ""  , slug :""}); // Reset Form
        setSidePopup(false)
      } else {
        toast.error("Failed to add program");
      }
    } catch (error) {
      console.error("Error adding program:", error);
      toast.error("An error occurred while adding the program");
    }
  };

  return (
    <Paper style={{ padding: "20px", maxWidth: "500px", margin: "20px auto" }}>
      <Typography variant="h5" gutterBottom>
        Add New Program
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Program Name"
          name="name"
          value={programData.name}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Enrollment Criteria"
          name="enrollment_criteria"
          value={programData.enrollment_criteria}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Slug"
          name="slug"
          value={programData.slug}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Minimum Marks Required"
          name="min_marks_req"
          type="number"
          value={programData.min_marks_req}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
          {loading ? "Adding..." : "Add Program"}
        </Button>
      </form>
    </Paper>
  );
};

export default AddPrograms;
