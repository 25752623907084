import React, { useState } from "react";
import { TextField, Button, Paper, Typography } from "@mui/material";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { INSERT_PROGRAMS_REQUIREMENTS } from "~/graphql/cons_programs_requirements/Mutation";
import { GET_PROGRAM_REQUIREMENTS } from "~/graphql/cons_programs_requirements/Query";

const AddProgramRequirements = ({ programId }: any) => {
  const [requirementData, setRequirementData] = useState({
    course_name: "",
    gap_in_months: "",
  });

  const [insertRequirement, { loading }] = useMutation(INSERT_PROGRAMS_REQUIREMENTS, {
    refetchQueries: [{ query: GET_PROGRAM_REQUIREMENTS, variables: { programId } }], 
    awaitRefetchQueries: true,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRequirementData({ ...requirementData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!requirementData.course_name || !requirementData.gap_in_months) {
      toast.error("All fields are required");
      return;
    }

    try {
      const { data } = await insertRequirement({
        variables: {
          data: [
            {
              course_name: requirementData.course_name,
              gap_in_months: parseInt(requirementData.gap_in_months),
              cons_programs_id: programId,
            },
          ],
        },
      });

      if (data?.insert_cons_programs_requirements?.affected_rows > 0) {
        toast.success("Requirement added successfully!");
        setRequirementData({ course_name: "", gap_in_months: "" }); 
      } else {
        toast.error("Failed to add requirement");
      }
    } catch (error) {
      console.error("Error adding requirement:", error);
      toast.error("An error occurred while adding the requirement");
    }
  };

  return (
    <Paper style={{ padding: "20px", maxWidth: "500px", margin: "20px auto" }}>
      <Typography variant="h5" gutterBottom>
        Add Program Requirement
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Course Name"
          name="course_name"
          value={requirementData.course_name}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Gap in Months"
          name="gap_in_months"
          type="number"
          value={requirementData.gap_in_months}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
          {loading ? "Adding..." : "Add Requirement"}
        </Button>
      </form>
    </Paper>
  );
};

export default AddProgramRequirements;
