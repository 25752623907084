import React, { useState } from "react";
import { TextField, Button, Paper, Typography } from "@mui/material";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { INSERT_INTAKES } from "~/graphql/cons_intakes/Mutation";
import { GET_INTAKES } from "~/graphql/cons_intakes/Query";

const AddIntakes = ({ programId, setSidePopup }: any) => {
  const [intake, setIntake] = useState({
    name: "",
    scholarship: "",
    total_fee: "",
  });

  const [insertIntake, { loading }] = useMutation(INSERT_INTAKES, {
    refetchQueries: [{ query: GET_INTAKES, variables: { programId } }],
    awaitRefetchQueries: true,
    onCompleted: () => {
      toast.success("Intake added successfully!");
      setIntake({ name: "", scholarship: "", total_fee: "" });
      setSidePopup(false); // Close the popup after successful addition
    },
    onError: (error) => {
      console.error("Error adding intake:", error);
      toast.error("Failed to add intake.");
    },
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIntake({ ...intake, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!intake.name || !intake.scholarship || !intake.total_fee) {
      toast.error("All fields are required!");
      return;
    }

    await insertIntake({
      variables: {
        data: [{ ...intake, program_id: programId }],
      },
    });
  };

  return (
    <Paper elevation={3} className="p-6 w-96 mx-auto">
      <Typography variant="h6" className="mb-4">Add New Intake</Typography>
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <TextField
          label="Intake Name"
          name="name"
          value={intake.name}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextField
          label="Scholarship"
          name="scholarship"
          value={intake.scholarship}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextField
          label="Total Fee"
          name="total_fee"
          value={intake.total_fee}
          onChange={handleChange}
          fullWidth
          required
        />
        <Button type="submit" variant="contained" color="primary" disabled={loading}>
          {loading ? "Adding..." : "Add Intake"}
        </Button>
      </form>
    </Paper>
  );
};

export default AddIntakes;
