import { gql } from "@apollo/client";

export const GET_CONS_TEAM = gql`
  query MyQuery($brdId : uuid!) {
    cons_team(where: { brd_id: { _eq: $brdId } }) {
      id
      name
     designation
     image_id
    }
  }
`;

