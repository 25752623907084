import { Button } from "@mui/material";
import React, { useState } from "react";
import SideDrawer from "~/components/common/sideDrawer";
import AddConsHeadings from "./AddConsHeadings";
import ShowConsHeadings from "./ShowConsHeadings";

const ConsHeadings = ({ brdId }: { brdId: any }) => {
  const [openPopup, setOpenPopup] = useState(false);
  return (
    <div className="bg-secondary dark:bg-dark-secondary rounded-lg  p-5 mb-5">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center justify-center h-full ">
          <h1 className="text-xl flex items-center justify-center">
            Consultancy Heading
          </h1>
        </div>

        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenPopup(true)}
        >
          Add Consultancy Headings
        </Button>
      </div>
      <ShowConsHeadings brdId={brdId} />

      <SideDrawer
        component={
          <AddConsHeadings brdId={brdId} setSidePopup={setOpenPopup} />
        }
        sidePopup={openPopup}
        setSidePopup={setOpenPopup}
      />
    </div>
  );
};

export default ConsHeadings;
