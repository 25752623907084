import { useApolloClient, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { GET_TRANSACTIONS, GET_TRANSACTIONS_WITH_COND } from "./graphql/Query";
import TransactionsTable from "./components/TransactionsTable";
import SidePopup from "~/components/common/SidePopup";
// import TicketRequestRow from "./components/TicketRequestRow";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import AddEquity from "./components/AddEquity/AddEquity";
import AddManual from "./components/AddManual/AddManual";
import AddVendorPayment from "./components/VendorDeposit/AddVendorPayment";
import TransactionFilters from "./components/TransactionFilters";
import dayjs from "dayjs";
import useBrandPaymentMethod from "~/hooks/useBrandPaymentMethod";
import moment from "moment";
import brandsStore from "~/store/brandsStore/brandsStore";

export default function TransactionsList() {
  const { travelHouse }: any = travelHouseStore();
  const { brands } = brandsStore();
  const { thPaymentMethods }: any = useBrandPaymentMethod(null);

  const [selectedBrand, setSelectedBrand] = useState<any>([]);
  const [selectedTransType, setSelectedTransType] = useState<any>([]);
  const [selectedTransAcc, setSelectedTransAcc] = useState<any>([]);
  const [selectedPayMeth, setSelectedPayMeth] = useState<any>([]);
  const [inquiryNo, setInquiryNo] = useState<any>();
  const [transactionNo, setTransactionNo] = useState<any>();
  const [selectedUser, setSelectedUser] = useState<any>();
  const [selectedConsultant, setSelectedConsultant] = useState<any>();

  const today = dayjs();

  const currentMonth = [
    today.startOf("month").subtract(1, "month"),
    today.endOf("month"),
  ];

  const client = useApolloClient();

  const [dates, setDates] = useState<any>(currentMonth);

  const startDate = moment(dates?.[0]?.$d).format("YYYY-MM-DD");
  const endDate = moment(dates?.[1]?.$d).format("YYYY-MM-DD");
  const startDateTime = `${startDate}T00:00:00Z`;
  const endDateTime = `${endDate}T23:59:59Z`;

  const condition = {
    brd_list: { id: { _in: brands.map((brand: any) => brand.id) } },
    created_at: { _gte: startDateTime, _lte: endDateTime },
    ...(selectedBrand.length > 0 && {
      brd_id: { _in: selectedBrand },
    }),
    ...(selectedTransType.length > 0 && {
      def_acc_list: {
        type: { _in: selectedTransType },
      },
    }),
    ...(selectedTransAcc.length > 0 && {
      def_acc_list: {
        name: { _in: selectedTransAcc },
      },
    }),
    ...(selectedPayMeth.length > 0 && {
      acc_payment_method: { id: { _in: selectedPayMeth } },
    }),
    ...(selectedUser && {
      users: { id: { _eq: selectedUser } },
    }),
    ...(transactionNo && {
      transactions_no: { _eq: transactionNo },
    }),
    ...(inquiryNo && {
      inq_list: { inquiry_no: { _eq: inquiryNo } },
    }),
    ...(selectedConsultant?.[0]?.id && {
      inq_list: {
        picked_user: {
          id: { _eq: selectedConsultant?.[0]?.id },
        },
      },
    }),
  };

  const { loading, data, error } = useQuery(GET_TRANSACTIONS, {
    variables: { condition },
  });

  const [prevBalance, setPrevBalance] = useState(0);

  useEffect(() => {
    const condition1 = {
      brd_list: { id: { _in: brands.map((brand: any) => brand.id) } },
      created_at: { _lt: startDateTime },
      ...(selectedBrand.length > 0 && {
        brd_id: { _in: selectedBrand },
      }),
      ...(selectedTransType.length > 0 && {
        def_acc_list: {
          type: { _in: selectedTransType },
        },
      }),
      ...(selectedTransAcc.length > 0 && {
        def_acc_list: {
          name: { _in: selectedTransAcc },
        },
      }),
      ...(selectedPayMeth.length > 0 && {
        acc_payment_method: { id: { _in: selectedPayMeth } },
      }),
      ...(selectedUser && {
        users: { id: { _eq: selectedUser } },
      }),
      ...(transactionNo && {
        transactions_no: { _eq: transactionNo },
      }),
      ...(inquiryNo && {
        inq_list: { inquiry_no: { _eq: inquiryNo } },
      }),
      ...(selectedConsultant?.[0]?.id && {
        inq_list: {
          picked_user: {
            id: { _eq: selectedConsultant?.[0]?.id },
          },
        },
      }),
    };

    const getBalance = async () => {
      const res = await client.query({
        query: GET_TRANSACTIONS_WITH_COND,
        variables: { condition: condition1 },
      });

      const allDebits = res?.data?.acc_transactions
        ?.filter((transaction: any) => transaction.type === "debit")
        ?.reduce((sum: any, transaction: any) => sum + transaction.amount, 0);

      const allCredits = res?.data?.acc_transactions
        ?.filter((transaction: any) => transaction.type === "credit")
        ?.reduce((sum: any, transaction: any) => sum - transaction.amount, 0);

      setPrevBalance(allDebits - allCredits);
    };
    getBalance();
  }, [startDateTime, endDateTime]);

  // if (loading) return <div>Loading...</div>;
  if (error) return <div>Error in the query {error.message}</div>;

  return (
    <>
      <h1 className="text-primary dark:text-dark-primary uppercase text-[18px] font-bold border-b-2 divide-gray-200  dark:divide-gray-700 mb-2">
        Journal Transactions ({data?.acc_transactions?.length})
      </h1>
      <TransactionFilters
        travelHouse={travelHouse}
        selectedTransType={selectedTransType}
        selectedTransAcc={selectedTransAcc}
        selectedPayMeth={selectedPayMeth}
        setSelectedTransType={setSelectedTransType}
        setSelectedBrand={setSelectedBrand}
        selectedBrand={selectedBrand}
        setSelectedTransAcc={setSelectedTransAcc}
        setSelectedPayMeth={setSelectedPayMeth}
        setDates={setDates}
        dates={dates}
        setInquiryNo={setInquiryNo}
        setTransactionNo={setTransactionNo}
        setSelectedUser={setSelectedUser}
        setSelectedConsultant={setSelectedConsultant}
      />
      <TransactionsTable
        prevBalance={prevBalance}
        transactions={data?.acc_transactions}
        selectedTransAcc={selectedTransAcc}
      />

      {/* <SidePopup
        className="mb-[100px] md:mb-[140px]"
        width="100%"
        component={<AddManual />}
        text="Manual Entry"
      />
      <SidePopup
        className="mb-[50px] md:mb-[70px]"
        component={<VendorDeposit />}
        text="Vendor Deposit"
      />
      <SidePopup component={<AddEquity />} text="Add Capital Deposit" /> */}

      <SidePopup
        popupType="speedDial"
        component={[
          {
            name: "VendorDeposit",
            icon: <GroupOutlinedIcon />,
            tooltip: "Vendor Deposit",
            address: <AddVendorPayment />,
          },
          {
            name: "ManualEntry",
            icon: <TextSnippetOutlinedIcon />,
            tooltip: "Manual Entry",
            address: <AddManual />,
          },
          {
            name: "AddEquity",
            icon: <PostAddOutlinedIcon />,
            tooltip: "Add Equity",
            address: <AddEquity />,
          },
        ]}
      />
    </>
  );
}
