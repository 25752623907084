import React, { useState } from "react";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { GET_CONS_APPROVAL_STEPS } from "~/graphql/cons_approval_steps/Query";
import { DELETE_CONS_APPROVAL_STEP } from "~/graphql/cons_approval_steps/Mutation";
import { nhost } from "~/lib/nhost";
import SideDrawer from "~/components/common/sideDrawer";
import EditConsApprovalSteps from "../EditConsApprovalSteps";

const ShowConsApprovalSteps = ({ brdId }: any) => {
  const client = useApolloClient();
  const { data, loading, error, refetch } = useQuery(GET_CONS_APPROVAL_STEPS, {
    variables: { brdId },
  });

  const [deleteStep, { loading: deleteLoading }] = useMutation(
    DELETE_CONS_APPROVAL_STEP,
    {
      onCompleted: () => {
        client.refetchQueries({ include: "active" });
        toast.success("Approval step deleted successfully!");
      },
      onError: (error) => {
        toast.error(`Error: ${error.message}`);
      },
    }
  );

  const [openDrawer, setOpenDrawer] = useState(false);
  const [editData, setEditData] = useState<any>(null);

  const handleDelete = async (id: string, imageId: string) => {
    try {
      if (imageId) {
        await nhost.storage.delete({ fileId: imageId });
      }
      await deleteStep({ variables: { id } });
      refetch();
    } catch (error) {
      toast.error("Failed to delete step.");
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error fetching data</p>;

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {data?.cons_approval_steps.map((step: any) => (
          <Card key={step.id} className="bg-primary dark:bg-dark-primary">
            {step.image_id && (
              <CardMedia
                component="img"
                image={nhost.storage.getPublicUrl({ fileId: step.image_id })}
                alt={step.title}
                sx={{
                  objectFit: "cover",
                  maxWidth: "100%",
                  maxHeight: "150px",
                }}
              />
            )}

            <CardContent>
              <Typography variant="h6">{step.title}</Typography>
              <Typography variant="body2" color="textSecondary">
                {step.description}
              </Typography>

              <div className="flex gap-2 mt-3">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setEditData(step);
                    setOpenDrawer(true);
                  }}
                >
                  Edit
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleDelete(step.id, step.image_id)}
                  disabled={deleteLoading}
                >
                  {deleteLoading ? "Deleting..." : "Delete"}
                </Button>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>

      {/* SideDrawer for Editing */}
      <SideDrawer
        component={
          <EditConsApprovalSteps
            setSidePopup={setOpenDrawer}
            editData={editData}
            brdId = {brdId}
          />
        }
        sidePopup={openDrawer}
        setSidePopup={setOpenDrawer}
      />
    </>
  );
};

export default ShowConsApprovalSteps;
