import { gql } from "@apollo/client";

export const INSERT_DEPARTMENTS = gql`
  mutation InsertDepartments($data: [cons_department_insert_input!]!) {
    insert_cons_department(objects: $data) {
      affected_rows
    }
  }
`;

export const UPDATE_DEPARTMENT = gql`
  mutation UpdateDepartment($id: uuid!, $data: cons_department_set_input!) {
    update_cons_department(
      where: { id: { _eq: $id } }
      _set: $data
    ) {
      affected_rows
    }
  }
`;


export const DELETE_DEPARTMENT = gql`
  mutation DeleteDepartment($id: uuid!) {
    delete_cons_department(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;





