import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_UNIVERSITIES } from "~/graphql/cons_university/Query";
import { DELETE_UNIVERSITY } from "~/graphql/cons_university/Mutation";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";


const ShowUniversity = ({ brdId, countryId }: any) => {
  const [fetchUniversities, { data, loading, error }] =
    useLazyQuery(GET_UNIVERSITIES);
  const [deleteUniversity] = useMutation(DELETE_UNIVERSITY);
  const [openPopup, setOpenPopup] = useState(false);
  const [universities, setUniversities] = useState<any[]>([]);
  const [selectedUniversity, setSelectedUniversity] = useState<any | null>(
    null
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (brdId && countryId) {
      fetchUniversities({
        variables: {
          brd_id: brdId,
          country_id: countryId,
        },
      });
    }
  }, [brdId, countryId, fetchUniversities]);

  useEffect(() => {
    if (data?.cons_university) {
      setUniversities(data.cons_university);
    }
  }, [data]);

  const handleEdit = (university: any) => {
    navigate(`/admin/brands/${brdId}/countries/${countryId}/university/${university.id}`, {
    });
  };
  
  

  const handleDelete = async (id: string) => {
    try {
      const { data } = await deleteUniversity({
        variables: { id },
      });

      if (data?.delete_cons_university?.affected_rows > 0) {
        setUniversities((prev) => prev.filter((uni) => uni.id !== id));
        toast.success("University deleted successfully");

        // Refetch updated list after deletion
        await fetchUniversities({
          variables: { brd_id: brdId, country_id: countryId },
        });
      } else {
        toast.error("Failed to delete university");
      }
    } catch (err) {
      console.error("Error deleting university:", err);
      toast.error("An error occurred while deleting the university");
    }
  };

  const handleViewCampuses = (universityId: any) => {
    navigate(
      `/admin/brands/${brdId}/countries/university/${universityId}/campuses`
    );
  };
  const handlePOC = (universityId: any) => {
    navigate(
      `/admin/brands/${brdId}/countries/university/${universityId}/point_of_contact`
    );
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading universities</p>;

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Slug</TableCell>
              <TableCell>Actions</TableCell>
              <TableCell>Campuses</TableCell>
              <TableCell>Point of Contact</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {universities.map((university) => (
              <TableRow key={university.id}>
                <TableCell>{university.name}</TableCell>
                <TableCell>{university.slug}</TableCell>
                <TableCell>
                  <div className="flex gap-3">
                    <Button onClick={() => handleEdit(university)}>
                      <FaEdit size={18} />
                    </Button>
                    <Button onClick={() => handleDelete(university.id)}>
                      <FaTrash size={18} />
                    </Button>
                  </div>
                </TableCell>
                <TableCell>
                  <Button
                    color="success"
                    onClick={() => handleViewCampuses(university.id)}
                  >
                    View Campuses
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    color="success"
                    onClick={() => handlePOC(university.id)}
                  >
                    View Point of Contact
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ShowUniversity;
