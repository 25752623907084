import React from "react";
import SidePopup from "~/components/common/SidePopup";
import AddPointOfContact from "./components/AddPointOfContact";
import { useParams } from "react-router-dom";
import ShowPointOfContact from "./components/ShowPointOfContact";

const PointOfContact = () => {
  const { university_id, brd_id } = useParams();
  return (
    <>
      <ShowPointOfContact universityId={university_id} brdId={brd_id} />
      <SidePopup
        component={
          <AddPointOfContact universityId={university_id} brdId={brd_id} />
        }
      />
    </>
  );
};

export default PointOfContact;
