import React, { useState } from "react";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
} from "@mui/material";
import { FaEdit, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import SideDrawer from "~/components/common/sideDrawer";
import EditConsAboutHeading from "../EditConsAboutHeadings";
import { GET_CONSULTANCY_ABOUT_INFO } from "~/graphql/cons_about_info/Query"; // Corrected import
import { DELETE_CONS_ABOUT_INFO } from "~/graphql/cons_about_info/Mutation"; // Corrected import

const ShowConsAboutHeroHeading = ({ brdId }: any) => {
  const client = useApolloClient();
  const { data, loading, error, refetch } = useQuery(
    GET_CONSULTANCY_ABOUT_INFO,
    {
      variables: { brdId },
    }
  );

  const [deleteInfo, { loading: deleteLoading }] = useMutation(
    DELETE_CONS_ABOUT_INFO,
    {
      onCompleted: () => {
        client.refetchQueries({ include: [GET_CONSULTANCY_ABOUT_INFO] }); // Refetch query
      },
      onError: (error) => {
        toast.error(`Error: ${error.message}`);
      },
    }
  );

  const [openDrawer, setOpenDrawer] = useState(false);
  const [editData, setEditData] = useState<any>(null);

  const handleDelete = async (id: string) => {
    try {
      const { data } = await deleteInfo({ variables: { id } });

      if (data?.delete_cons_about_info?.affected_rows > 0) {
        toast.success("Deleted successfully!");
        refetch(); // Refresh data after delete
      } else {
        toast.error("Failed to delete.");
      }
    } catch (error) {
      toast.error("Error deleting record.");
      console.error(error);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error fetching data</p>;

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Heading</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Sub Heading</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.cons_about_info.map((info: any) => (
              <TableRow key={info.id}>
                <TableCell>{info.heading || "NA"}</TableCell>
                <TableCell>{info.description || "NA"}</TableCell>
                <TableCell>{info.sub_heading || "NA"}</TableCell>
                <TableCell>
                  <div className="flex gap-3">
                    <Button
                      onClick={() => {
                        setEditData(info);
                        setOpenDrawer(true);
                      }}
                    >
                      <FaEdit size={18} />
                    </Button>
                    <Button
                      onClick={() => handleDelete(info.id)}
                      disabled={deleteLoading}
                    >
                      <FaTrash size={18} />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* SideDrawer for Editing */}
      <SideDrawer
        component={
          <EditConsAboutHeading
            setSidePopup={setOpenDrawer}
            editData={editData}
          />
        }
        sidePopup={openDrawer}
        setSidePopup={setOpenDrawer}
      />
    </>
  );
};

export default ShowConsAboutHeroHeading;
