import { gql } from "@apollo/client";

export const INSERT_CONS_COUNTRY = gql`
  mutation MyMutation($data: [cons_country_insert_input!]!) {
    insert_cons_country(objects: $data) {
      affected_rows
    }
  }
`;


export const UPDATE_COUNTRY = gql`
  mutation UpdateCountry(
    $id: uuid!,
    $name: String!,
    $slug: String!
  ) {
    update_cons_country(
      where: {
        id: { _eq: $id }
      }
      _set: {
        name: $name
        slug: $slug
      }
    ) {
      affected_rows
    }
  }
`;



export const DELETE_COUNTRY = gql`
  mutation DeleteCountry($id: uuid!) {
    delete_cons_country(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;





