
import Layout from "~/components/common/Layout";
import BrandModule from "~/modules/BrandModule";
import ProgramsRequirements from "~/modules/BrandModule/segments/Countries/components/ProgramsRequirements";

const ProgramRequirementsPage  = () => {
  return (
    <div>
      <Layout>
        <BrandModule>
          <ProgramsRequirements />
        </BrandModule>
      </Layout>
    </div>
  );
};

export default ProgramRequirementsPage;